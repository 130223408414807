
















































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import PayrollBatchSelect from "@/components/EmployeePlan/PayrollBatchSelect.vue";
import LaddaButton from "@/components/LaddaButton.vue";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "../models/common";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    PayrollBatchSelect,
    LaddaButton
  }
})

export default class PayrollBatchesModal extends TSXComponent<void> {

  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: [] })
  payrollBatchesData!: any;

  @Prop({ required: false, default: [] })
  realizedProfitBatchesData!: any;

  @Prop({ required: false, default: 0 })
  month!: number;

  @Prop({ required: false, default: {}})
  data!: any;

  @Prop({ required: false, default: "" })
  selectedPayrollCycleMonthAsString!: string;

  $validator: any;
  selectedBatchID: any = [];
  selectedBatches: object[] = [];
  saving: boolean = false;
  payrollBatchesDataBK: any = [];

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  created() {
    let realizedProfitBatchesDataBK = JSON.parse(JSON.stringify(this.realizedProfitBatchesData));
    this.payrollBatchesDataBK = JSON.parse(JSON.stringify(this.payrollBatchesData));
    if (realizedProfitBatchesDataBK.length) {
      let realizedProfitBatch = realizedProfitBatchesDataBK.find((item: any) => item.MONTH == this.month);
      let otherMonthsSelectedBatchIDs = realizedProfitBatchesDataBK.flatMap((item: any) => item.SELECTEDBATCHID);
      this.selectedBatchID = realizedProfitBatch && realizedProfitBatch.SELECTEDBATCHID ? realizedProfitBatch.SELECTEDBATCHID : [];
      if (otherMonthsSelectedBatchIDs && otherMonthsSelectedBatchIDs.length) {
        this.payrollBatchesDataBK = this.payrollBatchesDataBK.filter((item: any) => !otherMonthsSelectedBatchIDs.includes(item.PGGROUPID) || this.selectedBatchID.includes(item.PGGROUPID));
      }
      if (this.selectedBatchID.length) {
        this.payrollBatchesDataBK.map((item: any) => {
          if (this.selectedBatchID.includes(item.PGGROUPID)) {
            this.selectedBatches.push({
              PGGROUPID: item.PGGROUPID,
              PAYCHECKNAME: item.PAYCHECKNAME
            });
          }
        });
        let newSelectedBatchID: any = [];
        this.selectedBatchID.map((id: number) => {
          const batchIdx = this.payrollBatchesDataBK.findIndex((item: any) => item.PGGROUPID === id);
          if (batchIdx !== -1) {
            newSelectedBatchID.push(id);
          }
        });
        this.selectedBatchID = newSelectedBatchID;
      }
    }
  }

  toggleSelectedBatch(selectedID: number, batchName) {
    const index = this.selectedBatchID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedBatchID.push(selectedID);
      this.selectedBatches.push({
        PGGROUPID: selectedID,
        PAYCHECKNAME: batchName
      });
      return;
    }

    this.selectedBatchID.splice(index, 1);
    this.$delete(this.selectedBatches, index);
  }

  selectAllBatches() {
    this.resetBatches();
    this.selectedBatchID = this.payrollBatchesDataBK.map(
      (val: any) => val.PGGROUPID
    );
    this.selectedBatches = this.payrollBatchesDataBK.map((val: any) => ({
      PGGROUPID: val.PGGROUPID,
      PAYCHECKNAME: val.PAYCHECKNAME
    }));
  }

  resetBatches() {
    this.selectedBatches = [];
    this.selectedBatchID = [];
  }

  updateBatchList(batchID) {
    const batchNameIdx = this.selectedBatches.findIndex((item: any) => item.PGGROUPID === batchID);
    if (batchNameIdx !== -1) {
      this.selectedBatches.splice(batchNameIdx, 1);
    }
    const batchIdIdx = this.selectedBatchID.findIndex(id => id === batchID);
    if (batchIdIdx !== -1) {
      this.selectedBatchID.splice(batchIdIdx, 1);
    }
  }

  async save() {
    this.saving = true;
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "EmployeePlanAdd",
      action: "updateRealizedBatch",
      selectedBatchID: this.selectedBatchID,
      month: this.month,
      compUUID: this.data.COMPUUID || ""
    });
    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      this.$emit("reload");
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
    this.saving = false;
  }
}
