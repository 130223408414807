

































































import { VNode } from "vue";
import axios from "axios";
import { notifier } from "../models/common";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import InfoTooltip from "../components/InfoTooltip.vue";
import Datepicker from "@/components/Datepicker.vue";
import SnoozeTooltip from "@/components/Log/SnoozeTooltip.vue";

interface Props {
}

interface Events {
  onClose: void
  onSave: void
  onCompleted: void
}

declare const $: any
declare const getImageRouteData: Function;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    InfoTooltip,
    SnoozeTooltip,
    Datepicker
  }
})
export default class ModifyAlertDetailsModal extends TSXComponent<
  Props,
  Events
> {

  @Prop({required: true, default: {}})
  alert!: any

  alertContent: string = '';
  showModifySnoozeAlertModalFlg = false;


  saving = false;
  loading: boolean = false;

  $refs!: {
    modal: HTMLDivElement
  }

  created() {

  }

  mounted() {
    $(this.$refs.modal).modal('show');

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    });

    this.alertContent = this.alert ? this.alert.CONTENT : '';
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  showSnoozeModal() {
    this.showModifySnoozeAlertModalFlg = !this.showModifySnoozeAlertModalFlg;
  }

  async save() {

    const valid = await this.$validator.validateAll();

    if (!valid) {
      return;
    }
    this.saving = true;

    const requestObj = {
      Controller: 'Alerts',
      FunctionName: 'Update',
      action: 'modifyAlertContent',
      userID: sessionStorage.getItem("userId"),
      modifyAlertId: this.alert.ALERTID,
      alertContent: this.alertContent,
    };

    const response = await getImageRouteData(requestObj);

    const message = response.data.STATUSMESSAGE || "";
    if (response.data.STATUS == 1) {
      notifier.success(message);
      this.$emit('completed', true);
    } else {
      if (message) {
        notifier.alert(message);
      }
    }

    this.saving = false;
  }
}
