
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Model, Emit, Inject } from "vue-property-decorator";
import { CoolSelect } from 'vue-cool-select'
import { debounce } from "../models/common";

interface Props {
  defaultText?: string
  placeholder?: string
  value: string
  sourceList: number
  searchFn: AutosuggestSearch
}

interface Events {
  onInput: string
}

export type Option = {
  value: string
  text: string
  label?: string // optional, if provided, will be used for suggested list item
  fundingsourceid?: number;
}

export type AutosuggestSearch = (search: string) => Promise<{
  options: Option[],
}>

@Component({
  inheritAttrs: true,
  components: {
    CoolSelect,
  },
  inject: ['$validator'],
})
export default class AutoSuggestInput extends TSXComponent<
  Props,
  Events
> {

  @Prop({ required: false, type: String })
  placeholder?: string

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, default : 0 })
  sourceList?: number

   @Prop({ required: false, default : false })
  showmore?: boolean

  @Prop({ required: false, default : false })
  disabled?: boolean

  @Prop({ required: false, type: String, default: "customers" })
  field?: string

  @Prop({ required: false, default : false })
  showAll?: boolean

  @Prop({ required: false, default: ""})
  redirect?: string;
  
  @Prop({ required: false, default: ""})
  atext?: string;

  @Prop({ required: false, default: ""})
  redirectAfter?: string;

  @Prop({ required: false, default: false})
  showAddCustomer?: boolean;

  @Prop({ required: false, default: "bottom" })
  tooltipPosition!: string;

  @Prop({ required: false, default: false})
  titles?: string;

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string

  @Prop({ required: true, type: Function })
  searchFn!: AutosuggestSearch

  options: Option[] = []
  search = ''
  loading = false
  moreUsers = 0
  timeoutId:any = 0
  addCustomerQueryString:string= "";
  addCustomer:boolean = this.redirect != "" ? true : false;

  get infoMsg(): string {
    if (this.search.length === 0) {
      return "Type in the field above to refine your search"
    }

    if (this.search.length === 1) {
      return "Please type at least 2 characters to search"
    }

    if (this.options.length === 0) {
      return "No results found"
    }

    return ''
  }

  get renderOptions(): Option[] {
    if (this.value && !this.options.find((option) => option.value === this.value)) {
      return [
        {
          value: this.value,
          text: this.defaultText || ''
        },
        ...this.options,
      ]
    }
    return this.options.map((o) => ({...o}))
  }

  async localOnSearch(search: string) {
    this.search = search
    
    this.addCustomerQueryString = '?customerAdd='+search+'&redirectTo='+this.redirectAfter;

    this.loading = true

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(async () => {
      const { options } = await this.searchFn(search)
      this.options = options
      if(options.length > 10 && !this.showAll){
        this.options = options.slice(0,10)
        this.moreUsers = options.length - 10;
      }

      this.setLoading(false)
    }, 300)
  }

  setLoading(loading: boolean) {
    this.loading = loading
  }

  localOnBlur() {
    this.$emit('onBlur', {
      search: this.search,
      value: this.value
    });
  }

  @Emit('input')
  onInput(value: string) {
    return value
  }

  render() {
    return (
      <CoolSelect
        value={this.value}
        // items={this.options}
        items={this.renderOptions}
        item-value="value"
        item-text="text"
        filter={() => {
          return true // disabled filtering by match text
        }}
        placeholder={this.placeholder}
        onInput={this.onInput}
        onSearch={this.localOnSearch}
        onFocus={() => this.localOnSearch(this.value || '')}
        onBlur={() => this.localOnBlur()}
        menuItemsMaxHeight="178px"
        menuDefaultPosition={this.tooltipPosition}
        disabled = {this.disabled}

        scopedSlots={{
          item: ({ item }) => (
            typeof this.titles === "string" && this.titles === "true" ? (
              <div
                class="item"
                data-value={item.value || ""}
                title={item.label || item.text || item.value}
              >
                {item.label || item.text || item.value}
              </div>
            ) : (
              <div class="item" data-value={item.value || ""}>
                {item.label || item.text || item.value}
              </div>
            )
          ),
          'before-items-fixed': () => (
            <div></div>
          ),
          'no-data': () => (
            this.loading ? (
              <div>Loading...</div>
            ) : (
              this.showAddCustomer ? (
                <div>
                  <div>No results</div>
                  <a
                    href={this.redirect + this.addCustomerQueryString}
                    class="addNew pl-4"
                    v-show={this.addCustomer}
                  >{this.atext} - {this.search}</a>
                </div>
              ) : (
                <div>No results</div>
              )
            )
          ),
          'input-end': () => {
            <span class="icon-dropdown" />
          },
          'before-items': () => (
            <div></div>
          ),
          'after-items': () => (
            this.options.length > 9 && this.showmore && this.moreUsers > 0 ? ( 
              <div id="narrowSearch" class="itemtext"><span>{this.moreUsers} more (narrow search to see more accounts)</span></div>
             ) : (
               this.options.length == 5 && this.showmore ? (<div id="top5" class="itemtext"><span>Top 5 {this.field} are listed, search to see more {this.field != "customers" ? this.field : "accounts"}</span></div>) : (this.options.length != 0 && this.search.length === 0 && this.sourceList !== 1? (<div class="itemtext">Top {this.field} are listed, search to see more {this.field != "customers" ? this.field : "accounts"}</div>) : <div></div>)
             )

          ),
        }}
      >
      </CoolSelect>
    );
  }
}
