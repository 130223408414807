














































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "./LaddaButton.vue";
import { notifier, wait } from "../models/common";
import axios from "axios";
import DropdownControl from "./DropdownControl.vue";
import { getEUAddress } from "../helpers/ApiHelper";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";
import Datepicker from "../components/Datepicker.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import moment from "moment";

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    DropdownControl,
    FormMultiSelect,
    Datepicker,
    ConfirmRemoveItemModal
  },
  methods: {
    getEUAddress
  }
})
export default class HardwareUpdateFields extends TSXComponent<void> {
  @Prop({ required: false, default: "" })
  selectedIDs!: any;

  @Prop({ required: false, default: [] })
  selectedItems!: any;

  @Prop({ required: false, default: [] })
  categories!: any;

  @Prop({ required: false, default: [] })
  pageHeaders!: any;

  @Prop({ required: false, default: [] })
  listCustomFields!: any;

  $refs!: {
    stateListRef: any;
    modal: HTMLDivElement;
  };

  loading = false;
  saving: boolean | "error" = false;
  $parent: any;
  editFields: any = [
    // {
    //   id: "HARDWAREID",
    //   text: "Hardware ID"
    // },
    {
      id: "SKU",
      text: "SKU"
    },
    {
      id: "HDESCRIPTION",
      text: "Description"
    },
    // {
    //   id: "ASSETNUMBER",
    //   text: "Serial Number"
    // },
    // {
    //   id: "ASSETTAG",
    //   text: "Asset Tag"
    // },
    {
      id: "CATEGORYID",
      text: "Category"
    },
    {
      id: "STARTDATEFORMATTED",
      text: "Warranty Start Date"
    },
    {
      id: "WARRENTYEXPFORMATTED",
      text: "Warranty End Date"
    }
    // hardware custom fields
  ];
  editData: any = [];
  editDataBK: any = [];
  confirmUpdateModalVisible = false;
  allowSaving = true;
  // existed: any = {
  //   ASSETNUMBER: [],
  //   ASSETTAG: []
  // };

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(".datepicker-container:visible").remove();
    $(this.$refs.modal).modal("hide");
  }

  async created() {
    // edit data
    this.editData = [...this.selectedItems].map(item => ({
      ...item,
      CATEGORYID: item.CATEGORYID || 0
    }));

    // append hardware custom fields
    const fieldIds: number[] = [];
    for (const item of this.pageHeaders) {
      if (item.key.indexOf("customData") != -1) {
        const id = parseInt(item.key.replace("customData", "")) || 0;
        if (id) {
          fieldIds.push(id);

          // get dropdown options if any
          const inListCustomFields = this.listCustomFields.find(
            t => t.CUSTOMFIELDID == id
          );
          let options: any = [];
          let customDType = 1;
          if (inListCustomFields && inListCustomFields.CUSTOMDTYPE == 3) {
            customDType = 3;
            options = [...(inListCustomFields.OPTIONS || [])].map(t => ({
              ID: t.CUSTOMFIELDOPTIONID,
              TEXT: t.CUSTOMFIELDOPTIONNAME
            }));
            options.unshift({
              ID: 0,
              TEXT: "-- Select --"
            });
          }

          this.editFields.push({
            id,
            text: item.value,
            isCustomField: true,
            customDType,
            options
          });

          // make sure item has this custom data field
          for (const t of this.editData) {
            if (!(`CUSTOMDATA${id}` in t)) {
              t[`CUSTOMDATA${id}`] = "";
            }
          }
        }
      }
    }

    if (fieldIds.length) {
      try {
        this.loading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          Controller: "Hardware",
          FunctionName: "UpdateFields",
          action: "getCustomData",
          hardwareIds: this.selectedIDs.join(","),
          fieldIds: fieldIds.join(",")
        });
        if (response.data.STATUS == 1) {
          const customData = response.data.customData || [];

          // update custom data for editData
          for (const item of this.editData) {
            const hardwareId = item.HARDWAREID;
            for (const key of Object.keys(item)) {
              if (key.indexOf("CUSTOMDATA") == -1) {
                continue;
              }

              const id = parseInt(key.replace("CUSTOMDATA", "")) || 0;
              if (!id) {
                continue;
              }

              const inList = customData.find(
                t =>
                  `${t.HARDWAREID}` == `${hardwareId}` && t.CUSTOMFIELDID == id
              );
              if (inList) {
                item[`CUSTOMDATA${id}`] = inList.CUSTOMVALUE;
              }
            }
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }

    // group lines based on sku/poid
    const processed: any = [];
    let index = -1;
    // eslint-disable-next-line require-atomic-updates
    for (const item of this.editData) {
      item.show = false;
      const key = `${item.SKU}_${item.POID || item.EXTERNALPOID || ""}`;
      const inList = processed.find(
        t => `${t.SKU}_${t.POID}`.toLowerCase() == key.toLowerCase()
      );
      if (!inList) {
        index += 1;
        item.show = true;
        item.index = index;
        processed.push({
          SKU: item.SKU,
          POID: item.POID || ""
        });
      }
    }

    // backup
    this.editDataBK = JSON.parse(JSON.stringify(this.editData));
    this.updateAllowSaving();
  }

  get editedLines() {
    let ret: any = [];
    for (const item of this.editData) {
      const itemBK = this.editDataBK.find(
        t => t.HARDWAREID == item.HARDWAREID && t.PURCHASEID == item.PURCHASEID
      );
      if (itemBK && JSON.stringify(item) != JSON.stringify(itemBK)) {
        ret.push(item);
      }
    }

    return ret;
  }

  async saveFields(force = false) {
    if (!this.editedLines.length) {
      return;
    }

    // validate
    this.$forceUpdate();
    const valid = await this.$validator.validateAll();
    if (!valid) {
      // notify warranty date format
      const invalidFormatFields = this.$validator.errors.items.filter(
        item =>
          item.field.indexOf("startDate") != -1 ||
          item.field.indexOf("endDate") != -1
      );
      const invalidRange = this.editedLines.filter(
        item => item.invalidWarrantyRange || false
      );
      if (invalidFormatFields.length || invalidRange.length) {
        notifier.alert("Invalid warranty date");
      }

      return;
    }

    // validate duplicated asset number/asset tag in edit lines
    // let hasDuplicateAssetNumbers = false;
    // let hasDuplicateAssetTags = false;
    // for (const item of this.editData) {
    //   if (this.duplicatedInLines(item, "ASSETNUMBER")) {
    //     hasDuplicateAssetNumbers = true;
    //   }
    //   if (this.duplicatedInLines(item, "ASSETTAG")) {
    //     hasDuplicateAssetTags = true;
    //   }
    // }
    // if (hasDuplicateAssetNumbers || hasDuplicateAssetTags) {
    //   notifier.alert("Duplicated Serial Number or Asset Tag");
    //   return;
    // }

    // validate if use duplicated asset number/asset tag already exists
    // let assetNumberExisted = false;
    // let assetTagExisted = false;
    // for (const item of this.editData) {
    //   if (this.inExisted(item, "ASSETNUMBER")) {
    //     assetNumberExisted = true;
    //   }
    //   if (this.inExisted(item, "ASSETTAG")) {
    //     assetTagExisted = true;
    //   }
    // }
    // if (assetNumberExisted || assetTagExisted) {
    //   notifier.alert("Serial Number or Asset Tag already exists");
    //   return;
    // }

    if (!force) {
      // show a confirm "X number of fields will be updated"
      this.confirmUpdateModalVisible = true;
      return;
    }

    try {
      this.saving = true;
      const hardware: any = [];
      for (const item of this.editedLines) {
        const tmp: any = {
          AID: item.AID,
          PURCHASEID: item.PURCHASEID || 0,
          POLIID: item.POLIID || 0,
          HARDWAREID: item.HARDWAREID,
          HARDWAREUUID: item.HARDWAREUUID,
          HDESCRIPTION: item.HDESCRIPTION,
          // ASSETNUMBER: item.ASSETNUMBER,
          // ASSETTAG: item.ASSETTAG,
          CATEGORYID: item.CATEGORYID,
          STARTDATEFORMATTED: item.STARTDATEFORMATTED,
          WARRENTYEXPFORMATTED: item.WARRENTYEXPFORMATTED
        };
        // custom data
        const cfIds: number[] = [];
        for (const k of Object.keys(item)) {
          if (k.indexOf("CUSTOMDATA") != -1) {
            const id = parseInt(k.replace("CUSTOMDATA", "")) || 0;
            if (id) {
              tmp[k] = item[k];
              cfIds.push(id);
            }
          }
        }
        tmp.cfIds = cfIds;
        hardware.push(tmp);
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Hardware",
        FunctionName: "UpdateFields",
        hardware
      });

      if (response.data.STATUS == 1) {
        this.saving = false;

        // backup
        this.editDataBK = JSON.parse(JSON.stringify(this.editData));

        // success
        this.$emit("close");
        this.$emit("resetSelectedIds");
        notifier.success("Saved successfully");
      } else {
        this.saving = "error";
      }
    } catch (err) {
      console.log(err);
      this.saving = "error";
    }
  }

  updateAllowSaving() {
    this.allowSaving =
      JSON.stringify(this.editData) == JSON.stringify(this.editDataBK)
        ? false
        : true;
  }

  dayChange(item, index, type) {
    // validate start/end waranty date
    item.invalidWarrantyRange = false;
    if (
      moment(item.STARTDATEFORMATTED).isValid() &&
      moment(item.WARRENTYEXPFORMATTED).isValid() &&
      new Date(item.STARTDATEFORMATTED) > new Date(item.WARRENTYEXPFORMATTED)
    ) {
      item.invalidWarrantyRange = true;
    }

    item.datePickerKey = `${index}_${item.STARTDATEFORMATTED}_${item.WARRENTYEXPFORMATTED}`;
    this.syncHardwareData(item);
    // this.updateAllowSaving();
  }

  isValidDate(val) {
    if (val && !moment(val).isValid()) {
      return false;
    }

    return true;
  }

  // duplicatedInLines(item, key) {
  //   let ret = false;
  //   if (item[key]) {
  //     const duplidatedItems = this.editData.filter(
  //       t =>
  //         t[key].toLowerCase() == item[key].toLowerCase() &&
  //         t.HARDWAREID != item.HARDWAREID
  //     );
  //     if (duplidatedItems.length) {
  //       ret = true;
  //     }
  //   }

  //   return ret;
  // }

  // inExisted(item, key) {
  //   if (!item[key]) return false;

  //   let ret = false;
  //   const inList = this.existed[key].find(
  //     t => t.toLowerCase() == item[key].toLowerCase()
  //   );
  //   if (inList) {
  //     ret = true;
  //   }

  //   return ret;
  // }

  // async checkDuplicate(item, key) {
  //   if (!key || !item[key]) return;

  //   const requestObj = {
  //     Controller: "Hardware",
  //     FunctionName: "UpdateFields",
  //     action: "checkDuplicate",
  //     aId: item.AID || 0,
  //     HARDWAREID: item.HARDWAREID,
  //     hardwareIds: this.selectedIDs.join(","),
  //     [key]: item[key]
  //   };
  //   const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
  //   if (response.data.STATUS == 1) {
  //     const existed = response.data.existed || 0;
  //     if (existed) {
  //       let message = "";
  //       if (key == "ASSETNUMBER") {
  //         message = "Serial Number already exists";
  //       } else if (key == "ASSETTAG") {
  //         message = "Asset Tag already exists";
  //       }
  //       notifier.alert(message);

  //       // save existed asset number/asset tag
  //       this.existed[key].push(item[key]);
  //     }
  //   }
  // }

  syncHardwareData(item) {
    // find and sync data for hidden lines
    const key = `${item.SKU}_${item.POID || item.EXTERNALPOID || ""}`;
    const hiddenItems = this.editData.filter(
      t => `${t.SKU}_${t.POID}`.toLowerCase() == key.toLowerCase() && !t.show
    );
    // update data from "item"
    for (const t of hiddenItems) {
      for (const field of this.editFields) {
        if (
          [
            "HDESCRIPTION",
            "CATEGORYID",
            "STARTDATEFORMATTED",
            "WARRENTYEXPFORMATTED"
          ].includes(field.id)
        ) {
          t[field.id] = item[field.id];
        } else if (field.isCustomField || false) {
          // custom fields
          t[`CUSTOMDATA${field.id}`] = item[`CUSTOMDATA${field.id}`] || "";
        }
      }
    }

    this.updateAllowSaving();
  }

  get totalShow() {
    const showItems = this.editData.filter(t => t.show || false);
    return showItems.length;
  }

  isTooltipTop(item, field) {
    let ret = false;
    if (this.totalShow > 5 && this.totalShow - (item.index || 0) < 4) {
      ret = true;
    }

    return ret;
  }
}
