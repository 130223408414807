import { render, staticRenderFns } from "./FilterCurrencyBox.vue?vue&type=template&id=0833ae71&scoped=true"
import script from "./FilterCurrencyBox.vue?vue&type=script&lang=tsx"
export * from "./FilterCurrencyBox.vue?vue&type=script&lang=tsx"
import style0 from "./FilterCurrencyBox.vue?vue&type=style&index=0&id=0833ae71&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0833ae71",
  null
  
)

export default component.exports