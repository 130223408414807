




import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ApiHelper } from "@/helpers/all";

declare const process: any;

@Component({
  inheritAttrs: true,
  components: {}
})

export default class MapComponent extends TSXComponent<void> {

  $refs!: {
    mapContainer: HTMLDivElement;
  };

  // Mapbox map instance
  map: mapboxgl.Map | null = null;
  mapData: any = [];
  isLatLongData: boolean = false;

  created() {
    this.fetchData();
  }

  initializeMap() {
  try {
    // Initialize the Mapbox map instance
    this.map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: 'mapbox://styles/milestonetech/cm3f1fcx0000901shed0rdjja',
      center: [-104.8561, 39.3722],
      zoom: 15,
      accessToken: process.env.VUE_APP_MAPBOXGL_ACCESSTOKEN
    });

    // Ensure mapData is populated correctly
    if (!this.mapData || !Array.isArray(this.mapData) || this.mapData.length === 0) {
      console.error("Map data is empty or undefined.");
      return;
    }

    // Extract coordinates from the nested structure
    const coordinates = this.mapData
      .filter((feature: any) => feature.geometry && feature.geometry.coordinates && feature.geometry.coordinates.length === 2)
      .map((feature: any) => feature.geometry.coordinates);

    if (coordinates.length === 0) {
      console.error("No valid coordinates found in map data.");
      return;
    }

    // Calculate bounds based on valid coordinates
    const bounds = coordinates.reduce(
      (bounds, coord) => bounds.extend(coord),
      new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
    );

    // Define a threshold to determine if the bounds are too large
    const maxDistanceThreshold = 150; // Threshold in degrees

    const northeast = bounds.getNorthEast();
    const southwest = bounds.getSouthWest();

    const lngDistance = Math.abs(northeast.lng - southwest.lng);
    const latDistance = Math.abs(northeast.lat - southwest.lat);

    // Avoid Zoom Level if no Lat and Long Values
    if (this.isLatLongData) {
      // Center on the US if bounds are too large; otherwise, fit to bounds
      if (lngDistance > maxDistanceThreshold || latDistance > maxDistanceThreshold) {
        this.map.setCenter([-95.7129, 37.0902]); // Center on the US
        this.map.setZoom(3); // Zoom level that shows most of the US
        console.warn("Bounds are too large, centering on the US.");
      } else {
        this.map.fitBounds(bounds, { padding: 25 });
  
        // Adjust zoom level by zooming out 1 level after fitBounds
        this.map.once('moveend', () => {
          this.map.setZoom(this.map.getZoom() - 1);
        });
      }
    }

    // Add zoom and rotation controls to the map
    const nav = new mapboxgl.NavigationControl({ showCompass: false });
    this.map.addControl(nav, 'top-right');

    // Wait for map to fully load before adding sources and layers
    this.map.on('load', () => {
      try {
        // Define GeoJSON source structure explicitly
        const geoJsonSource = {
          type: "FeatureCollection",
          features: this.mapData.map((feature: any) => ({
            type: "Feature",
            geometry: feature.geometry, // Use geometry as provided in the data structure
            properties: feature.properties // Use properties as provided in the data structure
          }))
        };

        // Add the data source with clustering enabled
        this.map.addSource('clusters', {
          type: 'geojson',
          data: geoJsonSource,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50
        });

        // Add a circle layer for clusters
        this.map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'clusters',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': '#0091FF',
            'circle-radius': 20
          }
        });

        // Avoid load the cluster point for default IP Location
        if (this.isLatLongData) {
          // Add a symbol layer to show the number of points in each cluster
          this.map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'clusters',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': '{point_count}',
              'text-size': 16,
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold']
            },
            paint: { 'text-color': '#FFFFFF' }
          });
  
          // Add a layer for individual points when not in clusters
          this.map.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: 'clusters',
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-color': '#0091FF',
              'circle-radius': 10
            }
          });
  
          // Add popup functionality for unclustered points
          this.map.on('click', 'unclustered-point', (e) => {
            const coordinates = e.features[0].geometry.coordinates.slice();
  
            let popupContent = '';
            e.features.map((features: any) => {
              let statusColor;
              const { userName, address, lastSeen, deviceModel, userId, aId } = features.properties;
  
              if(lastSeen != "N/A"){
                // Parse lastSeen into a Date object
                const lastSeenDate = new Date(lastSeen);
                const currentTime = new Date();
  
                // Calculate the time difference in hours
                const timeDifference = (currentTime.getTime() - lastSeenDate.getTime()) / (1000 * 60 * 60); // Difference in hours
  
                // Set statusColor based on time difference
                statusColor = timeDifference <= 24 ? 'green' : 'red';
                statusColor = timeDifference > 168 ? 'grey' : statusColor;
              }
              else {
                statusColor = 'grey';
              }
  
              const userSpacer = e.features.length > 1 ? '<br>' : '';
  
              popupContent += `
                <span class="d-flex align-items-center"><div style="color: #0091FF; font-family: 'Open Sans'; font-size: 16px; font-style: normal; font-weight: 600; position: relative;"><a class="textOverflow eu-hardware-link" href="#/editHardwareUser/${userId}?euName=${userName}&aId=${aId}" target="_new">${userName}</a> <div class="icon mr-2 me-2 ${statusColor}" style="margin-left: 9px;position: absolute;top: 8px;height: 5px;width: 5px;"></div></div></span>
                <div style="color: #B7B6BA; font-family: 'Open Sans'; font-size: 8px; font-style: normal; font-weight: 600; line-height: 12px;">Last Online: ${lastSeen}</div>
                <div style="color: #000; font-family: 'Open Sans'; font-size: 10px; font-style: normal; font-weight: 700; margin-top: 3px;">${address}</div>
                <div style="color: #000; font-family: 'Open Sans'; font-size: 9px; font-style: normal; font-weight: 600; line-height: 12px;">${deviceModel}</div>${userSpacer}
              `;
            });
  
            // Open the popup at the correct coordinates
            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(popupContent)
              .addTo(this.map);
          });
        }

        // Change cursor to pointer when hovering over a point
        this.map.on('mouseenter', 'unclustered-point', () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });

        // Reset cursor when not hovering over a point
        this.map.on('mouseleave', 'unclustered-point', () => {
          this.map.getCanvas().style.cursor = '';
        });
      } catch (layerError) {
        console.error("Error while adding layers or sources:", layerError);
      }
    });
  } catch (mapInitError) {
    console.error("Map initialization error:", mapInitError);
  }
}





  async fetchData() {
    let reqData = {}
    reqData["controller"] = "Hardware";
    reqData["FunctionName"] = "List";
    reqData["viewType"] = "Map";
    reqData["getAll"] = 0;
    reqData["allowEndUserAll"] = 1;

    const response = await ApiHelper.callApi("post", reqData);

    if (response.ERROR) {
      throw new Error(response.ERROR);
    }
    if (response.STATUS !== 1) {
      // throw new Error(response.STATUSMESSAGE);
      return response
    }
    if (response.STATUS == 1) {
      console.dir(response.HARDWARES);
      if (response.HARDWARES.length) {
        this.mapData = response.HARDWARES.map((hardware: any) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [parseFloat(hardware.LONGITUDE), parseFloat(hardware.LATITUDE)]
          },
          properties: {
            userName: hardware.ENDUSERFULLNAME,
            address: hardware.ENDUSERFULLADDRESS,
            lastSeen: hardware.LOGTS ? new Date(hardware.LOGTS).toLocaleString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : "N/A",
            deviceModel: hardware.MAINMODEL,
            aId: hardware.AID,
            userId: hardware.EUID
          }
        }));
        this.isLatLongData = true;
      } else {
        this.mapData = [{
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [-104.8561, 39.3722]
          }
        }];
        this.isLatLongData = false;
      }

      this.initializeMap();
    }

    return response
  }

}
