var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_header : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":10,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_paragraph : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":2},"end":{"line":27,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_shipment_notes : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":2},"end":{"line":40,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRemitAddress : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":2},"end":{"line":146,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInfoHeader : depth0),{"name":"if","hash":{},"fn":container.program(80, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":2},"end":{"line":761,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"if","hash":{},"fn":container.program(235, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":763,"column":2},"end":{"line":767,"column":9}}})) != null ? stack1 : "")
    + "  \n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"if","hash":{},"fn":container.program(238, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":769,"column":2},"end":{"line":788,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_table : depth0),{"name":"if","hash":{},"fn":container.program(244, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":790,"column":2},"end":{"line":816,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_data_fields : depth0),{"name":"if","hash":{},"fn":container.program(254, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":818,"column":2},"end":{"line":855,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <table width=\"100%\" class=\"element-header "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":110}}})) != null ? stack1 : "")
    + " element-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":119},"end":{"line":4,"column":125}}}) : helper)))
    + "\">\n        <tr>\n          <td class=\"element-header-content\">"
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":6,"column":45},"end":{"line":6,"column":54}}}) : helper)))
    + "</td>\n        </tr>\n      </table>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "element-header-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":4,"column":87},"end":{"line":4,"column":103}}}) : helper)));
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <table width=\"100%\" class=\"element-paragraph "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":51},"end":{"line":14,"column":116}}})) != null ? stack1 : "")
    + " element-"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":125},"end":{"line":14,"column":131}}}) : helper)))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.header : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "      </table>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "element-paragraph-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":14,"column":93},"end":{"line":14,"column":109}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <tr>\n            <td class=\"element-paragraph-header\">"
    + container.escapeExpression(((helper = (helper = helpers.header || (depth0 != null ? depth0.header : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"header","hash":{},"data":data,"loc":{"start":{"line":17,"column":49},"end":{"line":17,"column":59}}}) : helper)))
    + "</td>\n          </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <tr>\n            <td class=\"element-paragraph-content\">"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":22,"column":50},"end":{"line":22,"column":59}}}) : helper)))
    + "</td>\n          </tr>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":39,"column":11}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <table width=\"100%\" class=\"element-paragraph "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":51},"end":{"line":31,"column":116}}})) != null ? stack1 : "")
    + " element-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":31,"column":125},"end":{"line":31,"column":131}}}) : helper)))
    + "\">\n        <tr>\n          <td class=\"element-paragraph-header\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":33,"column":47},"end":{"line":33,"column":55}}}) : helper)))
    + "</td>\n        </tr>\n        <tr>\n          <td class=\"element-paragraph-content\">"
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":36,"column":48},"end":{"line":36,"column":57}}}) : helper)))
    + "</td>\n        </tr>\n      </table>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <table width=\"100%\" id=\"quoteDetails_header\">\n      <tr>\n        <td style=\"color:#bbb; line-height: 20px; letter-spacing: 1.7px; padding-right: 5px; padding-top: 20px;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showRemitAddress),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":10},"end":{"line":77,"column":17}}})) != null ? stack1 : "")
    + "        </td>\n        <td colspan=\"2\">\n          <img src=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.companyLogo), depth0))
    + "\" style=\"display:block; border: 0; float: right; height: 52px;\" border=\"0\" />\n        </td>\n      </tr>\n      <tr>\n        <td colspan=\"3\">\n          <table width=\"100%\">\n            <tr>\n              <td style=\"padding: 20px 0;font-size: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.headerSize),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":88,"column":52},"end":{"line":88,"column":145}}})) != null ? stack1 : "")
    + "px;letter-spacing: 2px;font-weight: bold;color: #a5a5a5;\">\n                <span style=\"color: #0196d5;\">PURCHASE ORDER #:</span>\n                <span style=\"font-size: 22px !important;\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMERPO),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(48, data, 0),"data":data,"loc":{"start":{"line":90,"column":58},"end":{"line":90,"column":175}}})) != null ? stack1 : "")
    + "</span>\n              </td>\n              <td align=\"right\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showSourceName),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":16},"end":{"line":105,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showLogo),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.program(58, data, 0),"data":data,"loc":{"start":{"line":107,"column":16},"end":{"line":115,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showTempIndicator),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":16},"end":{"line":119,"column":23}}})) != null ? stack1 : "")
    + "              </td>\n            </tr>\n          </table>\n        </td>\n      </tr>\n\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"unless","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":6},"end":{"line":144,"column":17}}})) != null ? stack1 : "")
    + "    </table>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyName),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":53,"column":19}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.streetAddress),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":54,"column":12},"end":{"line":59,"column":19}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.cityState),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":65,"column":19}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.phoneNo),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":66,"column":12},"end":{"line":75,"column":19}}})) != null ? stack1 : "")
    + " <br />\n            "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":76,"column":12},"end":{"line":76,"column":141}}})) != null ? stack1 : "")
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyName), depth0)) != null ? stack1 : "")
    + "</strong>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.companyName), depth0)) != null ? stack1 : "")
    + "</strong>\n            ";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.streetAddress), depth0)) != null ? stack1 : "")
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.address1), depth0)) != null ? stack1 : "")
    + "\n            ";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.cityState), depth0)) != null ? stack1 : "")
    + "\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return "              "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.city), depth0)) != null ? stack1 : "")
    + ", "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.state), depth0)) != null ? stack1 : "")
    + " "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.zip), depth0)) != null ? stack1 : "")
    + "\n            ";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.phoneNo), depth0)) != null ? stack1 : "")
    + "\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":69,"column":14},"end":{"line":74,"column":21}}})) != null ? stack1 : "")
    + "            ";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText), depth0))
    + " \n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return "                "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.orderContactPhone1), depth0)) != null ? stack1 : "")
    + " "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.orderContactPhone2), depth0)) != null ? stack1 : "")
    + "\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue), depth0))
    + " ";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.Email), depth0))
    + " ";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.headerSize), depth0));
},"44":function(container,depth0,helpers,partials,data) {
    return "26";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMERPO), depth0))
    + " ";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POID), depth0))
    + " ";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  <div style=\"text-align:right; padding: 20px 0; font-size: 26px; letter-spacing: 2px; font-weight: bold; color: #a5a5a5; white-space: nowrap;\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISOTHERS),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(53, data, 0),"data":data,"loc":{"start":{"line":99,"column":20},"end":{"line":103,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0))
    + "\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VARSOURCE_NAME), depth0))
    + "\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.LOGOSRC)) && stack1.length),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":18},"end":{"line":110,"column":25}}})) != null ? stack1 : "");
},"56":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <span style=\"border-radius: 50%;\"><img src="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.LOGOSRC), depth0))
    + " style=\"height: 75px;\"/></span>\n";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showCustomerName),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":16},"end":{"line":115,"column":16}}})) != null ? stack1 : "");
},"59":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AACCOUNTNAME),{"name":"if","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":18},"end":{"line":114,"column":25}}})) != null ? stack1 : "")
    + "                ";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <span style=\"font-size: 25px; font-weight: 600;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AACCOUNTNAME), depth0))
    + "</span>\n";
},"62":function(container,depth0,helpers,partials,data) {
    return "                  <span style=\"padding: 10px; background-color: #cdcdcd; text-align:center; color: white;\">Customer Indicator</span>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.shipAddress),{"name":"if","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":8},"end":{"line":143,"column":15}}})) != null ? stack1 : "");
},"65":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "          <tr>\n            <td colspan=\"3\" style=\"color:#bbb;padding-top: 20px;line-height: 20px;letter-spacing: 1.7px;\">\n              <strong>Ship To: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.program(68, data, 0),"data":data,"loc":{"start":{"line":130,"column":31},"end":{"line":130,"column":151}}})) != null ? stack1 : "")
    + "</strong><br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.program(72, data, 0),"data":data,"loc":{"start":{"line":131,"column":14},"end":{"line":140,"column":21}}})) != null ? stack1 : "")
    + "            </td>\n          </tr>\n";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO), depth0));
},"68":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AACCOUNTNAME), depth0));
},"70":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPTOONETIMEADDRESS), depth0)) != null ? stack1 : "")
    + "\n";
},"72":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.program(75, data, 0),"data":data,"loc":{"start":{"line":134,"column":16},"end":{"line":139,"column":23}}})) != null ? stack1 : "");
},"73":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR), depth0)) != null ? stack1 : "")
    + "\n";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.program(78, data, 0),"data":data,"loc":{"start":{"line":137,"column":18},"end":{"line":138,"column":105}}})) != null ? stack1 : "")
    + "\n";
},"76":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<strong>"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ACITY), depth0))
    + ",\n                  "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ASTATE), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AZIP), depth0))
    + "</strong>";
},"78":function(container,depth0,helpers,partials,data) {
    return "&nbsp;";
},"80":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.draggableOptions),{"name":"each","hash":{},"fn":container.program(81, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":4},"end":{"line":760,"column":13}}})) != null ? stack1 : "");
},"81":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showInformation),{"name":"if","hash":{},"fn":container.program(82, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":150,"column":6},"end":{"line":759,"column":13}}})) != null ? stack1 : "");
},"82":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <table width=\"100%\" id=\"quoteDetails_info\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isContractNo : depth0),{"name":"if","hash":{},"fn":container.program(83, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":10},"end":{"line":161,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOtherOptions : depth0),{"name":"if","hash":{},"fn":container.program(87, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":10},"end":{"line":757,"column":17}}})) != null ? stack1 : "")
    + "        </table>\n";
},"83":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contractNumber),{"name":"if","hash":{},"fn":container.program(84, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":12},"end":{"line":160,"column":19}}})) != null ? stack1 : "");
},"84":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SPECIALPRICINGID),{"name":"if","hash":{},"fn":container.program(85, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":154,"column":14},"end":{"line":159,"column":21}}})) != null ? stack1 : "");
},"85":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr>\n                  <tr><td width=\"30%\" class=\"title\">Contract Number</td></tr>\n                  <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SPECIALPRICINGID), depth0))
    + "</strong></td></tr>\n                </tr>\n";
},"87":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].cols : depths[1]),{"name":"if","hash":{},"fn":container.program(88, data, 0, blockParams, depths),"inverse":container.program(166, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":163,"column":12},"end":{"line":731,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TEMPLATENOTES),{"name":"if","hash":{},"fn":container.program(222, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":733,"column":12},"end":{"line":736,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"unless","hash":{},"fn":container.program(224, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":738,"column":12},"end":{"line":743,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMSBYORDER),{"name":"if","hash":{},"fn":container.program(227, data, 0, blockParams, depths),"inverse":container.program(229, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":745,"column":12},"end":{"line":756,"column":19}}})) != null ? stack1 : "");
},"88":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "              <tr>\n                <td width=\"60%\" valign=\"top\">\n                  <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depths[1] != null ? depths[1].cols : depths[1])) != null ? stack1.col1 : stack1),{"name":"each","hash":{},"fn":container.program(89, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":169,"column":20},"end":{"line":376,"column":29}}})) != null ? stack1 : "")
    + "                  </table>\n                </td>\n                <td width=\"40%\" valign=\"top\">\n                  <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depths[1] != null ? depths[1].cols : depths[1])) != null ? stack1.col2 : stack1),{"name":"each","hash":{},"fn":container.program(89, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":382,"column":20},"end":{"line":589,"column":29}}})) != null ? stack1 : "")
    + "                  </table>\n                </td>\n              </tr>\n";
},"89":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_customerPO : depth0),{"name":"if","hash":{},"fn":container.program(90, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":170,"column":22},"end":{"line":175,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_orderNumber : depth0),{"name":"if","hash":{},"fn":container.program(93, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":22},"end":{"line":182,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_orderDate : depth0),{"name":"if","hash":{},"fn":container.program(96, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":184,"column":22},"end":{"line":189,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_accountRep : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":191,"column":22},"end":{"line":196,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_phone : depth0),{"name":"if","hash":{},"fn":container.program(105, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":22},"end":{"line":213,"column":29}}})) != null ? stack1 : "")
    + "                      \n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_email : depth0),{"name":"if","hash":{},"fn":container.program(114, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":215,"column":22},"end":{"line":230,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_sourceRepInfo : depth0),{"name":"if","hash":{},"fn":container.program(120, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":232,"column":22},"end":{"line":260,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_sourceAddress : depth0),{"name":"if","hash":{},"fn":container.program(131, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":262,"column":22},"end":{"line":280,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_shipTo : depth0),{"name":"if","hash":{},"fn":container.program(135, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":282,"column":22},"end":{"line":335,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_sourceOrderNumber : depth0),{"name":"if","hash":{},"fn":container.program(158, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":338,"column":22},"end":{"line":355,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_sourceQuoteNumber : depth0),{"name":"if","hash":{},"fn":container.program(162, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":358,"column":22},"end":{"line":375,"column":29}}})) != null ? stack1 : "");
},"90":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.customerPO),{"name":"if","hash":{},"fn":container.program(91, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":24},"end":{"line":174,"column":31}}})) != null ? stack1 : "");
},"91":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Customer PO </td></tr>\n                          <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POID), depth0))
    + "</strong></td></tr>\n";
},"93":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderNO),{"name":"if","hash":{},"fn":container.program(94, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":24},"end":{"line":181,"column":31}}})) != null ? stack1 : "");
},"94":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Order Number</td></tr>\n                          <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PURCHASEID), depth0))
    + "</strong></td></tr>\n";
},"96":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderDate),{"name":"if","hash":{},"fn":container.program(97, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":185,"column":24},"end":{"line":188,"column":31}}})) != null ? stack1 : "");
},"97":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                          <tr><td class=\"title\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].sourceRepInfo : depths[2]),{"name":"if","hash":{},"fn":container.program(98, data, 0, blockParams, depths),"inverse":container.program(100, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":186,"column":48},"end":{"line":186,"column":109}}})) != null ? stack1 : "")
    + "</td></tr>\n                          <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PODATETIMEFORMATTED), depth0))
    + "</strong></td></tr>\n";
},"98":function(container,depth0,helpers,partials,data) {
    return "P.O. DATE";
},"100":function(container,depth0,helpers,partials,data) {
    return "Order Date";
},"102":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showAccRep),{"name":"if","hash":{},"fn":container.program(103, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":24},"end":{"line":195,"column":31}}})) != null ? stack1 : "");
},"103":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Account Rep</td></tr>\n                          <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.ContactName), depth0))
    + "</strong></td></tr>\n";
},"105":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactPhone),{"name":"if","hash":{},"fn":container.program(106, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":24},"end":{"line":212,"column":31}}})) != null ? stack1 : "");
},"106":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Phone</td></tr>\n                          <tr>\n                            <td style=\"padding: 2px 0;\">\n                              <strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent),{"name":"if","hash":{},"fn":container.program(107, data, 0),"inverse":container.program(109, data, 0),"data":data,"loc":{"start":{"line":204,"column":32},"end":{"line":208,"column":39}}})) != null ? stack1 : "")
    + "                              </strong>\n                            </td>\n                          </tr>\n";
},"107":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent), depth0))
    + "\n";
},"109":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(110, data, 0),"inverse":container.program(112, data, 0),"data":data,"loc":{"start":{"line":207,"column":34},"end":{"line":207,"column":176}}})) != null ? stack1 : "")
    + "\n";
},"110":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText), depth0))
    + " ";
},"112":function(container,depth0,helpers,partials,data) {
    return "303-848-8405 ";
},"114":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactEmail),{"name":"if","hash":{},"fn":container.program(115, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":216,"column":24},"end":{"line":229,"column":31}}})) != null ? stack1 : "");
},"115":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Email</td></tr>\n                          <tr>\n                            <td style=\"padding: 2px 0;\">\n                              <strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent),{"name":"if","hash":{},"fn":container.program(116, data, 0),"inverse":container.program(118, data, 0),"data":data,"loc":{"start":{"line":221,"column":32},"end":{"line":225,"column":39}}})) != null ? stack1 : "")
    + "                              </strong>\n                            </td>\n                          </tr>\n";
},"116":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent), depth0))
    + "\n";
},"118":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":224,"column":34},"end":{"line":224,"column":163}}})) != null ? stack1 : "")
    + "\n";
},"120":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].sourceRepInfo : depths[2]),{"name":"if","hash":{},"fn":container.program(121, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":233,"column":24},"end":{"line":259,"column":31}}})) != null ? stack1 : "");
},"121":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                          <tr>\n                            <td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISHPDIRECT),{"name":"if","hash":{},"fn":container.program(122, data, 0, blockParams, depths),"inverse":container.program(124, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":236,"column":30},"end":{"line":256,"column":37}}})) != null ? stack1 : "")
    + "                            </td>\n                          </tr>\n";
},"122":function(container,depth0,helpers,partials,data) {
    return "";
},"124":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                                  <tr class=\"element-header-type"
    + alias2(alias1((depths[2] != null ? depths[2].templateType : depths[2]), depth0))
    + "\">\n                                    <td class=\"element-header-content\">\n                                      "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VARSOURCE_NAME), depth0))
    + " REP AND INFO\n                                    </td>\n                                  </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(125, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":246,"column":34},"end":{"line":248,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(127, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":249,"column":34},"end":{"line":251,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(129, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":252,"column":34},"end":{"line":254,"column":41}}})) != null ? stack1 : "")
    + "                                </table>\n";
},"125":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"127":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"129":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"131":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].sourceAddress : depths[2]),{"name":"if","hash":{},"fn":container.program(132, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":263,"column":24},"end":{"line":279,"column":31}}})) != null ? stack1 : "");
},"132":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                          <tr>\n                            <td>\n                              <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                                <tr class=\"element-header-type"
    + container.escapeExpression(container.lambda((depths[2] != null ? depths[2].templateType : depths[2]), depth0))
    + "\">\n                                  <td class=\"element-header-content\">\n                                    SOURCE ADDRESS\n                                  </td>\n                                </tr>\n\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.sourceAddr),{"name":"if","hash":{},"fn":container.program(133, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":273,"column":32},"end":{"line":275,"column":39}}})) != null ? stack1 : "")
    + "                              </table>\n                            </td>\n                          </tr>\n";
},"133":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  <tr><td style=\"padding: 2px 0; padding-bottom: 7px;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.sourceAddr), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"135":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.shipAddress),{"name":"if","hash":{},"fn":container.program(136, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":283,"column":24},"end":{"line":334,"column":31}}})) != null ? stack1 : "");
},"136":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                          <tr>\n                            <td class=\"title\">Ship To</td>\n                          </tr>\n                          <tr>\n                            <td style=\"padding: 2px 0;\">\n                              <strong>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.oneEU),{"name":"if","hash":{},"fn":container.program(137, data, 0),"inverse":container.program(139, data, 0),"data":data,"loc":{"start":{"line":290,"column":32},"end":{"line":303,"column":39}}})) != null ? stack1 : "")
    + "                              </strong>\n                            </td>\n                          </tr>\n                          <tr>\n                            <td class=\"title\">Shipping Address</td>\n                          </tr>\n                          <tr>\n                            <td style=\"padding: 2px 0;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.oneEU),{"name":"if","hash":{},"fn":container.program(147, data, 0),"inverse":container.program(149, data, 0),"data":data,"loc":{"start":{"line":312,"column":30},"end":{"line":331,"column":37}}})) != null ? stack1 : "")
    + "                            </td>\n                          </tr>\n";
},"137":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.oneEUInfo)) && stack1.EUNAME), depth0))
    + "\n";
},"139":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(140, data, 0),"inverse":container.program(142, data, 0),"data":data,"loc":{"start":{"line":293,"column":34},"end":{"line":302,"column":41}}})) != null ? stack1 : "");
},"140":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPTOONETIMEADDRESSNAME), depth0))
    + "\n";
},"142":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO),{"name":"if","hash":{},"fn":container.program(143, data, 0),"inverse":container.program(145, data, 0),"data":data,"loc":{"start":{"line":297,"column":36},"end":{"line":301,"column":43}}})) != null ? stack1 : "");
},"143":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO), depth0))
    + "\n";
},"145":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AACCOUNTNAME), depth0))
    + "\n";
},"147":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.oneEUInfo)) && stack1.EUADDR), depth0))
    + "\n";
},"149":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(150, data, 0),"inverse":container.program(152, data, 0),"data":data,"loc":{"start":{"line":315,"column":32},"end":{"line":330,"column":39}}})) != null ? stack1 : "");
},"150":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPTOONETIMEADDRESS), depth0)) != null ? stack1 : "")
    + "\n";
},"152":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR),{"name":"if","hash":{},"fn":container.program(153, data, 0),"inverse":container.program(155, data, 0),"data":data,"loc":{"start":{"line":319,"column":34},"end":{"line":329,"column":41}}})) != null ? stack1 : "");
},"153":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR), depth0)) != null ? stack1 : "")
    + "\n";
},"155":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS),{"name":"if","hash":{},"fn":container.program(156, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":322,"column":36},"end":{"line":328,"column":43}}})) != null ? stack1 : "");
},"156":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                                      <strong>\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS), depth0))
    + "\n                                        <br />\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ACITY), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ASTATE), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AZIP), depth0))
    + "\n                                      </strong>\n";
},"158":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].sourceOrderNumber : depths[2]),{"name":"if","hash":{},"fn":container.program(159, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":339,"column":24},"end":{"line":354,"column":31}}})) != null ? stack1 : "");
},"159":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.finalSourceOrderNumber),{"name":"if","hash":{},"fn":container.program(160, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":340,"column":26},"end":{"line":353,"column":33}}})) != null ? stack1 : "");
},"160":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda;

  return "                            <tr>\n                              <td>\n                                <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                                  <tr class=\"element-header-type"
    + container.escapeExpression(alias1((depths[2] != null ? depths[2].templateType : depths[2]), depth0))
    + "\">\n                                    <td class=\"element-header-content\">\n                                      SOURCE ORDER NUMBER\n                                    </td>\n                                  </tr>\n                                  <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = alias1(((stack1 = (data && data.root)) && stack1.finalSourceOrderNumber), depth0)) != null ? stack1 : "")
    + "</td></tr>\n                                </table>\n                              </td>\n                            </tr>\n";
},"162":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].sourceQuoteNumber : depths[2]),{"name":"if","hash":{},"fn":container.program(163, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":359,"column":24},"end":{"line":374,"column":31}}})) != null ? stack1 : "");
},"163":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.INDIRECTQUOTEID),{"name":"if","hash":{},"fn":container.program(164, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":360,"column":26},"end":{"line":373,"column":33}}})) != null ? stack1 : "");
},"164":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda;

  return "                            <tr>\n                              <td>\n                                <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                                  <tr class=\"element-header-type"
    + container.escapeExpression(alias1((depths[2] != null ? depths[2].templateType : depths[2]), depth0))
    + "\">\n                                    <td class=\"element-header-content\">\n                                      SOURCE QUOTE NUMBER\n                                    </td>\n                                  </tr>\n                                  <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.INDIRECTQUOTEID), depth0)) != null ? stack1 : "")
    + "</td></tr>\n                                </table>\n                              </td>\n                            </tr>\n";
},"166":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "              <tr>\n                <td width=\"60%\" valign=\"top\">\n                  <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.customerPO),{"name":"if","hash":{},"fn":container.program(167, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":598,"column":20},"end":{"line":601,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderNO),{"name":"if","hash":{},"fn":container.program(169, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":602,"column":20},"end":{"line":605,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderDate),{"name":"if","hash":{},"fn":container.program(171, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":606,"column":20},"end":{"line":609,"column":27}}})) != null ? stack1 : "")
    + "                  </table>\n                </td>\n                <td width=\"40%\" valign=\"top\">\n                  <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showAccRep),{"name":"if","hash":{},"fn":container.program(173, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":614,"column":20},"end":{"line":617,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactPhone),{"name":"if","hash":{},"fn":container.program(175, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":618,"column":20},"end":{"line":631,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactEmail),{"name":"if","hash":{},"fn":container.program(180, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":632,"column":20},"end":{"line":645,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].sourceRepInfo : depths[1]),{"name":"if","hash":{},"fn":container.program(185, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":647,"column":20},"end":{"line":727,"column":27}}})) != null ? stack1 : "")
    + "                  </table>\n                </td>\n              </tr>\n";
},"167":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Customer PO </td></tr>\n                      <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POID), depth0))
    + "</strong></td></tr>\n";
},"169":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Order Number</td></tr>\n                      <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PURCHASEID), depth0))
    + "</strong></td></tr>\n";
},"171":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Order Date</td></tr>\n                      <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PODATETIMEFORMATTED), depth0))
    + "</strong></td></tr>\n";
},"173":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Account Rep</td></tr>\n                      <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.ContactName), depth0))
    + "</strong></td></tr>\n";
},"175":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Phone</td></tr>\n                      <tr>\n                        <td style=\"padding: 2px 0;\">\n                          <strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent),{"name":"if","hash":{},"fn":container.program(176, data, 0),"inverse":container.program(178, data, 0),"data":data,"loc":{"start":{"line":623,"column":28},"end":{"line":627,"column":35}}})) != null ? stack1 : "")
    + "                          </strong>\n                        </td>\n                      </tr>\n";
},"176":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                              "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent), depth0))
    + "\n";
},"178":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                              "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(110, data, 0),"inverse":container.program(112, data, 0),"data":data,"loc":{"start":{"line":626,"column":30},"end":{"line":626,"column":172}}})) != null ? stack1 : "")
    + "\n";
},"180":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Email</td></tr>\n                      <tr>\n                        <td style=\"padding: 2px 0;\">\n                          <strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent),{"name":"if","hash":{},"fn":container.program(181, data, 0),"inverse":container.program(183, data, 0),"data":data,"loc":{"start":{"line":637,"column":28},"end":{"line":641,"column":35}}})) != null ? stack1 : "")
    + "                          </strong>\n                        </td>\n                      </tr>\n";
},"181":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                              "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent), depth0))
    + "\n";
},"183":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                              "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":640,"column":30},"end":{"line":640,"column":159}}})) != null ? stack1 : "")
    + "\n";
},"185":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                      <tr>\n                        <td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISHPDIRECT),{"name":"if","hash":{},"fn":container.program(122, data, 0, blockParams, depths),"inverse":container.program(186, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":650,"column":26},"end":{"line":724,"column":33}}})) != null ? stack1 : "")
    + "                        </td>\n                      </tr>\n";
},"186":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISOTHERS),{"name":"if","hash":{},"fn":container.program(187, data, 0, blockParams, depths),"inverse":container.program(206, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":651,"column":26},"end":{"line":724,"column":26}}})) != null ? stack1 : "");
},"187":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                            <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                              <tr class=\"element-header-type"
    + alias2(alias1((depths[1] != null ? depths[1].templateType : depths[1]), depth0))
    + "\">\n                                <td class=\"element-header-content\">\n                                  "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0))
    + " REP AND INFO\n                                </td>\n                              </tr>\n                              <tr>\n                                <td><b>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME),{"name":"if","hash":{},"fn":container.program(188, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":659,"column":39},"end":{"line":659,"column":126}}})) != null ? stack1 : "")
    + "</b></td>\n                              </tr>\n                              <tr>\n                                <td><strong>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR1),{"name":"if","hash":{},"fn":container.program(190, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":662,"column":44},"end":{"line":662,"column":133}}})) != null ? stack1 : "")
    + "</strong></td>\n                              </tr>\n                              <tr>\n                                <td>\n                                  <strong>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR2),{"name":"if","hash":{},"fn":container.program(192, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":666,"column":42},"end":{"line":666,"column":131}}})) != null ? stack1 : "")
    + "</strong>\n                                </td>\n                              </tr>\n                              <tr>\n                                <td>\n                                  <strong>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYCITY),{"name":"if","hash":{},"fn":container.program(194, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":671,"column":42},"end":{"line":671,"column":129}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYSTATE),{"name":"if","hash":{},"fn":container.program(196, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":671,"column":129},"end":{"line":671,"column":225}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYZIP),{"name":"if","hash":{},"fn":container.program(198, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":671,"column":225},"end":{"line":671,"column":316}}})) != null ? stack1 : "")
    + "</strong>\n                                </td>\n                              </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(200, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":676,"column":30},"end":{"line":678,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(202, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":679,"column":30},"end":{"line":681,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(204, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":682,"column":30},"end":{"line":684,"column":37}}})) != null ? stack1 : "")
    + "                            </table>\n";
},"188":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0));
},"190":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR1), depth0));
},"192":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR2), depth0));
},"194":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYCITY), depth0));
},"196":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ",&nbsp;"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYSTATE), depth0));
},"198":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "&nbsp;"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYZIP), depth0));
},"200":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"202":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"204":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"206":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                            <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                              <tr class=\"element-header-type"
    + alias2(alias1((depths[1] != null ? depths[1].templateType : depths[1]), depth0))
    + "\">\n                                <td class=\"element-header-content\">\n                                  "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VARSOURCE_NAME), depth0))
    + " REP AND INFO\n                                </td>\n                              </tr>\n                              <tr><td><b>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.NAME),{"name":"if","hash":{},"fn":container.program(207, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":693,"column":41},"end":{"line":693,"column":186}}})) != null ? stack1 : "")
    + "</b></td></tr>\n                              <tr><td><strong>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ATTRN),{"name":"if","hash":{},"fn":container.program(212, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":694,"column":46},"end":{"line":694,"column":137}}})) != null ? stack1 : "")
    + "</strong></td></tr>\n                              <tr>\n                                <td>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS),{"name":"if","hash":{},"fn":container.program(214, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":697,"column":34},"end":{"line":699,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS1),{"name":"if","hash":{},"fn":container.program(216, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":700,"column":34},"end":{"line":703,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.PHONE),{"name":"if","hash":{},"fn":container.program(218, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":704,"column":34},"end":{"line":706,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.EMAIL),{"name":"if","hash":{},"fn":container.program(220, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":707,"column":34},"end":{"line":709,"column":41}}})) != null ? stack1 : "")
    + "                                </td>\n                              </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(200, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":714,"column":30},"end":{"line":716,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(202, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":717,"column":30},"end":{"line":719,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(204, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":720,"column":30},"end":{"line":722,"column":37}}})) != null ? stack1 : "")
    + "                            </table>\n                          ";
},"207":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.synnex),{"name":"if","hash":{},"fn":container.program(208, data, 0),"inverse":container.program(210, data, 0),"data":data,"loc":{"start":{"line":693,"column":83},"end":{"line":693,"column":179}}})) != null ? stack1 : "");
},"208":function(container,depth0,helpers,partials,data) {
    return "TD SYNNEX Corporation";
},"210":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.NAME), depth0)) != null ? stack1 : "");
},"212":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ATTRN), depth0)) != null ? stack1 : "");
},"214":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS), depth0)) != null ? stack1 : "")
    + "</strong>\n";
},"216":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <br>\n                                    <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS1), depth0)) != null ? stack1 : "")
    + "</strong>\n";
},"218":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <br>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.PHONE), depth0)) != null ? stack1 : "")
    + "\n";
},"220":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <br>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.EMAIL), depth0)) != null ? stack1 : "")
    + "\n";
},"222":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td width=\"30%\" class=\"title\">Notes</td></tr>\n              <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TEMPLATENOTES), depth0))
    + "</strong></td></tr>\n";
},"224":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPMENTNOTES),{"name":"if","hash":{},"fn":container.program(225, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":739,"column":14},"end":{"line":742,"column":21}}})) != null ? stack1 : "");
},"225":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td width=\"30%\" class=\"title\">Shipment Notes</td></tr>\n                <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPMENTNOTES), depth0))
    + "</strong></td></tr>\n";
},"227":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td width=\"30%\" class=\"title\">Contract Terms</td></tr>\n              <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMSBYORDER), depth0))
    + "</strong></td></tr>\n";
},"229":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMS),{"name":"if","hash":{},"fn":container.program(230, data, 0),"inverse":container.program(232, data, 0),"data":data,"loc":{"start":{"line":748,"column":12},"end":{"line":756,"column":12}}})) != null ? stack1 : "");
},"230":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td width=\"30%\" class=\"title\">Contract Terms</td></tr>\n              <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMS), depth0))
    + "</strong></td></tr>\n";
},"232":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TERMS),{"name":"if","hash":{},"fn":container.program(233, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":752,"column":14},"end":{"line":755,"column":21}}})) != null ? stack1 : "")
    + "            ";
},"233":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td width=\"30%\" class=\"title\">Contract Terms</td></tr>\n                <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TERMS), depth0))
    + "</strong></td></tr>\n";
},"235":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isCoreProduct : depth0),{"name":"if","hash":{},"fn":container.program(236, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":764,"column":4},"end":{"line":766,"column":11}}})) != null ? stack1 : "");
},"236":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./orderDetails_items.handlebars"),depth0,{"name":"orderDetails_items","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"238":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isFooterDetail : depth0),{"name":"if","hash":{},"fn":container.program(239, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":770,"column":4},"end":{"line":787,"column":11}}})) != null ? stack1 : "");
},"239":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showFooterDetail),{"name":"if","hash":{},"fn":container.program(240, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":771,"column":6},"end":{"line":773,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.signature : depth0),{"name":"if","hash":{},"fn":container.program(242, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":776,"column":6},"end":{"line":786,"column":13}}})) != null ? stack1 : "");
},"240":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./orderDetails_summary.handlebars"),depth0,{"name":"orderDetails_summary","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"242":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "        <table width=\"100%\" id=\"quoteDetails_notes\">\n          <tr><td>&nbsp;</td></tr>\n          <tr>\n            <td width=\"60%\"></td>\n            <td width=\"40%\">\n              <img src=\""
    + alias1(((helper = (helper = helpers.signature || (depth0 != null ? depth0.signature : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"signature","hash":{},"data":data,"loc":{"start":{"line":782,"column":24},"end":{"line":782,"column":37}}}) : helper)))
    + "\" alt=\"signature\" style=\"width: 180px;\"/> "
    + alias1(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CURRENTDATE), depth0))
    + "\n            </td>\n          </tr>\n        </table>\n";
},"244":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(245, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":792,"column":4},"end":{"line":798,"column":11}}})) != null ? stack1 : "")
    + "    <table width=\"100%\" class=\"element-table "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(247, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":800,"column":45},"end":{"line":800,"column":106}}})) != null ? stack1 : "")
    + "\">\n      <thead>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.headers : stack1),{"name":"each","hash":{},"fn":container.program(249, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":802,"column":8},"end":{"line":804,"column":17}}})) != null ? stack1 : "")
    + "      </thead>\n      <tbody>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.rows : stack1),{"name":"each","hash":{},"fn":container.program(251, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":807,"column":8},"end":{"line":813,"column":17}}})) != null ? stack1 : "")
    + "      </tbody>\n    </table>\n";
},"245":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <table width=\"100%\" class=\"element-header "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":793,"column":48},"end":{"line":793,"column":110}}})) != null ? stack1 : "")
    + " element-header-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":793,"column":126},"end":{"line":793,"column":132}}}) : helper)))
    + "\">\n        <tr>\n          <td class=\"element-header-content\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":795,"column":45},"end":{"line":795,"column":54}}}) : helper)))
    + "</td>\n        </tr>\n      </table>\n";
},"247":function(container,depth0,helpers,partials,data) {
    var helper;

  return "element-table-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":800,"column":83},"end":{"line":800,"column":99}}}) : helper)));
},"249":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <th>"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":803,"column":14},"end":{"line":803,"column":23}}}) : helper)))
    + "</th>\n";
},"251":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(252, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":809,"column":10},"end":{"line":811,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n";
},"252":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <td>"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":810,"column":16},"end":{"line":810,"column":25}}}) : helper)))
    + "</td>\n";
},"254":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "    <table width=\"100%\" id=\"quoteDetails_items\" style=\"padding-top: 0; padding-bottom: 0;\">\n      <tbody>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showEUs : depth0),{"name":"if","hash":{},"fn":container.program(255, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":822,"column":8},"end":{"line":852,"column":15}}})) != null ? stack1 : "")
    + "      </tbody>\n    </table>\n";
},"255":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.orderEUs),{"name":"if","hash":{},"fn":container.program(256, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":823,"column":10},"end":{"line":851,"column":17}}})) != null ? stack1 : "");
},"256":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <tr class=\"element-header-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":824,"column":42},"end":{"line":824,"column":58}}}) : helper)))
    + "\">\n              <td class=\"element-header-content\">\n                End Users\n              </td>\n            </tr>\n            <tr>\n              <td style=\"padding-top: 1px;\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (data && data.root)) && stack1.orderEUs),{"name":"each","hash":{},"fn":container.program(257, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":831,"column":16},"end":{"line":848,"column":25}}})) != null ? stack1 : "")
    + "              </td>\n            </tr>\n";
},"257":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                  <div "
    + ((stack1 = helpers["if"].call(alias1,(data && data.index),{"name":"if","hash":{},"fn":container.program(258, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":832,"column":23},"end":{"line":832,"column":68}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euName : depths[1]),{"name":"if","hash":{},"fn":container.program(260, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":833,"column":20},"end":{"line":835,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euEmail : depths[1]),{"name":"if","hash":{},"fn":container.program(264, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":836,"column":20},"end":{"line":838,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euPhone : depths[1]),{"name":"if","hash":{},"fn":container.program(266, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":839,"column":20},"end":{"line":846,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n";
},"258":function(container,depth0,helpers,partials,data) {
    return "style=\"margin-top: 3px;\"";
},"260":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                      <span>"
    + container.escapeExpression(((helper = (helper = helpers.EUNAME || (depth0 != null ? depth0.EUNAME : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"EUNAME","hash":{},"data":data,"loc":{"start":{"line":834,"column":28},"end":{"line":834,"column":38}}}) : helper)))
    + "</span>"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euEmail : depths[1]),{"name":"if","hash":{},"fn":container.program(261, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":834,"column":45},"end":{"line":834,"column":93}}})) != null ? stack1 : "")
    + "\n";
},"261":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.EMAIL : depth0),{"name":"if","hash":{},"fn":container.program(262, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":834,"column":63},"end":{"line":834,"column":86}}})) != null ? stack1 : "");
},"262":function(container,depth0,helpers,partials,data) {
    return " - ";
},"264":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                      <span>"
    + container.escapeExpression(((helper = (helper = helpers.EMAIL || (depth0 != null ? depth0.EMAIL : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"EMAIL","hash":{},"data":data,"loc":{"start":{"line":837,"column":28},"end":{"line":837,"column":37}}}) : helper)))
    + "</span>\n";
},"266":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euName : depths[1]),{"name":"if","hash":{},"fn":container.program(267, data, 0, blockParams, depths),"inverse":container.program(269, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":840,"column":22},"end":{"line":844,"column":29}}})) != null ? stack1 : "")
    + "                      <span>"
    + container.escapeExpression(((helper = (helper = helpers.PHONE || (depth0 != null ? depth0.PHONE : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"PHONE","hash":{},"data":data,"loc":{"start":{"line":845,"column":28},"end":{"line":845,"column":37}}}) : helper)))
    + "</span>\n";
},"267":function(container,depth0,helpers,partials,data) {
    return "                        - \n";
},"269":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].euEmail : depths[1]),{"name":"if","hash":{},"fn":container.program(270, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":842,"column":22},"end":{"line":844,"column":22}}})) != null ? stack1 : "");
},"270":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.EMAIL : depth0),{"name":"if","hash":{},"fn":container.program(262, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":843,"column":24},"end":{"line":843,"column":47}}})) != null ? stack1 : "")
    + "\n                      ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.templateItemReorder : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":856,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});