





















































































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import { InvoiceAPIRepo } from "../repos/InvoiceAPIRepo";
import { OrderAPIRepo } from "../repos/OrderAPIRepo";
import moment from "moment";
import { htmlParse } from "../helpers/htmlHelpers";
import { getTimezoneInfo } from "@/helpers/ApiHelper";
import directives from "../helpers/directives";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";

interface Props {
  title: string
  selectedIDs: string[],
  selectedAll: boolean,
  profitTotal: number,
  editPaymentForInvoiceID?: string;
}

interface Events {
  onClose: void
  onConfirm: void
}

declare const $: any;
declare const htmlCheck: Function;
const invoiceRepo = new InvoiceAPIRepo();
const orderRepo = new OrderAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    ConfirmRemoveItemModal
  },
  directives
})
export default class InvoicePaidConfirmModal extends TSXComponent<
  Props,
  Events
> {
  @Prop({required: true, default: ""})
  title!: string

  @Prop({required: false, default: []})
  selectedIDs!: string[]

  @Prop({required: false, default: false})
  selectedAll!: boolean

  @Prop({required: false, default: false})
  isOrder!: boolean

  @Prop({required: false, default: 0})
  profitTotal!: number

  @Prop({required: false, default: 0})
  orderTotal!: number

  @Prop({required: false, default: 0})
  paymentDue!: number

  @Prop({required: false, default: false})
  loading!: any

  @Prop({required: false, default: false})
  removing!: any

  @Prop({required: false, default: ""})
  from!: string;

  @Prop({required: false, default: {}})
  invoiceDetails!: any

  @Prop({required: false, default: ""})
  editPaymentForInvoiceID!: string;

  @Prop({required: false, default: {}})
  relatedOrder!: any;

  @Prop({required: false, default: false})
  isInvoice!: boolean

  $refs!: {
    modal: HTMLDivElement
  }

  $parent: any;
  paymentDate = "";
  paymentAmount = "";
  paymentType = "EFT";
  paymentReference = "";
  isPartial = false;
  OrderDue:number = 0;
  modalTitle = "";
  showpartial = true;
  allowUpdate = true;
  paymentCancel = "Cancel";
  taxAmount = "";
  orderInvoiceTotal = "";
  confirmInvoiceRemove = false;
  confirmInvoiceRemovePayment = false;
  isDialogBox = true;
  isremoveInvoice = true;
  paidStatus = 0;
  invoicePaymentId = 0;
  profitTotals = 0;
  isPayCheckPaid = 0;
  customProfit = false;
  lastPaymentAmount = "$0.00";

  async created() {
    this.modalTitle = this.title;
    var payamount = 0;

    const timeInfo = await getTimezoneInfo();
    this.paymentDate = timeInfo.formattedDate;

    if(this.editPaymentForInvoiceID != "") {
      // case user wants to edit payment info
      const invoiceDetails = await invoiceRepo.findOneDetails(this.editPaymentForInvoiceID);
      this.isPayCheckPaid = typeof invoiceDetails.isPayCheckPaid != 'undefined' ? invoiceDetails.isPayCheckPaid : 0;
      this.paidStatus = typeof invoiceDetails.INVOICEDETAILS.IPAID != 'undefined' ? invoiceDetails.INVOICEDETAILS.IPAID : 0;
      this.invoicePaymentId = typeof invoiceDetails.INVOICEDETAILS.INVOICEPAYMENTID != 'undefined' ? invoiceDetails.INVOICEDETAILS.INVOICEPAYMENTID : 0;
      if(typeof invoiceDetails.relatedOrder.confirmedPaymentInfo != "undefined") {
        const confirmedPaymentInfo = invoiceDetails.relatedOrder.confirmedPaymentInfo;
        // this.paymentAmount = confirmedPaymentInfo.PAYMENTAMOUNT.replace(/[^\d.]/g, '') || "";
        this.paymentDate = confirmedPaymentInfo.PAYMENTDATE || "";
        this.profitTotal = confirmedPaymentInfo.PROFITTOTAL || "";
        this.paymentType = confirmedPaymentInfo.PAYMENTTYPE || "EFT";
        this.paymentReference = confirmedPaymentInfo.PAYMENTREFERENCE || "";
        this.taxAmount = confirmedPaymentInfo.TAXAMOUNT || 0;
      }
      this.paymentDate = typeof invoiceDetails.INVOICEDETAILS.IPAIDDATEFORMATTED != "undefined" ? invoiceDetails.INVOICEDETAILS.IPAIDDATEFORMATTED : this.paymentDate;
      // this.paymentAmount = typeof invoiceDetails.INVOICEDETAILS.INVOICETOTAL != "undefined" ? invoiceDetails.INVOICEDETAILS.INVOICETOTAL.toFixed(2) : 0;
      this.orderInvoiceTotal = typeof invoiceDetails.INVOICEDETAILS.INVOICETOTAL != "undefined" ? invoiceDetails.INVOICEDETAILS.INVOICETOTAL.toFixed(2) : 0;
      this.paymentDue = typeof invoiceDetails.INVOICEDUEBALANCE != 'undefined' ? parseFloat(invoiceDetails.INVOICEDUEBALANCE.replace(/[^\d.]/g, '')) : invoiceDetails.INVOICEDETAILS.GRANDTOTAL;
      if(typeof invoiceDetails.relatedOrder.confirmedPaymentInfo.PAYMENTAMOUNT != "undefined") {
          this.paymentAmount = parseFloat(invoiceDetails.relatedOrder.confirmedPaymentInfo.PAYMENTAMOUNT).toFixed(2);
      } else {
          this.paymentAmount = this.paymentAmount = typeof invoiceDetails.INVOICEDETAILS.INVOICETOTAL != "undefined" ? invoiceDetails.INVOICEDETAILS.INVOICETOTAL.toFixed(2) : 0;
      }
      // detect can save
      if(this.paymentType == "Stripe") {
        this.allowUpdate = false;
      }
      // if (this.allowUpdate == false) {
      //   this.paymentCancel = "Save";
      // }
      if(this.paidStatus == 1) {
        this.paymentAmount = typeof invoiceDetails.relatedOrder.confirmedPaymentInfo.PAYMENTAMOUNT != "undefined" ? invoiceDetails.relatedOrder.confirmedPaymentInfo.PAYMENTAMOUNT : invoiceDetails.INVOICEDETAILS.INVOICETOTAL.toFixed(2); 
      }
      this.lastPaymentAmount = typeof invoiceDetails.relatedOrder.confirmedPaymentInfo.LASTPAYMENTAMOUNT != "undefined" ? invoiceDetails.relatedOrder.confirmedPaymentInfo.LASTPAYMENTAMOUNT : "$0.00";
      let invoiceProfitTotal       = invoiceDetails.relatedOrder.confirmedPaymentInfo.PROFITTOTAL;
      let invoiceCustomProfitTotal = invoiceDetails.INVOICEDETAILS.CUSTOMPROFITTOTAL;
      if (typeof invoiceCustomProfitTotal != "undefined" && invoiceCustomProfitTotal != "") {
        this.profitTotal = invoiceCustomProfitTotal;
        let invMargin = invoiceDetails.INVOICEDETAILS.IMARGIN || 0;
        if (typeof invoiceProfitTotal != "undefined" && this.profitTotal != invMargin) {
          this.customProfit = true;
        }
      } else if(typeof invoiceProfitTotal != "undefined") {
        this.profitTotal = invoiceProfitTotal;
      }
    }else {
      if(this.from == "invoice-details") {
        // if call from invoice detail page
        this.paymentAmount = this.invoiceDetails.INVOICETOTAL.toFixed(2) || "";
        // get profit total
        // if(this.invoiceDetails.PURCHASEID) {
        //   const response: any = await orderRepo.findOneDetails(this.invoiceDetails.PURCHASEID);
        //   this.profitTotal = response.MARGIN || 0;
        // }
        this.profitTotal = (typeof this.invoiceDetails.CUSTOMPROFITTOTAL != "undefined" && this.invoiceDetails.CUSTOMPROFITTOTAL != "") ? (this.invoiceDetails.CUSTOMPROFITTOTAL) : (typeof this.relatedOrder.confirmedPaymentInfo.PROFITTOTAL != "undefined" ? this.relatedOrder.confirmedPaymentInfo.PROFITTOTAL : 0);
        this.taxAmount = typeof this.relatedOrder.confirmedPaymentInfo.TAXAMOUNT != "undefined" ? this.relatedOrder.confirmedPaymentInfo.TAXAMOUNT : 0;
        payamount = this.invoiceDetails.INVOICETOTAL;
        this.orderInvoiceTotal = this.invoiceDetails.INVOICETOTAL.toFixed(2) || 0;
        this.customProfit = typeof this.invoiceDetails.CUSTOMPROFITTOTAL != "undefined" && this.invoiceDetails.CUSTOMPROFITTOTAL != "" &&  this.invoiceDetails.CUSTOMPROFITTOTAL != (typeof this.invoiceDetails.IMARGIN != "undefined" ? this.invoiceDetails.IMARGIN : 0) ? true : false;
      }else {
        const invoiceID = this.$parent.currentInvoiceToPaid || "";
        if(invoiceID != "") {
          // if call from tab invoice of order details
          const response = await invoiceRepo.findOneDetails(invoiceID);
          this.paymentAmount = response.INVOICEDETAILS.INVOICETOTAL.toFixed(2) || "";
          payamount = response.INVOICEDETAILS.INVOICETOTAL;
          this.taxAmount = typeof response.relatedOrder.confirmedPaymentInfo.TAXAMOUNT != "undefined" ? response.relatedOrder.confirmedPaymentInfo.TAXAMOUNT : 0;
          this.paymentDue = typeof response.INVOICEDUEBALANCE != 'undefined' ? parseFloat(response.INVOICEDUEBALANCE.replace(/[^\d.]/g, '')) : response.INVOICEDETAILS.GRANDTOTAL;
          this.orderInvoiceTotal = response.INVOICEDETAILS.INVOICETOTAL.toFixed(2) || 0;
          this.paidStatus = typeof response.INVOICEDETAILS.IPAID != 'undefined' ? response.INVOICEDETAILS.IPAID : 0;
          this.profitTotal = (typeof response.INVOICEDETAILS.CUSTOMPROFITTOTAL != "undefined" && response.INVOICEDETAILS.CUSTOMPROFITTOTAL != "") ? (response.INVOICEDETAILS.CUSTOMPROFITTOTAL) : (typeof response.relatedOrder.confirmedPaymentInfo.PROFITTOTAL != "undefined" ? response.relatedOrder.confirmedPaymentInfo.PROFITTOTAL : 0);
          this.customProfit = typeof response.INVOICEDETAILS.CUSTOMPROFITTOTAL != "undefined" && response.INVOICEDETAILS.CUSTOMPROFITTOTAL != "" && response.INVOICEDETAILS.CUSTOMPROFITTOTAL != (typeof response.INVOICEDETAILS.IMARGIN != "undefined" ? response.INVOICEDETAILS.IMARGIN : 0) ? true : false;
        }else {
          // if call from tab status of order details
          // create a paid status for order
          this.modalTitle = "Status Paid Confirmation";
          if(typeof this.$parent.details.ORDERDETAILS.PSTATUS != "undefined" && this.$parent.details.ORDERDETAILS.PSTATUS == 10) {
            this.paymentAmount = this.$parent.details.DUEBALANCE;
          } else {
            this.paymentAmount = this.$parent.details.GRANDTOTAL.toFixed(2) || "";
          }
          payamount = this.$parent.details.GRANDTOTAL;
          this.paymentType = this.$parent.details.PaymentCardName != "" ? "Stripe" : "EFT";
          this.taxAmount = this.$parent.details.TOTALTAX.toFixed(2) || 0;
          this.profitTotal = this.$parent.details.MARGIN_FORMATTED != "undefined" ? this.$parent.details.MARGIN_FORMATTED.replace(/[^\d.]/g, '') : 0;
        }
      }
      // this.OrderDue = this.paymentDue;
      this.OrderDue = this.paymentDue - payamount;

      if(this.OrderDue > 0){
        //console.log("this.OrderD",this.OrderDue)
        this.isPartial = true;
      }
    }
    this.profitTotals = this.profitTotal;
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  async confirm() {
    var isValid = true;
    // remove html for payment ref
    this.paymentReference = this.removeHTML(this.paymentReference);
    this.$validator.errors.clear();
    await this.$validator.validateAll().then(async result => {
      // check payment date format
      let validPaymentDate = true;
      if(this.paymentDate !="" && !moment(this.paymentDate, "MM/DD/YYYY", true).isValid()) {
        validPaymentDate = false;
        this.$validator.errors.add({
          field: "paymentDate",
          msg: "Invalid Date (Date must be in the format mm/dd/yyyy)"
        });
      }
      // check payment amount
      let validPaymentAmount = true;
      if(!parseFloat(this.paymentAmount)) {
        validPaymentAmount = false;
        this.$validator.errors.add({
          field: "paymentAmount",
          msg: "Payment Amount must be greater than 0"
        });
      }
      if(!result || !validPaymentDate || !validPaymentAmount) {
        isValid = false;
      }
    });
    if(!isValid) {
      return false;
    }

    this.loading = true
    this.isPartial = false;
    // if(this.orderTotal > Number(this.paymentAmount) ){
    //   this.isPartial = true;
    // }
    if(Number(this.orderInvoiceTotal) > Number(this.paymentAmount) ){
      this.isPartial = true;
    }
    if (parseFloat(this.paymentAmount) == 0 && this.isremoveInvoice && this.paidStatus == 1) {
      this.confirmInvoiceRemove = true;
      this.isDialogBox = false;
      this.loading = false;
      return false;
    }
    const confirmedVals = {
      paymentAmount: this.paymentAmount,
      paymentDate: this.paymentDate,
      profitTotal: this.profitTotals,
      paymentType: this.paymentType,
      isPartial:this.isPartial,
      paymentReference: this.paymentReference,
      taxAmount: this.taxAmount,
      paymentID : this.invoicePaymentId,
      zeroPayment : parseFloat(this.paymentAmount) == 0 ? true : false
    };
    this.$emit('confirm', confirmedVals);
  }

  close(clickedCancel = false) {
    this.$emit('close', clickedCancel);
  }
  remove(removed = false) {
    if(removed === false){
      this.confirmInvoiceRemovePayment = true;
      this.isDialogBox = false;
      return;
    }
    this.removing = true;
    this.$emit('remove');
  }

  async deleteInvoice() {
    this.isremoveInvoice = false;
    await this.confirm();
  }

  removeHTML(txt) {
    if(htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }
  dueCalculate(){
    // this.paymentDue = this.paymentDue - Number(this.paymentAmount);
    var currDue = this.orderTotal - Number(this.paymentAmount);

    this.isPartial = false;
    // this.showpartial = false;
    if(currDue > 0){
      this.isPartial = true;
      this.showpartial = true;
    }
  }
}
