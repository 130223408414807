














































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import axios from "axios";
import { notifier } from "../models/common";
import BusinessLineToolTip from "../components/BusinessLineToolTip.vue";
interface Props {
  title: string
  selectedIDs: string[],
  selectedAll: boolean,
  totalAll: number
}

interface Events {
  onClose: void
  onSave: void
}

declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class UpdateItemStatusModal extends TSXComponent<
  Props,
  Events
> {
  
  @Prop({required: true })
  fileId!: number

  @Prop({required: false, default: 0})
  option!: number

  @Prop({required: true, default: ''})
  purchaseId!: string

  @Prop({required: true, default: 0})
  pfileID!: number

  $parent: any;

  saving = false;
  showError = false;
  disabledefault = false;

  $refs!: {
    modal: HTMLDivElement
  }

  created() {
    if (this.option) this.disabledefault = true;
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  async save() {
    if (!this.option) {
      this.showError = true;
      return;
    }

    this.saving = true;

    try {
      const response  = await axios.post(dataURL + "?ReturnType=JSON", {
        "controller"        : "Files",
        "FunctionName"      : "FileOption",
        "fileId"            : this.fileId,
        "option"            : this.option,
        "purchaseId"        : this.purchaseId,
        "pfileID"           : this.pfileID
      });
      if (response.data.STATUSMESSAGE) {
        notifier.success(response.data.STATUSMESSAGE);
        this.$parent.fetchFiles();
      }
      else notifier.alert(response.data.STATUSMESSAGE)
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.$emit('close');
    }
  }

  checkIfDisabled() {
    if(this.$validator.errors.items.length || this.saving) {
      return true;
    }
    
    return false;
  }
}
