














































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import EmailMultiselect from "../components/EmailMultiselect.vue";
import { ApiHelper } from "@/helpers/all";

interface Props {
  purchaseID: number;
  details?: any;
  aID?: number;
  type?: string;
  forceProcess?: boolean;
  showNotes?: boolean;
  showSubmitBtn?: boolean;
}

interface Events {}

declare const $: any;
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    EmailMultiselect
  }
})
export default class ProcessOrderModal extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: 0 })
  purchaseID?: number;

  @Prop({ required: false, default: 0 })
  aID?: number;

  @Prop({ required: false, default: {} })
  details?: any;

  @Prop({ required: false, default: "process" })
  type?: string;

  @Prop({ required: false, default: false })
  forceProcess?: boolean;

  @Prop({ required: false, default: true })
  showNotes?: boolean;

  @Prop({ required: false, default: true })
  showSubmitBtn?: boolean;

  @Prop({ required: false, default: 0 })
  processWithSourceId!: number;

  saving: boolean | 'error' = false
  today = new Date();
  purchaseDate = (this.today.getMonth() + 1) + "/" + this.today.getDate() + "/" + this.today.getFullYear();
  priceInfo: any = [];
  DistyTotal = 0;
  loading = false;
  processResult = "";
  orderComment = "";
  customerPO = "";
  processResultErr = false;
  emailDistyLoading: boolean | 'error' = false;
  emailDistyFlag = false;
  // showSubmitBtn = true;
  selectedEmailIds: string[] = [];
  sendTo = "";
  sendToMessage = "";
  emailDistyClose = "Cancel";
  convertOrderId = "";
  selectedEmails: object[] = [];

  $refs!: {
    modal: HTMLDivElement;
  };

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  async created() {
    if(this.type == "process") {
      this.customerPO = this.details.ORDERDETAILS.POID;
    }
    if(typeof this.details.preEmails != "undefined") {
      this.sendTo = ''; // this.details.preEmails[0];
    }
    
    //build price info
    for (var item of this.details.ORDERITEMS) {
      if (
        (item.POINCLUDED == 0 || (item.POINCLUDED && !(item.ISCONFIG || 0))) &&
        item.STATUSID != 1 &&
        item.STATUSID != 2 && 
        (!item.PRODUCTSOURCEID || item.PRODUCTSOURCEID == this.processWithSourceId) && 
        !(item.ISFEE || 0) && 
        !(item.ISCONTRACTFEE || 0)
      ) {
        this.priceInfo.push({
          sku: item.POPRODSKU,
          qty: item.POPRODQUANTITY,
          DistyAvailability: "N/A",
          DistyPrice: "N/A"
        });
      }
    }

    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller      : "Helpers",
        FunctionName    : "GetSKUData",
        SKU             : this.getSKUList().join(),
        sourceId        : this.details.ORDERDETAILS.SOURCE
      });

      // if (response.data.ERROR) {
      //   throw new Error(response.data.ERROR);
      // }
      // if (response.data.status !== 1) {
      //   throw new Error(response.data.STATUSMESSAGE);
      // }

      if(response.data.PriceDetails && response.data.PriceDetails.length) {
        for(var i in this.priceInfo) {
          var tmpPrice = response.data.PriceDetails.find(val => val.OLMS_Product_ID == this.priceInfo[i].sku);
          if(tmpPrice) {
            this.priceInfo[i].DistyAvailability = tmpPrice.Quantity || "N/A";
            this.priceInfo[i].DistyPrice = parseFloat(tmpPrice.Price) || "N/A";
            if(parseFloat(tmpPrice.Price) > 0 && !!this.priceInfo[i].qty) {
              this.DistyTotal += parseFloat(tmpPrice.Price) * this.priceInfo[i].qty;
            }
          }
        }
      }

      // force process?
      if(this.type == "process" && this.forceProcess) {
        this.submit();
      }
    } catch (err) {
      // console.log(err.message); 
    } finally {
      this.loading = false;
    }

    this.$forceUpdate();
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  close(){
    if(this.emailDistyClose == "Cancel") {
      this.$emit('close')
    } else {
      // this.$router.push({ name: "Orders" });
      this.$router.push({
        name: "ViewOrder", 
        params: { id: this.convertOrderId } 
      });
    }
  }

  getSKUList() {
    const ret: string[] = [];
    for (const item of this.details.ORDERITEMS) {
      if (
        (item.POINCLUDED == 0 || (item.POINCLUDED && !(item.ISCONFIG || 0))) &&
        item.STATUSID != 1 &&
        item.STATUSID != 2
      ) {
        ret.push(item.POPRODSKU);
      }
    }
    return ret;
  }

  async validateBeforeSubmit(e) {
    // this.$validator.validateAll().then((result) => {
    //   if (result) {
    //     return this.submit();
    //   }
    // });
    return this.submit();
  }

  getProcessResult(id) {
    return "Order has been pushed to distribution with external order ID: " + id;
  }

  async submit() {  
    try {
      // handle loading
      if(this.emailDistyFlag == true) {
        this.emailDistyLoading = true;
      }else {
        this.saving = true;
      }
      const req = {
        controller: "Orders",
        FunctionName: "PlaceDistributorOrder",
        purchaseID: this.details.ORDERDETAILS.PURCHASEID,
        sourceId: this.processWithSourceId,
        quoteID : this.details.ORDERDETAILS.QUOTEID,
        orderComment: this.orderComment,
        customerPO: this.customerPO,
        processWithSourceId: this.processWithSourceId
      };
      if(this.emailDistyFlag == true) {
        req["emailDisty"] = 1;
        if(this.sendTo != "") {
          req["selectedemails"] = this.sendTo;
        }
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", req);

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      // if (response.data.status !== 1) {
      //   throw new Error(response.data.STATUSMESSAGE);
      // }

      if(response.data.STATUSMESSAGE) {
        this.processResult = response.data.STATUSMESSAGE;
      }else if(typeof response.data.DistributorID != "undefined") {
        this.processResult = this.getProcessResult(response.data.DistributorID);
      }

      if(response.data.STATUS == 0) {// handle error
        this.processResultErr = true;
        if(this.processResult != "") {
          this.processResult = 'Order was not processed due to "' + this.processResult + '"';
        }

        if(this.emailDistyFlag == true) {
          this.emailDistyLoading = "error";
        } else {
          this.saving = 'error';
        }
      }else {
        if(this.emailDistyFlag == true) {
          this.emailDistyLoading = false;
          this.$parent["processOrderModalVisible"] = false;
        } else {
          this.saving = false;
          this.$emit("updateCanProcessWithDisty", false);
          this.showSubmitBtn = false;
          this.emailDistyClose = "Close";
          this.convertOrderId = typeof response.data.CONVERTQUOTEORDERID != "undefined" ? response.data.CONVERTQUOTEORDERID : 0;
          // this.$router.push({ name: "Orders" });
        }
      }

      // auto process?
      if(this.forceProcess) {
        this.showSubmitBtn = false;
      }
    } catch (err) {
      if(this.emailDistyFlag == true) {
        this.emailDistyLoading = "error";
      } else {
        this.saving = 'error';
      }
    } finally {
      this.emailDistyFlag = false;
    }
  }

  emailDisty() {
    this.emailDistyFlag = true;
    // validate emails
    var isValid = true;
		this.sendToMessage = "";
		var tmp: any = [];
		if (this.sendTo != "") {
			var tmpEmails = this.sendTo.split(",");
			for (var anEmail of tmpEmails) {
				anEmail = anEmail.trim();
				if (!tmp.includes(anEmail)) {
					tmp.push(anEmail);
				}
				if (!this.isEmail(anEmail)) {
					isValid = false;
					this.sendToMessage = "Invalid Email";
				}
			}
		}
    if(isValid) {
      this.submit();
    }
  }

  isEmail(email) {
		var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		return regex.test(email);
  }
  
  toggleEmail(e){
    const index = this.selectedEmailIds.findIndex(id => id === e);
    if (index === -1) {
      this.selectedEmailIds.push(e);
      this.sendTo = this.getEUEmails();
      return;
    }
    this.selectedEmailIds = this.selectedEmailIds.filter((email) => email != e);
    this.sendTo = this.getEUEmails();
  }

  selectAllEmails() {
    this.sendTo = this.getEUEmails();
    this.selectedEmailIds = this.details.preEmails.map((val: any) => {
      return val.USERID;
    });
  }
  resetEmails() {
    this.sendTo = '';
    this.selectedEmailIds = [];
  }

  getEUEmails() {
    const emails = this.selectedEmailIds.map((email: any) => email).join(',');
    return emails;
  }

  async addNewHPdirectTo(newEmail) {
    this.details.preEmails.push({
      USERID: newEmail,
      UEMAIL: newEmail,
    });
    this.toggleEmail(newEmail);
    this.sendTo = this.getEUEmails();
    const newHPdirectTo = {};
    for (let i = 0; i < this.details.preEmails.length; i++) {
      const emailInfo = this.details.preEmails[i];
      newHPdirectTo[`${i+1}`] = emailInfo.UEMAIL;
    }
    this.details.ORDERDETAILS.HPDIRECTTO = JSON.stringify(newHPdirectTo);
    const response = await ApiHelper.callApi(
      'post',
      {
        controller: "Orders",
        FunctionName: "UpdateAccountHPdirectTo",
        purchaseID: this.details.ORDERDETAILS.PURCHASEID,
        sourceId: this.details.ORDERDETAILS.SOURCE,
        quoteID : this.details.ORDERDETAILS.QUOTEID,
        HPdirectTo: this.details.ORDERDETAILS.HPDIRECTTO,
        customerPO: this.customerPO
      }
    );
    if (response.STATUS == 1) {
      ApiHelper.showSuccessMessage('Added new email');
    }
  }

}
