















































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import {notifier} from '../models/common'

interface Props {
  labelMin: string;
  labelMax: string;
  min: string;
  max: string;
}

interface Events {
  onFilter: void;
}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class FilterCurrencyBox extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  labelMin!: string;

  @Prop({ required: true })
  labelMax!: string;

  @Prop({ required: true })
  min!: string;

  @Prop({ required: true })
  max!: string;

  @Prop({ required: false, default: false })
  showValueExists!: boolean;

  @Prop({ required: false, default: 'Value Exists' })
  valueExistsLabel: string;

  @Prop({ required: false, default: false })
  valueExists!: boolean;

  $refs!: {
    icon: HTMLSpanElement;
    searchBox: HTMLDivElement;
    searchBoxContainer: HTMLDivElement;
  };

  searchBoxVisible = false;

  inputFocus = false

  vcoConfig = {
    handler: this.close,
    events: ['dblclick', 'click'],
    isActive: !this.inputFocus,
  }

  toggleBox(event) {
    if (event.target !== this.$refs.icon) return;

    const containerHeight = $(this.$refs.searchBoxContainer).outerHeight();

    const $searchBox = $(this.$refs.searchBox);

    if ($searchBox.height() == 0) {
      $searchBox.animate({ height: containerHeight }, 200);
      $searchBox.find('#min').focus();
    } else {
      $searchBox.animate({ height: 0 }, 200);
    }
  }

  close(event) {
    if (event.target === this.$refs.icon) return;

    const $searchBox = $(this.$refs.searchBox);
    $searchBox.animate({ height: 0 }, 200);
  }

  async filter() {
    const result = await this.$validator.validateAll()
    if (!result) return
    if((this.min == undefined || this.min == '') && (this.max == '' || this.max == undefined)) {
      notifier.alert('Please Fill Min or Max value')
    }else {
      this.$emit('filter')
    }
  }
  resetValue(type) {
    if(type == 'min') {
      this.$emit('update:min', '')
    }
    else {
      this.$emit('update:max', '')
    }
    // this.$emit('reset');
    if((this.min == undefined || this.min == '') && (this.max == '' || this.max == undefined)) {
      return
    }else {
      this.$emit('filter')
    }
  }
  reset() {
    this.$emit('update:max', '')
    this.$emit('update:min', '')
    this.$emit('reset')
    if((this.min == undefined || this.min == '') && (this.max == '' || this.max == undefined)) {
      return
    }else {
      this.$emit('filter')
    }
  }

  excludeZeroDollar() {
    this.$emit("valueExists", this.valueExists)
  }
}
