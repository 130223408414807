













































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import { notifier, downloadFileUrl } from "../models/common";
import { InvoiceAPIRepo,InvoiceDepositFilters,InvoiceDepositSort,InvoiceDepositSortField } from "../repos/InvoiceAPIRepo";
import Pagination from "../components/Pagination.vue";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import InvoiceDepositDetailsModal from "../components/InvoiceDepositDetailsModal.vue";
import InvoiceDepositModal from "../components/InvoiceDepositModal.vue";
import PageTitle from '../components/pageTitle.vue';
import { colsResizeable, getRangeFilter, getDateRangeFilter } from "@/helpers/ApiHelper";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import { ApiHelper } from "@/helpers/all";

const invoiceRepo = new InvoiceAPIRepo();
declare function require(params: any): any;

declare const $: any;
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    FilterCurrencyBox,
    InvoiceDepositDetailsModal,
    InvoiceDepositModal,
    PageTitle,
    ExportModal,
    ConfirmRemoveItemModal
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    },
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})

export default class InvoiceDeposits extends TSXComponent<void> {
  $refs!: {
    InvoiceDepositDetailsModal: InvoiceDepositDetailsModal;
  };
  loading = false;
  totalAll = 0;
  total = 0;
  totalDeposittems = 0;
  ret = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1
  };
  pageNumber = 1;
  invoiceDepositId = 0;
  depositModalVisible = false;
  viewType = "CurrentUsers";
  selectedView = false;
  selectedIDs: any = [];
  excludedIDs: number[] = [];
  selectedAll = false;
  exportModalVisible = false;
  depositCheckAll = false;
  selectPageAll = false;
  showFilter = 0;
  invoiceDeposits: any = [];
  invoiceDepositFilters: InvoiceDepositFilters = {
    invoiceDepositId: "",
    invoiceDepositName: "",
    invoiceDepositStartDate: undefined,
    invoiceDepositEndDate: undefined,
    invoiceDepositTotalMin: "",
    invoiceDepositTotalMax: "",
    invoiceDepositIsConfirmed: [0, 1]
  };
  InvoiceDepositSort = {
    field: 0,
    direction: {
      [InvoiceDepositSortField.invoiceDepositId]: 1,
      [InvoiceDepositSortField.invoiceDepositName]: 1,
      [InvoiceDepositSortField.invoiceDepositDate]: 1,
      [InvoiceDepositSortField.invoiceDepositTotal]: 1,
      [InvoiceDepositSortField.invoiceDepositIsConfirmed]: 1
    }
  };
  searchFilters: InvoiceDepositFilters = {
    invoiceDepositId: "",
    invoiceDepositName: "",
    invoiceDepositStartDate: undefined,
    invoiceDepositEndDate: undefined,
    invoiceDepositTotalMin: "",
    invoiceDepositTotalMax: "",
    invoiceDepositIsConfirmed: [0,1]
  };
  confirmDeleteDeposit: boolean = false;
  deleteLoading: boolean = false;
  depositCreateModalVisible = false;

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const ret = await invoiceRepo.findInvoiceDeposits(
        this.invoiceDepositFilters,
        this.InvoiceDepositSort,
        this.pageNumber,
        this.viewType,
        this.selectedView
      );
      this.ret = ret;
      this.invoiceDeposits = ret.INVOICEDEPOSITS;
      this.totalAll = ret.TOTALALL;
      this.total = ret.TOTAL;
      this.totalDeposittems = ret.TOTALDEPOSITITEMS;

      if (
        this.invoiceDepositFilters.invoiceDepositStartDate || this.invoiceDepositFilters.invoiceDepositEndDate ||
        this.invoiceDepositFilters.invoiceDepositId ||  this.invoiceDepositFilters.invoiceDepositName ||
        this.invoiceDepositFilters.invoiceDepositTotalMin || this.invoiceDepositFilters.invoiceDepositTotalMax ||
        this.invoiceDepositFilters.invoiceDepositIsConfirmed.length === 1
      ) {
        this.showFilter = 1;
      } else {
        this.showFilter = 0;
      }
      if (this.selectedAll === true) {
        this.selectAll(true);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  async sortBy(field: InvoiceDepositSortField) {
    this.InvoiceDepositSort.field = field;
    this.InvoiceDepositSort.direction[field] = this.InvoiceDepositSort.direction[field] === 1 ? 2 : 1;
    for (const dirField in this.InvoiceDepositSort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }
      this.InvoiceDepositSort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async sortingByDepositId() {
    await this.sortBy(InvoiceDepositSortField.invoiceDepositId);
  }
  async sortingByDepositName() {
    await this.sortBy(InvoiceDepositSortField.invoiceDepositName);
  }
  async sortingByDepositDate() {
    await this.sortBy(InvoiceDepositSortField.invoiceDepositDate);
  }
  async sortingByDepositTotal() {
    await this.sortBy(InvoiceDepositSortField.invoiceDepositTotal);
  }
  async sortingByIsConfirmed() {
    await this.sortBy(InvoiceDepositSortField.invoiceDepositIsConfirmed);
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if (this.pageNumber <= this.ret.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  async searchById() {
    this.pageNumber = 1;
    if (this.searchFilters.invoiceDepositId == "") {
      return;
    } else {
      this.invoiceDepositFilters.invoiceDepositId = this.searchFilters.invoiceDepositId;
      await this.fetchData();
    }
  }
  async resetSearchById() {
    this.pageNumber = 1;
    this.invoiceDepositFilters.invoiceDepositId = this.searchFilters.invoiceDepositId = "";
    await this.fetchData();
  }

  async searchByDepositName() {
    this.pageNumber = 1;
    if (this.searchFilters.invoiceDepositName == "") {
      return;
    } else {
      this.invoiceDepositFilters.invoiceDepositName = this.searchFilters.invoiceDepositName;
      await this.fetchData();
    }
  }
  async resetSearchByDepositName() {
    this.pageNumber = 1;
    this.invoiceDepositFilters.invoiceDepositName = this.searchFilters.invoiceDepositName = "";
    await this.fetchData();
  }

  async searchByDepositDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.invoiceDepositStartDate == undefined || this.searchFilters.invoiceDepositStartDate == "")
      && (this.searchFilters.invoiceDepositEndDate == "" || this.searchFilters.invoiceDepositEndDate == undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.invoiceDepositFilters.invoiceDepositStartDate = this.searchFilters.invoiceDepositStartDate;
      this.invoiceDepositFilters.invoiceDepositEndDate = this.searchFilters.invoiceDepositEndDate;
      await this.fetchData();
    }
  }
  async resetSearchByDepositDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.invoiceDepositStartDate == undefined || this.searchFilters.invoiceDepositStartDate == "")
      && (this.searchFilters.invoiceDepositEndDate == "" || this.searchFilters.invoiceDepositEndDate == undefined)
    ) {
      return;
    } else {
      this.invoiceDepositFilters.invoiceDepositStartDate = this.searchFilters.invoiceDepositStartDate = "";
      this.invoiceDepositFilters.invoiceDepositEndDate = this.searchFilters.invoiceDepositEndDate = "";
      await this.fetchData();
    }
  }

  async searchByDepositTotal() {
    this.pageNumber = 1;
    this.invoiceDepositFilters.invoiceDepositTotalMin = this.searchFilters.invoiceDepositTotalMin;
    this.invoiceDepositFilters.invoiceDepositTotalMax = this.searchFilters.invoiceDepositTotalMax;
    await this.fetchData();
  }
  async resetSearchByDepositTotal() {
    this.pageNumber = 1;
    this.invoiceDepositFilters.invoiceDepositTotalMin = this.searchFilters.invoiceDepositTotalMin = "";
    this.invoiceDepositFilters.invoiceDepositTotalMax = this.searchFilters.invoiceDepositTotalMax = "";
    await this.fetchData();
  }
  async showDeposits(depositId) {
    if(this.depositModalVisible) {
      await this.$refs.InvoiceDepositDetailsModal.closeModal();
    }
    this.invoiceDepositId = depositId;
    this.depositModalVisible = true;
  }

  listPageRedirection() {
    this.$router.push({ name: "Invoices" });
  }

  async checkIsConfirmed(event, depositID) {
    event.stopPropagation();
    try {
      const requestData = {
        controller: "Invoices",
        FunctionName: "depositList",
        action: "checkIsConfirmed",
        depositID: depositID
      };
      const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);
      if(response.data.STATUS) {
         await this.fetchData();
      }
    } catch (err) {
      // console.log(err.message);
    }
  }

  async checkIsConfirmedList(isConfirmed: number) {
    this.pageNumber = 1;
    const i = this.invoiceDepositFilters.invoiceDepositIsConfirmed.findIndex(s => s === isConfirmed);
    if (i === -1) {
      this.invoiceDepositFilters.invoiceDepositIsConfirmed.push(isConfirmed);
    } else {
      this.invoiceDepositFilters.invoiceDepositIsConfirmed.splice(i, 1);
    }
    await this.fetchData();
  }

  async uncheckAllIsConfirmedList() {
    this.pageNumber = 1;
    this.invoiceDepositFilters.invoiceDepositIsConfirmed = [];
    await this.fetchData();
  }

  async resetIsConfirmedList() {
    this.pageNumber = 1;
    this.invoiceDepositFilters.invoiceDepositIsConfirmed = [0, 1];
    await this.fetchData();
  }

  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedIDs.length;
    }

    return ret;
  }

  selectAll(selectedAll = true) {
    this.selectedAll = selectedAll;
    this.selectedIDs = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (var val of this.invoiceDeposits) {
        if (!this.excludedIDs.includes(val.DEPOSITID)) {
          this.selectedIDs.push(val.DEPOSITID);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }

  checkExcludedIDs(depositId) {
    if (this.selectedAll) {
      if (!this.selectedIDs.includes(depositId)) {
        if (!this.excludedIDs.includes(depositId)) {
          this.excludedIDs.push(depositId);
        }
      } else if (this.excludedIDs.includes(depositId)) {
        this.$delete(
          this.excludedIDs,
          this.excludedIDs.findIndex(id => id == depositId)
        );
      }

      if (this.getSelectedTotal(this.total) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
  }

  depositCheckAllChange() {
    this.depositCheckAll = !this.depositCheckAll;
    if (!this.depositCheckAll) {
      this.selectInPage(false);
    } else {
      this.selectInPage(true);
    }
  }

  selectInPage(selectedAllPage = true) {
    this.selectPageAll = selectedAllPage;
    this.showFilter = 0;
    if (this.selectPageAll == true) {
      for (var val of this.invoiceDeposits) {
        if(this.selectedIDs.indexOf(val.DEPOSITID) == -1)  {
          this.selectedIDs.push(val.DEPOSITID);
        }
        this.$delete(
          this.excludedIDs,
          this.excludedIDs.findIndex((id) => id == val.DEPOSITID)
        );
      }
    } else {
      for (var val of this.invoiceDeposits) {
        let selectedIDIndex = this.selectedIDs.indexOf(val.DEPOSITID);
        this.selectedIDs.splice(selectedIDIndex, 1);
      }
    }
  }

  async performExport(formats: ExportFormat[]) {
    this.loading = true;
    this.exportModalVisible = false;
    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.csvDownLoadFn());
    }

    exportFns.forEach(fn => fn());

    this.loading = false;
  }

  async csvDownLoadFn() {
    let fileUrl = '';
    try {
      // export csv
      const reqData = {
        controller: "Invoices",
        FunctionName: "ExportInvoiceDeposit",
        ExportType: 'CSV',
        exportForm: 'list',
        selectedIDs: this.selectedIDs,
        excludedIDs: this.excludedIDs,
        selectedAll: this.selectedAll,
        "depositId": this.invoiceDepositFilters.invoiceDepositId,
        "depositName": this.invoiceDepositFilters.invoiceDepositName,
        "invoiceDepositStartDate": this.invoiceDepositFilters.invoiceDepositStartDate ? this.invoiceDepositFilters.invoiceDepositStartDate : '',
        "invoiceDepositEndDate": this.invoiceDepositFilters.invoiceDepositEndDate ? this.invoiceDepositFilters.invoiceDepositEndDate : '',
        "isConfirmed": this.invoiceDepositFilters.invoiceDepositIsConfirmed ? this.invoiceDepositFilters.invoiceDepositIsConfirmed.join(",") : '',
        "view": this.viewType,
        selectedView: this.selectedView,
      }

      if (typeof this.invoiceDepositFilters.invoiceDepositTotalMin !== 'undefined') {
          reqData["invoiceDepositTotalMin"] = this.invoiceDepositFilters.invoiceDepositTotalMin
      }
      if (typeof this.invoiceDepositFilters.invoiceDepositTotalMax !== 'undefined') {
        reqData["invoiceDepositTotalMax"] = this.invoiceDepositFilters.invoiceDepositTotalMax
      }
      if (this.InvoiceDepositSort.field) {
        reqData["order"] = this.InvoiceDepositSort.field;
        reqData["direction"] = this.InvoiceDepositSort.direction[this.InvoiceDepositSort.field].toString();
      }
      if (!this.invoiceDepositFilters.invoiceDepositIsConfirmed.length) {
        reqData['isConfirmed'] = "-1";
      }

      const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);
      if (response.data.STATUS === 1) {
        fileUrl = response.data.S3URL;
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err: Error) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async deleteDeposit() {
    this.deleteLoading = true;
    let reqData = {};
    reqData["controller"]     = "Invoices";
    reqData["FunctionName"]   = "depositList";
    reqData["action"]         = "deleteDeposit";
    reqData["depositIDs"]      = this.selectedIDs;

    const response = await ApiHelper.callApi("post", reqData);
    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      this.confirmDeleteDeposit = false;
      this.selectedIDs = [];
      await this.fetchData();
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
    this.deleteLoading = false;
  }
}
