var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <td style=\"text-align: left; border-bottom: 5px solid #b1b1b1; padding-right: 3px;\">"
    + container.escapeExpression(((helper = (helper = helpers.header || (depth0 != null ? depth0.header : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"header","hash":{},"data":data,"loc":{"start":{"line":4,"column":88},"end":{"line":4,"column":98}}}) : helper)))
    + "</td>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "  <tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].SELECTEDHEADERSARRAY : depths[1]),{"name":"each","hash":{},"fn":container.program(4, data, 2, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":9,"column":4},"end":{"line":11,"column":13}}})) != null ? stack1 : "")
    + "  </tr>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "    <td style=\"text-align: left; padding-right: 3px;\"> "
    + ((stack1 = (helpers.getIestedIterationValues||(depth0 && depth0.getIestedIterationValues)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),blockParams[1][0],((stack1 = blockParams[0][0]) != null ? stack1.column : stack1),{"name":"getIestedIterationValues","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":10,"column":55},"end":{"line":10,"column":101}}})) != null ? stack1 : "")
    + " </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table width=\"100%\" id=\"customerList_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.SELECTEDHEADERSARRAY : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":13}}})) != null ? stack1 : "")
    + "  </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.CUSTOMERS : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 2, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":7,"column":2},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "</table>";
},"useData":true,"useDepths":true,"useBlockParams":true});