import axios from "axios";
import { ApiHelper } from "@/helpers/all";

declare const dataURL: string

export enum SortField {
	Name = 1,
	Profit = 2,
}

export type Sort = {
	field: SortField | null
	direction: {
		[SortField.Name]: 1 | 2,
		[SortField.Profit]: 1 | 2
	}
}

export class FinancesDashboardAPIRepo{

	async fetchData(container){
	    const response = await axios.post(dataURL + "?ReturnType=JSON", container);
	    return response.data
	}

}

export enum BoardSortField {
	boardName = 1,
	ranking = 2,
	yearRevenue = 3,
	yearProfit = 4,
	opportunities = 5
}

export type BoardSort = {
	field: BoardSortField | null
	direction: {
		[BoardSortField.boardName]: 1 | 2,
		[BoardSortField.ranking]: 1 | 2,
		[BoardSortField.yearRevenue]: 1 | 2,
		[BoardSortField.yearProfit]: 1 | 2,
		[BoardSortField.opportunities]: 1 | 2
	}
}

export type BoardFilters = {
	boardName?: string,
	rankMin?: string | undefined,
	rankMax?: string | undefined
}

export enum BoardDetailsSortField {
	revenue = 1,
	profit = 2,
	targetProfit = 3,
}

export type BoardDetailsSort = {
	field: BoardDetailsSortField | null
	direction: {
		[BoardDetailsSortField.revenue]: 1 | 2,
		[BoardDetailsSortField.profit]: 1 | 2,
		[BoardDetailsSortField.targetProfit]: 1 | 2,
	}
}

export class FinancialBoardsAPIRepo {

	async find(filters: BoardFilters, sort: BoardSort, pageNumber: number, maxRows: number = 0) {
		const reqData: any = this.prepareFilteredData(filters, sort);
		reqData["FunctionName"] = "BoardList";
		reqData["maxRows"] = maxRows ? maxRows : sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows();
		reqData["PageNumber"] = pageNumber.toString();
		reqData["allowCompanyBoard"] = true;

		const response = await ApiHelper.callApi("post", reqData);

		if (response.ERROR) {
			throw new Error(response.ERROR);
		}
		if (response.STATUS !== 1) {
			// throw new Error(response.STATUSMESSAGE);
			return response
		}

		return response
	}

	prepareFilteredData(filters: BoardFilters, sort: BoardSort) {
		var ret = {
			"controller" 				: "Finances",
			"boardName" 				: filters.boardName
		}

		if (sort.field) {
			ret["order"] = sort.field;
			ret["direction"] = sort.direction[sort.field].toString();
		}

		if (typeof filters.rankMin !== 'undefined') {
			ret['rankMin'] = filters.rankMin;
		}

		if (typeof filters.rankMax !== 'undefined') {
			ret['rankMax'] = filters.rankMax;
		}

		return ret;
	}

	async deleteBoard(boardUUID: string) {
		let reqData = {}
		reqData["controller"] = "Finances";
		reqData["FunctionName"] = "BoardUpdate";
		reqData["action"] = "deleteBoard";
		reqData["boardUUID"] = boardUUID;

		const response = await ApiHelper.callApi("post", reqData);

		if (response.ERROR) {
			throw new Error(response.ERROR);
		}
		if (response.STATUS !== 1) {
			// throw new Error(response.STATUSMESSAGE);
			return response
		}

		return response
	}

	async findOneDetails(boardUUID: string, viewType: string, action: string = "boardView", boardLimit: string = "", isCompanyBoard: boolean = false, sort: BoardDetailsSort, year: number | string = new Date().getFullYear(), targetYear: number = new Date().getFullYear() ) {
		let reqData = {}
		reqData["controller"] = "Finances";
		reqData["FunctionName"] = "BoardUpdate";
		reqData["action"] = action;
		reqData["boardUUID"] = boardUUID;
		reqData["viewType"] = viewType;
		reqData["isCompanyBoard"] = isCompanyBoard ? true : false;
		reqData["expenseYear"] = year;
		reqData["targetYear"] = targetYear;

		if (boardLimit.length) {
			reqData["boardLimit"] = boardLimit;
		}

		if (sort && sort.field) {
			reqData["boardDetailsOrder"] = sort.field;
			reqData["boardDetailsDirection"] = sort.direction[sort.field].toString();
		}

		const response = await ApiHelper.callApi("post", reqData);

		if (response.ERROR) {
			throw new Error(response.ERROR);
		}
		if (response.STATUS !== 1) {
			// throw new Error(response.STATUSMESSAGE);
			return response
		}

		return response
	}

	async addExpenses(boardUUID: string, expenses: any, month: number = 0, year: number | string = new Date().getFullYear(), expenseTypeUUID: string = "") {
		let reqData = {}
		reqData["controller"] = "Finances";
		reqData["FunctionName"] = "BoardUpdate";
		reqData["action"] = "addExpenses";
		reqData["boardUUID"] = boardUUID;
		reqData["expenses"] = expenses;
		reqData["expenseMonth"] = month;
		reqData["expenseYear"] = year;
		reqData["expenseTypeUUID"] = expenseTypeUUID;

		const response = await ApiHelper.callApi("post", reqData);

		if (response.ERROR) {
			throw new Error(response.ERROR);
		}
		if (response.STATUS !== 1) {
			// throw new Error(response.STATUSMESSAGE);
			return response
		}

		return response
	}

	async addBoardEmployeePlan(boardUUID: string, selectedPlanID: any, year: number | string = new Date().getFullYear()) {
		let reqData = {}
		reqData["controller"] 			= "Finances";
		reqData["FunctionName"] 		= "BoardUpdate";
		reqData["action"] 					= "addEmployeePlan";
		reqData["boardUUID"] 				= boardUUID;
		reqData["selectedPlanID"] 	= selectedPlanID;
		reqData["expenseYear"] 			= year;

		const response = await ApiHelper.callApi("post", reqData);

		if (response.ERROR) {
			throw new Error(response.ERROR);
		}
		if (response.STATUS !== 1) {
			// throw new Error(response.STATUSMESSAGE);
			return response
		}

		return response
	}

	async findEmpPlanDetails(boardUUID: string, year: number | string = new Date().getFullYear()) {
		let reqData = {}
		reqData["controller"] 			= "Finances";
		reqData["FunctionName"] 		= "BoardUpdate";
		reqData["action"] 					= "employeePlanDetails";
		reqData["boardUUID"] 				= boardUUID;
		reqData["expenseYear"] 			= year;

		const response = await ApiHelper.callApi("post", reqData);

		if (response.ERROR) {
			throw new Error(response.ERROR);
		}
		if (response.STATUS !== 1) {
			// throw new Error(response.STATUSMESSAGE);
			return response
		}

		return response
	}

	async findExpensesDetails(boardUUID: string, year: number | string = new Date().getFullYear()) {
		let reqData = {}
		reqData["controller"] 			= "Finances";
		reqData["FunctionName"] 		= "BoardUpdate";
		reqData["action"] 					= "expensesDetails";
		reqData["boardUUID"] 				= boardUUID;
		reqData["expenseYear"] 			= year;

		const response = await ApiHelper.callApi("post", reqData);

		if (response.ERROR) {
			throw new Error(response.ERROR);
		}
		if (response.STATUS !== 1) {
			// throw new Error(response.STATUSMESSAGE);
			return response
		}

		return response
	}

	async addBoardExpensesType(boardUUID: string, expensesTypeName: string = "", expenseTypeUUID: string = "") {
		let reqData = {}
		reqData["controller"] 			= "Finances";
		reqData["FunctionName"] 		= "BoardUpdate";
		reqData["action"] 					= "addExpensesType";
		reqData["boardUUID"] 				= boardUUID;
		reqData["expensesTypeName"] = expensesTypeName;
		reqData["expenseTypeUUID"] 	= expenseTypeUUID;

		const response = await ApiHelper.callApi("post", reqData);

		if (response.ERROR) {
			throw new Error(response.ERROR);
		}
		if (response.STATUS !== 1) {
			// throw new Error(response.STATUSMESSAGE);
			return response
		}

		return response
	}

	async deleteExpensesType(boardUUID: string, expenseTypeUUID: string = "") {
		let reqData = {}
		reqData["controller"] 			= "Finances";
		reqData["FunctionName"] 		= "BoardUpdate";
		reqData["action"] 					= "deleteExpensesType";
		reqData["boardUUID"] 				= boardUUID;
		reqData["expenseTypeUUID"] 	= expenseTypeUUID;

		const response = await ApiHelper.callApi("post", reqData);

		if (response.ERROR) {
			throw new Error(response.ERROR);
		}
		if (response.STATUS !== 1) {
			// throw new Error(response.STATUSMESSAGE);
			return response
		}

		return response
	}

	async exportBoardDetails(boardUUID: string, viewType: string, sort: BoardDetailsSort, year: number | string = new Date().getFullYear(), exportType: string = "", targetYear: number = new Date().getFullYear()) {
		let reqData = {}
		reqData["controller"] = "Finances";
		reqData["FunctionName"] = "BoardExportView";
		reqData["boardUUID"] = boardUUID;
		reqData["ExportType"] = exportType;
		reqData["action"] = "boardView";
		reqData["boardUUID"] = boardUUID;
		reqData["viewType"] = viewType;
		reqData["isCompanyBoard"] = false;
		reqData["expenseYear"] = year;
		reqData["targetYear"] = targetYear;

		if (sort && sort.field) {
			reqData["boardDetailsOrder"] = sort.field;
			reqData["boardDetailsDirection"] = sort.direction[sort.field].toString();
		}

		const response = await ApiHelper.callApi("post", reqData);

		if (response.ERROR) {
			throw new Error(response.ERROR);
		}
		if (response.STATUS !== 1) {
			// throw new Error(response.STATUSMESSAGE);
			return response
		}

		return response
	}
}