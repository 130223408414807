import axios from "axios";

declare const dataURL: string;
export class CustomerAPIRepo {
	async exportFileUrl(type: string, ids: (number | undefined)[], viewType = "", selectedAll = false, excludedIDs: any = [], filters: any = {}, sort: any = {}, viewYear:number | string = ''): Promise<string> {
		var requestData: any = this.prepareFilteredData(filters, sort);
		requestData = {
			...requestData,
			controller: "Customers",
			ExportType: type,
			FunctionName: "Export",
			selectedIds: ids,
			selectedAll: selectedAll,
			excludedIDs: excludedIDs,
			viewYear: viewYear
		};

		if (viewType != "") {
			requestData["view"] = viewType;
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}

		return response.data.S3URL
	}

	async customersList(customerIDs: (number | undefined)[], isExport = 1, viewType = "", selectedAll = false, excludedIDs: any = [], filters: any = {}, sort: any = {}, maxRows:number = 0, viewYear:number | string = ''): Promise<string> {
		var requestData: any = this.prepareFilteredData(filters, sort);
		requestData = {
			...requestData,
			controller: "Customers",
			sub: 2,
			Export: isExport,
			FunctionName: "List",
			selectedIds: customerIDs,
			selectedAll: selectedAll,
			excludedIDs: excludedIDs,
			maxRows: maxRows,
			viewYear: viewYear
		};

		if (viewType != "") {
			requestData["view"] = viewType;
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data
	}

	prepareFilteredData(filters, sort) {
		var ret = {};
		if (filters.sAccountID != "") {
			ret["sAccountID"] = filters.sAccountID;
		}
		if (filters.sAccountName != "") {
			ret["sAccountName"] = filters.sAccountName;
		}
		if (filters.aStatus && filters.aStatus != "") {
			ret["aStatus"] = filters.aStatus.join(",");
		}
		if (filters.AccountTagID && filters.AccountTagID != "") {
			ret["selectedTagIDs"] = filters.AccountTagID.join(",");
		}
		if (filters.groupIDs && filters.groupIDs != "") {
			ret["groupIDs"] = filters.groupIDs.join(",");
		}
		if (filters.selectedSecurityGroups && filters.selectedSecurityGroups != "") {
			ret["selectedSecurityGroups"] = filters.selectedSecurityGroups.join(",");
		}
		if (filters.oppTotalMin && filters.oppTotalMin != "") {
			ret["oppTotalMin"] = filters.oppTotalMin;
		}
		if (filters.currentProfitTotalMin && filters.currentProfitTotalMin != "") {
			ret["currentProfitTotalMin"] = filters.currentProfitTotalMin;
		}
		if (filters.currentProfitTotalMax && filters.currentProfitTotalMax != "") {
			ret["currentProfitTotalMax"] = filters.currentProfitTotalMax;
		}
		if (filters.nonZeroAmountProfit && filters.nonZeroAmountProfit != "") {
			ret["nonZeroAmountProfit"] = filters.nonZeroAmountProfit;
		}
		if (filters.currentRevenueTotalMin && filters.currentRevenueTotalMin != "") {
			ret["currentRevenueTotalMin"] = filters.currentRevenueTotalMin;
		}
		if (filters.currentRevenueTotalMax && filters.currentRevenueTotalMax != "") {
			ret["currentRevenueTotalMax"] = filters.currentRevenueTotalMax;
		}
		if (filters.nonZeroAmountRevenue && filters.nonZeroAmountRevenue != "") {
			ret["nonZeroAmountRevenue"] = filters.nonZeroAmountRevenue;
		}
		if (filters.oppTotalMax && filters.oppTotalMax != "") {
			ret["oppTotalMax"] = filters.oppTotalMax;
		}
		if (filters.allTimeRevenueTotalMin && filters.allTimeRevenueTotalMin != "") {
			ret["allTimeRevenueTotalMin"] = filters.allTimeRevenueTotalMin;
		}
		if (filters.allTimeRevenueTotalMax && filters.allTimeRevenueTotalMax != "") {
			ret["allTimeRevenueTotalMax"] = filters.allTimeRevenueTotalMax;
		}
		if (filters.nonZeroAmountAllTimeRevenue && filters.nonZeroAmountAllTimeRevenue != "") {
			ret["nonZeroAmountAllTimeRevenue"] = filters.nonZeroAmountAllTimeRevenue;
		}
		if (sort.field != null) {
			ret["order"] = sort.field;
		}
		if (sort.direction != null) {
			ret["direction"] = sort.direction;
		}
		if (sort.type != null) {
			ret["type"] = sort.type;
		}

		return ret;
	}
}