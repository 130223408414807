import { render, staticRenderFns } from "./HardwareDashboard.vue?vue&type=template&id=f54ae4a6&scoped=true"
import script from "./HardwareDashboard.vue?vue&type=script&lang=tsx"
export * from "./HardwareDashboard.vue?vue&type=script&lang=tsx"
import style0 from "./HardwareDashboard.vue?vue&type=style&index=0&id=f54ae4a6&prod&scoped=true&lang=less"
import style1 from "./HardwareDashboard.vue?vue&type=style&index=1&id=f54ae4a6&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f54ae4a6",
  null
  
)

export default component.exports