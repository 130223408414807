






































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import {
  QuoteAPIRepo,
  Sort,
  SortField
} from "../repos/QuoteAPIRepo";
import { UserAPIRepo } from "../repos/UserAPIRepo";
import CustomerSearchTooltip from "../components/CustomerSearchTooltip.vue";
import { colsResizeable } from "../helpers/ApiHelper";
import ModalProductCatalog from "../components/Quote/ProductCatalog.vue";
import ProductCatalog from "../components/Quote/ProductCatalog.vue";
import { ApiHelper } from "../helpers/all";
import TooltipStandardStatus from "../components/Quote/TooltipStandardStatus.vue";
import FilterSearch from "../components/List/FilterSearch.vue";
import FilterCheckboxes from "../components/List/FilterCheckboxes.vue";
import FilterDate from "../components/List/FilterDate.vue";
import StandardProductsToolTip from "@/components/QuoteStandard/StandardProductsToolTip.vue";
import {notifier} from "@/models/common";

declare function require(params: any): any;

declare const dataURL: string;
declare const $: any;
const quoteRepo = new QuoteAPIRepo();
const userRepo = new UserAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    StandardProductsToolTip,
    FilterDate,
    FilterCheckboxes,
    FilterSearch,
    TooltipStandardStatus,
    ProductCatalog,
    ModalProductCatalog,
    Pagination,
    CustomerSearchTooltip
  }
})
export default class QuoteStandards extends TSXComponent<void> {
  tab = 1;
  $root: any;
  selectedID: string[] = [];
  currentRole = sessionStorage.getItem("userRole");
  loading = false;
  sort: any = {
    field: null,
    direction: {
      [SortField.Id]: 1,
      [SortField.Name]: 1,
      [SortField.Customer]: 1,
      [SortField.GrandTotal]: 1,
      [SortField.Date]: 1,
      [SortField.qOpenStatus]: 1,
      [SortField.Source]: 1,
      [SortField.Total]: 1,
      [SortField.DepartmentName]: 1,
      [SortField.system]: 1
    }
  };
  pageData = {
    errorMessage: '',
    TOTALPAGES: 0,
    TOTAL: 0,
    TOTALALLITEMS: 0,
    GETALERTS: [],
    CURRENTPAGE: 1,
  };
  filters = {
    status: [0, 1, 2, 3],
    specialPricingID: '',
    enddateEFL: '',
    fromdateEFL: '',
  };
  items = [];
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["QuoteStandards"] ? JSON.parse(sessionStorage.pageDetails)["QuoteStandards"] : 1;
  productCountTooltipIndex = -1;
  accounts = [];
  selectedAll:boolean = false;
  excludedIDs: string[] = [];
  selectPageAll:boolean = false;

  async created() {
    await this.fetchData(true);
    colsResizeable({ ms: 500 });
  }

  getEndOfLifeSummary(item) {
    return item.ENDOFLIFEFORMATTED; //`${moment(item.ENDOFLIFEDATE).format('MMM DD, YYYY')} to ${moment(item.EXPIREDATE).format('MMM DD, YYYY')}`;
  }

  getSelectedAccounts(ids: string) {
    const selectedIds = (ids ? ids.split(',') : []).map((id) => Number.parseInt(id, 10));
    const selectedAccounts = this.accounts.filter((item: any) => selectedIds.includes(item.AID));
    return selectedAccounts;
  }

  async getAccounts() {
    const response = await ApiHelper.callApi("post", {
      controller: "Accounts",
      FunctionName: "List",
      getAll: 1,
    });
    if (response.STATUS) {
      const data = response || {};
      this.accounts = data.ACCOUNTS || [];
    }
  }

  async fetchData(initialLoad: boolean = false) {
    this.pageData.errorMessage = '';
    this.loading = true;
    if (initialLoad) {
      await this.getAccounts();
    }

    const response = await ApiHelper.callApi("post", {
      controller: "Quotes",
      FunctionName: this.tab == 1 ? "StandardList" : "StandardListAccounts",
      search: "",
      view: "",
      maxRows: (sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows()),
      PageNumber: this.pageNumber.toString(),
      filters: this.filters
    });
    this.loading = false;
    if (response.STATUS == 1) {
      let tmpId = 0;
      this.items = (response.ITEMS || []).map((item) => {

        let isMain = false;
        if (this.tab == 2) {
          if (item.AID != tmpId) {
            isMain = true;
          }
          tmpId = item.AID;
        }

        item.ISMAIN = isMain;
        let statusName = '';
        switch (item.STANDARDSTATUS) {
          case 1:
            statusName = 'Active';
            break;
          case 2:
            statusName = 'Waiting for Approval';
            break;
          case 3:
            statusName = 'Terminated';
            break;
          default:
            statusName = '-';
            break;
        }
        item.STATUSNAME = statusName;
        item.ACCOUNTS = item.STANDARDACCOUNTIDS ? this.getSelectedAccounts(item.STANDARDACCOUNTIDS || '') : [];
        return item;
      });
      this.pageData = response;
    } else {
      this.pageData.errorMessage = response.STATUSMESSAGE || 'Cant get data';
    }
  }

  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if (sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["QuoteStandards"]) {
      const oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["QuoteStandards"] = this.pageNumber;
      sessionStorage.setItem('pageDetails', JSON.stringify(oldData))
    }
    if (this.pageNumber <= this.pageData.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  async updateStandardStatus(item, statusId) {
    const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Quotes",
          FunctionName: "UpdateQuoteStandardStatus",
          selectedIDs: item.STANDARDUUID,
          status: statusId
        }
    );
    if (response.STATUS) {
      ApiHelper.showSuccessMessage(`Status Updated Successfully`);
    } else {
      ApiHelper.showErrorMessage(response.STATUSMESSAGE || 'Cant update Standard Status');
    }
  }

  //go to search page
  gotoSearch(searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  }

  checkExcludedIDs(items, key) {
    if (this.selectedAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex(id => id == val[key])
          );
        }
      }
    }
  }
  
  updateStatus(value) {
    if(value.length) {
      this.filters.status = value;
    } else {
      this.filters.status = [-1];
      this.fetchData();
    }
  }

  createNew(createFor) {
    if (createFor == 'Order') {
      this.$router.push({
        name: "OrderCreate",
        params: {
          fromQuote: "0"
        },
        query: {
          fromStandardList: "1",
          standardIds: this.selectedID.join(",")
        }
      });
    } else if (createFor == 'Quote') {
      this.$router.push({
        name: "QuoteCreate",
        query: {
          fromStandardList: "1",
          standardIds: this.selectedID.join(",")
        }
      });
    }
  }
}
