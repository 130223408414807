import { render, staticRenderFns } from "./CustomerDetailsTabContacts.vue?vue&type=template&id=21f55ed7&scoped=true"
import script from "./CustomerDetailsTabContacts.vue?vue&type=script&lang=tsx"
export * from "./CustomerDetailsTabContacts.vue?vue&type=script&lang=tsx"
import style0 from "./CustomerDetailsTabContacts.vue?vue&type=style&index=0&id=21f55ed7&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f55ed7",
  null
  
)

export default component.exports