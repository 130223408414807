





























































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";
import UserSuggestInput from "./UserSuggestInput.vue";
import directives from "../helpers/directives";
import { ApiHelper } from "@/helpers/all";

declare const $: any;
declare const dataURL: string;

interface Props {
  list: any;
  loading: boolean;
  allocatedQty: number;
  totalQty: number;
  currentIndex: number;
  currentItem: any;
}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
    UserSuggestInput
  },
  directives
})
export default class UsersAllocationTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: true })
  list!: any;

  @Prop({ required: true })
  loading!: boolean;

  @Prop({ required: false, default: 0 })
  allocatedQty!: number;

  @Prop({ required: false, default: 0 })
  totalQty!: number;

  @Prop({ required: false, default: -1 })
  currentIndex!: number;

  @Prop({ required: false, default: {} })
  currentItem!: any;

  @Prop({ required: false, default: 0 })
  deploymentId!: number;

  @Prop({ required: false, default: undefined })
  updateAllocation?: Function;

  $parent: any;
  userID = 0;
  userName = "";
  userType = 1;
  qtyAvailable = 0;
  remainingQty = 0;
  isDelete = false;
  calculatingQty = false;

  async created() {
    this.remainingQty = parseFloat(
      (this.totalQty - this.allocatedQty).toFixed(2)
    );
    this.qtyAvailable = this.remainingQty;
  }

  beforeDestroy() {
    //
  }

  customChange(e) {
    if (e == null) {
      this.userID = 0;
    }
  }

  async selectUser(data) {
    this.userName = data.account.UFNAME + " " + data.account.ULNAME;
    this.userID = data.account.USERID;
    $("input#qtyTxt")
      .focus()
      .select();
  }

  async addQuantity() {
    var result = true;
    var realQtyAvailable = this.qtyAvailable;
    $(
      ".quick-edit-qty span.left, .quick-edit-qty span.right, .edit-comm-txt"
    ).removeClass("custom-error");
    if (this.userID == 0) {
      result = false;
    }

    var existedLinkID: any = [];
    var val: any;
    if (this.list.length) {
      // check if existed in list
      for (val of this.list) {
        if (val.USERID == this.userID && typeof val.LINKID != "undefined") {
          existedLinkID.push(val.LINKID);
        }
      }
      if (existedLinkID.length) {
        // existed, re-calculate quantity available
        let total = 0;
        this.list.forEach(function(val: any, index) {
          if (val.LINKID != existedLinkID[0]) {
            total += parseFloat(val.ALLOCATED);
          }
        });
        total = parseFloat(total.toFixed(2));
        realQtyAvailable = parseFloat((this.totalQty - total).toFixed(2));
      }
    }

    if (
      isNaN(this.remainingQty) ||
      this.remainingQty <= 0 ||
      this.remainingQty > realQtyAvailable
    ) {
      if (this.userID > 0) {
        $(".quick-edit-qty span.right").addClass("custom-error");
      }
      result = false;
    }
    var data = {};

    // re-check total allocation
    data = {
      controller: "Deployments",
      FunctionName: "GetAssetsAllocated",
      deploymentId: this.deploymentId,
      productId: this.currentItem.PRODUCTID,
      ignoreUserId: this.userID,
      type: "related_users"
    };
    const response = await axios.post(dataURL + "?ReturnType=JSON", data);
    var balanceQty = this.totalQty - response.data.totalAllocated;
    var showWarning = false;
    if (this.remainingQty > balanceQty) {
      result = false;
      $(".quick-edit-qty span.right").addClass("custom-error");
      showWarning = true;
    }

    if (result) {
      this.calculatingQty = true;
      try {
        const response = this.updateAllocation
          ? await this.updateAllocation({
              controller: "Deployments",
              FunctionName: "UpdateAllocation",
              linkId: existedLinkID.length ? existedLinkID[0] : 0,
              deploymentId: this.deploymentId,
              userId: this.userID,
              productId: this.currentItem.PRODUCTID,
              allocated: this.remainingQty
            })
          : undefined;

        if (response && response.data.STATUS == 1) {
          ApiHelper.updateDeploymentPercent(this.deploymentId).then(ret => {
            this.$emit("updateDeploymentPercent", ret);
          });
          this.updateList({
            LINKID: existedLinkID.length
              ? existedLinkID[0]
              : response.data.LINKID,
            USERID: this.userID,
            FULLNAME: this.userName,
            ALLOCATED: this.remainingQty
          });
        }

        //reset
        this.userName = "";
        this.userID = 0;
      } catch (err) {
        console.log(err.message);
      } finally {
        this.calculatingQty = false;
      }
    }

    if (showWarning) {
      // notifier.warning(`Allocation cannot exceed ${this.totalQty}`);
      notifier.warning(`${balanceQty} number of items remain to be allocated`);
      // this.$emit("reload");
    }
  }

  updateList(item) {
    var existed = false;
    var index = 0;
    var doDelete =
      typeof item.remove != "undefined" && item.remove == true ? true : false;
    var total = 0;

    if (typeof item.ALLOCATED != "undefined") {
      item.ALLOCATED = parseFloat(item.ALLOCATED);
    }

    for (var i in this.list) {
      if (this.list[i].LINKID == item.LINKID) {
        //existed
        existed = true;
        if (doDelete) {
          //find index
          index = parseInt(i);
        } else {
          //update percentage
          this.list[i].ALLOCATED = item.ALLOCATED;
        }
        break;
      }
    }

    if (!existed) {
      //insert
      this.list.push(item);
    }

    if (doDelete) {
      this.$delete(this.list, index);
    }

    //calculate on new list
    this.list.forEach(function(val: any, index) {
      val.oldAllocated = val.ALLOCATED;
      total += parseFloat(val.ALLOCATED);
    });
    total = parseFloat(total.toFixed(2));
    this.qtyAvailable = parseFloat((this.totalQty - total).toFixed(2));
    this.remainingQty = this.qtyAvailable;

    // update assests allocated
    this.$emit("updateAllocated", {
      allocated: total,
      currentIndex: this.currentIndex
    });
  }

  async removeAllocation(item, remainingQty) {
    this.calculatingQty = true;
    this.isDelete = true;
    try {
      const response = this.updateAllocation
        ? await this.updateAllocation({
            controller: "Deployments",
            FunctionName: "UpdateAllocation",
            action: "remove",
            linkId: item.LINKID,
            deploymentId: this.deploymentId,
            userId: item.USERID,
            productId: this.currentItem.PRODUCTID
          })
        : undefined;
      if (response && response.data.STATUS == 1) {
        ApiHelper.updateDeploymentPercent(this.deploymentId).then(ret => {
          this.$emit("updateDeploymentPercent", ret);
        });
        this.updateList({
          LINKID: item.LINKID,
          USERID: item.USERID,
          remove: true
        });
        // this.$emit("reload");
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.calculatingQty = false;
      this.isDelete = false;
    }
  }

  async updateQuantity(item) {
    var result = true;
    $(".quick-edit-qty span.left, .quick-edit-qty span.right").removeClass(
      "custom-error"
    );
    $(".edit-comm-txt[data-linkid='" + item.LINKID + "']").removeClass(
      "custom-error"
    );

    if (item.ALLOCATED <= 0 || isNaN(item.ALLOCATED)) {
      $(".edit-comm-txt[data-linkid='" + item.LINKID + "']").addClass(
        "custom-error"
      );
      result = false;
    }

    // cannot over max available
    var maxAvailable = 0;
    this.list.forEach(function(val, index) {
      if (val.LINKID != item.LINKID) {
        maxAvailable += parseFloat(val.ALLOCATED);
      }
    });
    maxAvailable = parseFloat((this.totalQty - maxAvailable).toFixed(2));
    if (item.ALLOCATED > maxAvailable) {
      $(".edit-comm-txt[data-linkid='" + item.LINKID + "']").addClass(
        "custom-error"
      );
      result = false;
    }

    if (result == true) {
      this.calculatingQty = true;
      try {
        const response = this.updateAllocation
          ? await this.updateAllocation({
              controller: "Deployments",
              FunctionName: "UpdateAllocation",
              linkId: item.LINKID,
              deploymentId: this.deploymentId,
              userId: item.USERID,
              productId: this.currentItem.PRODUCTID,
              allocated: item.ALLOCATED
            })
          : undefined;
        if (response && response.data.STATUS == 1) {
          ApiHelper.updateDeploymentPercent(this.deploymentId).then(ret => {
            this.$emit("updateDeploymentPercent", ret);
          });
          this.updateList({
            LINKID: item.LINKID,
            USERID: item.USERID,
            FULLNAME: item.FULLNAME,
            ALLOCATED: item.ALLOCATED
          });
          // this.$emit("reload");
        }
      } finally {
        this.calculatingQty = false;
      }
    }
  }

  checkAllocated(item) {
    var domItem = $(".edit-comm-txt[data-linkid='" + item.LINKID + "']");
    // if (domItem.hasClass("custom-error")) {
    //   item.ALLOCATED = item.oldAllocated;
    //   domItem.removeClass("custom-error");
    // }
  }

  clickOutside() {
    if (!this.calculatingQty && !this.isDelete) {
      this.$emit("close");
    }
  }

  async searchUsers(key: string) {
    const requestObj: any = {
      controller: "Deployments",
      FunctionName: "UsersList",
      deploymentId: this.deploymentId
    };
    if (key) {
      // requestObj.getAll = 1;
      requestObj.key = key;
    }
    const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
    if (response.data.ERROR) {
      return [];
    }
    return response.data.ITEMS;
  }
}
