import { render, staticRenderFns } from "./ProcessOrderModal.vue?vue&type=template&id=52963c60&scoped=true"
import script from "./ProcessOrderModal.vue?vue&type=script&lang=tsx"
export * from "./ProcessOrderModal.vue?vue&type=script&lang=tsx"
import style0 from "./ProcessOrderModal.vue?vue&type=style&index=0&id=52963c60&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52963c60",
  null
  
)

export default component.exports