


































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import Pagination from "@/components/Pagination.vue";
import { notifier, downloadFileUrl } from "../models/common";
import LaddaButton from "@/components/LaddaButton.vue";
import { colsResizeable, colsResizeableReset, getRangeFilter } from "@/helpers/ApiHelper";
import { ApiHelper } from "@/helpers/all";
import CompPlanAccountComponent from "@/components/PayrollAccount.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import FilterCurrencyBox from "@/components/FilterCurrencyBox.vue";
import EmployeePlanStatusComponent, {
  EmployeePlanStatusValue
} from "@/components/EmployeePlan/EmployeePlanStatus.vue";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import ExportToGustoModal from "@/components/ExportToGustoModal.vue";

declare const getMaxRows: Function;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    LaddaButton,
    CompPlanAccountComponent,
    ListHeaderActions,
    FilterCurrencyBox,
    EmployeePlanStatusComponent,
    ConfirmRemoveItemModal,
    ExportToGustoModal
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    }
  }
})

export default class EmployeePlans extends TSXComponent<void> {
  loading = false;
  pageNumber = 1;
  isSearch = 0;
  totalAll = 0;
  totalEmpCompitems = 0;
  details: any = {
    TOTALPAGES: 1,
    CURRENTPAGE: 1,
  };
  items: any[] = [];
  pageHeaders = {
    CSTATUS: "Status",
    EMPLOYEENAME: "Employee",
    OTEYEAR: "Year",
    ACCOUNTTOTAL: "Accounts",
    COMP_TARGET_FORMATTED: "Realized Profit / Target",
    COMPTODATE_FORMATTED: "Comp To Date",
    OTEBASE_FORMATTED: "Base OTE",
    OTECOMM_FORMATTED: "Comm OTE",
    TOTALOTE_FORMATTED: "OTE (Base + Comm)"
  };
  headerEmpComp = [
    "CSTATUS",
    "EMPLOYEENAME",
    "OTEYEAR",
    "ACCOUNTTOTAL",
    "COMP_TARGET_FORMATTED",
    "COMPTODATE_FORMATTED",
    "OTEBASE_FORMATTED",
    "OTECOMM_FORMATTED",
    "TOTALOTE_FORMATTED"
  ];
  selectedHeaders = [
    "EMPLOYEENAME",
    "OTEYEAR"
  ];
  searchHeader = "";
  payrollAccountTooltipIndex = -1;

  filters: any = {
    empName: "",
    oteBaseMin: undefined,
    oteBaseMax: undefined,
    oteCommMin: undefined,
    oteCommMax: undefined,
    oteTotalMin: undefined,
    oteTotalMax: undefined,
    compTargetMin: undefined,
    compTargetMax: undefined,
    status: [0, 1],
    statusName: ['Depreciated', 'Active']
  }
  searchFilters: any = {
    empName: "",
    oteBaseMin: undefined,
    oteBaseMax: undefined,
    oteCommMin: undefined,
    oteCommMax: undefined,
    oteTotalMin: undefined,
    oteTotalMax: undefined,
    compTargetMin: undefined,
    compTargetMax: undefined
  }
  SortField = {
    Name: 1,
    Year: 2,
    OteBase: 3,
    OteComp: 4,
    OteTotal: 5,
    TargetProfit: 6,
    Status: 7
  }
  sort: any = {
    field: null,
    direction: {
      Name: 1,
      Year: 1,
      OteBase: 1,
      OteComp: 1,
      OteTotal: 1,
      TargetProfit: 1,
      Status: 1
    }
  };
  confirmDepreciated: boolean = false;
  compId: number = 0;
  compUUID: string = "";
  status: number = 0;
  empId: number = 0;
  hasChangedStatusFilters: boolean = false;
  currentRole = sessionStorage.getItem("userRole");
  selectedID: string[] = [];
  empPlanCheckAll: boolean = false;
  totalEmpPlans: (number | undefined)[] = [];
  showExportToGustoModal: boolean = false;

  async created() {
    await this.fetchData(true);
  }

  async fetchData(initialLoad: boolean = false) {
    this.loading = true;
    this.hasChangedStatusFilters = false;
    try {
      let reqData = this.prepareFilteredData();
      reqData["FunctionName"]   = "EmployeePlan";
      reqData["pageNumber"]     = this.pageNumber;
      reqData["maxRows"]        = sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : getMaxRows();

      const response = await ApiHelper.callApi("post", reqData);

      this.details = response;
      this.items = this.details.EMPLOYEEPLANS || [];
      this.isSearch = this.details.ISSEARCH;
      this.totalAll = this.details.TOTALALL;
      this.totalEmpCompitems = this.details.TOTALEMPCOMPITEMS;
      if (this.details.userEmployeePlanHeaders && this.details.userEmployeePlanHeaders.length) {
        this.selectedHeaders = this.details.userEmployeePlanHeaders;
      }

      this.totalEmpPlans = [];
      for (var val of this.items) {
        if (this.selectedID.includes(val.COMPID)) {
          this.totalEmpPlans.push(val.COMPID);
        }
      }
      if(this.items.length == this.totalEmpPlans.length) {
        this.empPlanCheckAll = true;
      }
      else{
        this.empPlanCheckAll = false;
      }

    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }

    colsResizeable({ ms: 500 });

  }


  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= (this.details.TOTALPAGES || 1) && this.pageNumber >= 1) {
      this.fetchData();
    }
  }


  //go to search page
  gotoSearch = function(this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  sorting(c) {
    switch (c) {
      case "EMPLOYEENAME":
        this.sortingByName();
        break;
      case "OTEYEAR":
        this.sortingByYear();
        break;
      case "OTEBASE_FORMATTED":
        this.sortingByOteBase();
        break;
      case "OTECOMM_FORMATTED":
        this.sortingByOteComp();
        break;
      case "TOTALOTE_FORMATTED":
        this.sortingByOteTotal();
        break;
      case "COMP_TARGET_FORMATTED":
        this.sortingByTargetProfit();
        break;
      case "CSTATUS":
        this.sortingByStatus();
        break;
    }
  }

  async sortBy(field: any) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async sortingByName() {
    this.sortBy(this.SortField.Name);
  }

  async sortingByYear() {
    this.sortBy(this.SortField.Year);
  }
  async sortingByOteBase() {
    this.sortBy(this.SortField.OteBase);
  }

  async sortingByOteComp() {
    this.sortBy(this.SortField.OteComp);
  }

  async sortingByOteTotal() {
    this.sortBy(this.SortField.OteTotal);
  }

  async sortingByTargetProfit() {
    this.sortBy(this.SortField.TargetProfit);
  }

  async sortingByStatus() {
    this.sortBy(this.SortField.Status);
  }

  searchByHeader() {
    const filteredHeader = Object.entries(this.pageHeaders).reduce((header, [key, value]) => {
      if (value.toLowerCase().search(this.searchHeader.toLowerCase()) != -1) {
        header[key] = value;
      }
      return header;
    }, {});
    return filteredHeader;
  }

  clickOutsideHeader() {
    this.searchHeader = "";
  }

  async addHeader() {
    colsResizeableReset();
    var sortedHeaders: string[] = [];
    for (var headerEmpComp in this.headerEmpComp) {
      var invoiceIndex: string = this.headerEmpComp[headerEmpComp];
      const selectedIndex = this.selectedHeaders.includes(invoiceIndex);
      if (selectedIndex) {
        sortedHeaders.push(invoiceIndex);
      }
    }
    this.selectedHeaders = sortedHeaders;
    const response = await ApiHelper.callApi("post", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: this.selectedHeaders.join(","),
      from: "employeePlan",
    });

    if (response.ERROR) {
      throw new Error(response.ERROR);
    }
    if (response.STATUS !== 1) {
      throw new Error(response.STATUSMESSAGE);
    }
    colsResizeable();
  }

  async searchByEmpName() {
    this.pageNumber = 1;
    this.resetSelected();
    if (this.searchFilters.empName == "") {
      notifier.alert("Please Fill Employee Name");
    } else {
      this.filters.empName = this.searchFilters.empName;
      await this.fetchData();
    }
  }

  async searchByOteBase() {
    this.pageNumber = 1;
    this.resetSelected();
    this.filters.oteBaseMin = this.searchFilters.oteBaseMin;
    this.filters.oteBaseMax = this.searchFilters.oteBaseMax;
    await this.fetchData();
  }

  async searchByOteComm() {
    this.pageNumber = 1;
    this.resetSelected();
    this.filters.oteCommMin = this.searchFilters.oteCommMin;
    this.filters.oteCommMax = this.searchFilters.oteCommMax;
    await this.fetchData();
  }

  async searchByOteTotal() {
    this.pageNumber = 1;
    this.resetSelected();
    this.filters.oteTotalMin = this.searchFilters.oteTotalMin;
    this.filters.oteTotalMax = this.searchFilters.oteTotalMax;
    await this.fetchData();
  }

  async searchByCompTarget() {
    this.pageNumber = 1;
    this.resetSelected();
    this.filters.compTargetMin = this.searchFilters.compTargetMin;
    this.filters.compTargetMax = this.searchFilters.compTargetMax;
    await this.fetchData();
  }

  async resetSearchByEmpName(allowFetchData = true) {
    this.pageNumber = 1;
    this.resetSelected();
    this.filters.empName = this.searchFilters.empName = "";
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByOteBase(allowFetchData = true) {
    this.pageNumber = 1;
    this.resetSelected();
    this.filters.oteBaseMin = this.searchFilters.oteBaseMin = undefined;
    this.filters.oteBaseMax = this.searchFilters.oteBaseMax = undefined;
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByOteComm(allowFetchData = true) {
    this.pageNumber = 1;
    this.resetSelected();
    this.filters.oteCommMin = this.searchFilters.oteCommMin = undefined;
    this.filters.oteCommMax = this.searchFilters.oteCommMax = undefined;
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByOteTotal(allowFetchData = true) {
    this.pageNumber = 1;
    this.resetSelected();
    this.filters.oteTotalMin = this.searchFilters.oteTotalMin = undefined;
    this.filters.oteTotalMax = this.searchFilters.oteTotalMax = undefined;
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByCompTarget(allowFetchData = true) {
    this.pageNumber = 1;
    this.resetSelected();
    this.filters.compTargetMin = this.searchFilters.compTargetMin = undefined;
    this.filters.compTargetMax = this.searchFilters.compTargetMax = undefined;
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetStatusList(allowFetchData = true) {
    this.pageNumber = 1;
    this.resetSelected();
    this.filters.status = [0, 1];
    this.filters.statusName = ['Depreciated', 'Active'];
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async uncheckAllStatusList() {
    this.pageNumber = 1;
    this.resetSelected();
    this.filters.status = [];
    this.filters.statusName = [];
    await this.fetchData();
  }

  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    switch (header) {
      case 'EMPLOYEENAME':
        searchKey = this.filters.empName;
        break;
      case 'OTEBASE_FORMATTED':
        filter = getRangeFilter(this.filters.oteBaseMin, this.filters.oteBaseMax);
        break;
      case 'OTECOMM_FORMATTED':
        filter = getRangeFilter(this.filters.oteCommMin, this.filters.oteCommMax);
        break;
      case 'TOTALOTE_FORMATTED':
        filter = getRangeFilter(this.filters.oteTotalMin, this.filters.oteTotalMax);
        break;
      case 'COMP_TARGET_FORMATTED':
        filter = getRangeFilter(this.filters.compTargetMin, this.filters.compTargetMax);
        break;
      case 'CSTATUS':
        var statusFilters = [0, 1];
        if (!this.filters.statusName.length) {
          filter = "None";
        } else if (statusFilters.length == this.filters.status.length && statusFilters.every(value => this.filters.status.includes(value))) {
          filter = "";
        } else if (this.filters.statusName.length) {
          filter = this.filters.statusName.join(", ");
        }
        break;
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  async resetColumn(header) {
    switch (header) {
      case 'EMPLOYEENAME':
        this.resetSearchByEmpName();
        break;
      case 'OTEBASE_FORMATTED':
        this.resetSearchByOteBase();
        break;
      case 'OTECOMM_FORMATTED':
        this.resetSearchByOteComm();
        break;
      case 'TOTALOTE_FORMATTED':
        this.resetSearchByOteTotal();
        break;
      case 'COMP_TARGET_FORMATTED':
        this.resetSearchByCompTarget();
        break;
      case 'CSTATUS':
        this.resetStatusList();
        break;
      default:
        break;
    }
  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }

  async changeStatus(
    compUUID: string,
    status: EmployeePlanStatusValue,
    empId: number
  ) {
    this.compUUID = compUUID;
    this.status = status;
    this.empId = empId;
    if (status == 0) {
      await this.saveEmpStatus();
    } else {
      this.confirmDepreciated = true;
    }
  }

  async saveDepreciated() {
    await this.saveEmpStatus();
    this.confirmDepreciated = false;
  }

  async saveEmpStatus() {
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "EmployeePlanAdd",
      empStatus: this.status,
      user_account: this.empId,
      action: "updateEmpStatus",
      compUUID: this.compUUID
    });

    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      await this.fetchData();
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
  }

  async checkStatusList(status: number, name: string) {
    this.pageNumber = 1;
    this.resetSelected();
    this.hasChangedStatusFilters = true;
    const i = this.filters.status.findIndex(s => s === status);
    if (i === -1) {
      this.filters.status.push(status);
    } else {
      this.filters.status.splice(i, 1);
    }
    if (i === -1) {
      this.filters.statusName.push(name);
    } else {
      this.filters.statusName.splice(i, 1);
    }
  }

  empPlanCheckAllChange() {
    this.empPlanCheckAll = !this.empPlanCheckAll;
    if (!this.empPlanCheckAll) {
      this.selectInPage(false);
    } else {
      this.selectInPage(true);
    }
  }

  selectInPage(selectedAllPage = true) {
    if (selectedAllPage) {
      for (var val of this.items) {
        if(this.selectedID.indexOf(val.COMPID) == -1)  {
          this.selectedID.push(val.COMPID);
        }
        if(this.totalEmpPlans.indexOf(val.COMPID) == -1)  {
          this.totalEmpPlans.push(val.COMPID);
        }
      }
    } else {
      for (var val of this.items) {
        let selectedIDIndex = this.selectedID.indexOf(val.COMPID);
        let totalEmpPlanIndex = this.totalEmpPlans.indexOf(val.COMPID);
        this.selectedID.splice(selectedIDIndex, 1);
        this.totalEmpPlans.splice(totalEmpPlanIndex, 1);
      }
    }
  }

  checkExcludedIDs(compID) {
    if(compID) {
      if (this.selectedID.includes(compID)) {
        this.totalEmpPlans.push(compID);
      }
      else {
        this.$delete(
          this.totalEmpPlans,
          this.totalEmpPlans.findIndex((id) => id == compID)
        );
      }
    }
    if(this.items.length == this.totalEmpPlans.length && this.items.length != 0) {
      this.empPlanCheckAll = true;
    }
    else{
      this.empPlanCheckAll = false;
    }
  }
  
  getSelectedTotal() {
    var ret = this.selectedID.length;
    return ret;
  }

  resetSelected() {
    this.selectedID = [];
  }

  async exportToGusto(data) {
    if (data.planMonth) {
      let reqData = this.prepareFilteredData();
      reqData["FunctionName"]   = "ExportEmpGusto";
      reqData["ExportType"]     = "CSV";
      reqData["selectedID"]     = this.selectedID;
      reqData["planMonth"]     = data.planMonth;
  
      const response = await ApiHelper.callApi("post", reqData);
  
      if (response.STATUS === 1) {
        let fileUrl = response.S3URL;
        this.showExportToGustoModal = false;
        return downloadFileUrl(fileUrl);
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
    }
  }

  prepareFilteredData() {
    let reqData = {
      controller: "Finances"
    }

    if (this.filters.empName) {
      reqData["empName"] = this.filters.empName;
    }

    if (this.sort.field) {
      reqData["order"] = this.sort.field;
      reqData["direction"] = this.sort.direction[this.sort.field].toString();
    }

    if (this.filters.oteBaseMin) {
      reqData['oteBaseMin'] = this.filters.oteBaseMin;
    }

    if (this.filters.oteBaseMax) {
      reqData['oteBaseMax'] = this.filters.oteBaseMax;
    }

    if (this.filters.oteCommMin) {
      reqData['oteCommMin'] = this.filters.oteCommMin;
    }

    if (this.filters.oteCommMax) {
      reqData['oteCommMax'] = this.filters.oteCommMax;
    }

    if (this.filters.oteTotalMin) {
      reqData['oteTotalMin'] = this.filters.oteTotalMin;
    }

    if (this.filters.oteTotalMax) {
      reqData['oteTotalMax'] = this.filters.oteTotalMax;
    }

    if (this.filters.compTargetMin) {
      reqData['compTargetMin'] = this.filters.compTargetMin;
    }

    if (this.filters.compTargetMax) {
      reqData['compTargetMax'] = this.filters.compTargetMax;
    }

    if (this.filters.status.length) {
      reqData['empStatus'] =  this.filters.status.join(",");
    } else {
      reqData['empStatus'] = "-1";
    }

    return reqData;
  }

  async exportAllCustomer() {
    this.loading = true;
    let reqData = this.prepareFilteredData();
    reqData["FunctionName"]   = "ExportEmpAllCustomer";
    reqData["ExportType"]     = "EmpAllCustomerCSV";
    reqData["selectedID"]     = this.selectedID;

    const response = await ApiHelper.callApi("post", reqData);

    this.loading = false;
    if (response.STATUS === 1) {
      let fileUrl = response.S3URL;
      return downloadFileUrl(fileUrl);
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
  }
}
