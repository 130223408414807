



































































































































































































import { validateFn } from "../helpers";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component,Prop, Watch,Model } from "vue-property-decorator";
import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
declare function require(params: any): any;
import { DashboardAPIRepo } from "../repos/DashboardAPIRepo";
const template = require('../templates/dashboard/dashboard.handlebars')
import InfoTooltip from '../components/InfoTooltip.vue'

const dashboardRepo = new DashboardAPIRepo();

interface ILineChart extends TSXComponent<void> {
  renderLineChart()
}

interface IBarChart extends TSXComponent<void> {
  renderBarChart()
}

interface IChartConfig {
  label?: string,
  backgroundColor?: string,
  borderColor?: string,
  name?: string,
  borderWidth?: number,
  fill?: boolean,
  data: number[],
}

// apex
const allMonths = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

var braekmonth = 11 - new Date().getMonth() ;
var startPoint = new Date().getMonth();
var lastYear:string[] = [];
var Curryear:string[] = [];
for (var i = 0; i < 12; i++) {
	var mon = allMonths[i].substring(0,3);
	if(startPoint < i){
		lastYear.push(mon+", "+( new Date().getFullYear() - 1));
	}
	else{
		Curryear.push(mon+", "+(new Date().getFullYear()));
		// Curryear.push(i);
	}
}
const last12 = lastYear.concat(Curryear)

@Component({
  inheritAttrs: false,
  components: {
		InfoTooltip
  }
})
export default class Dashboard extends TSXComponent<void> {
  @Prop({ required: true })
  customerid!: number;

  @Prop({ required: true })
  aID!: number;

  @Prop({required: false, default: []})
  grouping!: string[];

  currYear = new Date().getFullYear();
  loading = false;
  dashView = "CurrentUsers";
  tagIDs: number[] = [];
  gTagIDs: number[] = [];
  sourceIDs: number[] = [];
  accountTagsWO = [];
  groupTagIDs: number[] = [];
  selectedTags = [];
  businessLines: any = {};
  businessLineIds: string[] = [];
  businessLineName: string[] = [];

  CurrentView = "Grouping";
  CurrentHeader = "Grouping";
  selectedView = false;

  // chartOptions = {
  //   legend: {
  //     display: false,
  //   }
  // }

  barOptions:any ={
		chart: {
			height: "100%",
			defaultLocale: "en",
			type: "line",
			stacked: true,
			dropShadow: {
				enabled: true,
				color: "#000",
				top: 18,
				left: 7,
				blur: 10,
				opacity: 0.2,
			},
			animations: {
				enabled: true,
				easing: "linear",
				dynamicAnimation: {
					speed: 1000,
				},
			},
			toolbar: { show: false },
			zoom: { enabled: false },
		},
		colors: ["#0ea1ff"],
		dataLabels: {
			enabled: false,
    },
    stroke: {
			curve: "smooth",
			width: 3,
			dashArray: [0],
		},
		markers: {
			size: 6,
			fillOpacity: 0,
		},
		xaxis: {
			categories: [],
			tooltip: {
				enabled: false,
			},
			axisTicks: {
				show: true,
				height: 11,
			},
		},
		yaxis: {
			show: false,
		},
		tooltip: {
			x: {
				formatter: function (value) {
					return allMonths[value-1].substring(0,3) +", "+( new Date().getFullYear() - 1) ;
				},
			},
			y: {
				formatter: function (value) {
					var formatedAmount = value;
					if (!isNaN(value)) {
						value = value.toFixed(2);
						formatedAmount = "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					}
					return formatedAmount;
				},
			},
		},
		grid: { show: false },
  };
  Chartoptions2:any ={
		chart: {
			height: "100%",
			defaultLocale: "en",
			type: "line",
			stacked: true,
			dropShadow: {
				enabled: true,
				color: "#000",
				top: 18,
				left: 7,
				blur: 10,
				opacity: 0.2,
			},
			animations: {
				enabled: true,
				easing: "linear",
				dynamicAnimation: {
					speed: 1000,
				},
			},
			toolbar: { show: false },
			zoom: { enabled: false },
		},
		colors: ["#0ea1ff"],
		dataLabels: {
			enabled: false,
    },
    stroke: {
			curve: "smooth",
			width: 3,
			dashArray: [0],
		},
		markers: {
			size: 6,
			fillOpacity: 0,
		},
		xaxis: {
			categories: [],
			tooltip: {
				enabled: false,
			},
			axisTicks: {
				show: true,
				height: 11,
			},
		},
		yaxis: {
			show: false,
		},
		tooltip: {
			x: {
				formatter: function (value) {
					return last12[value - 1];
				},
			},
			y: {
				formatter: function (value) {
					var formatedAmount = value;
					if (!isNaN(value)) {
						value = value.toFixed(2);
						formatedAmount = "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					}
					return formatedAmount;
				},
			},
		},
		grid: { show: false },
  };
	get chartOptions():any{
		return {
			chart: {
				height: 350,
				defaultLocale: "en",
				type: "line",
				// stacked: true,
				events: {
					click: (event, chartContext, config)  =>  {
						if(config.dataPointIndex >= 0 || config.seriesIndex >= 0){
							// The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
							this.saleRedirect(config.globals.categoryLabels[config.dataPointIndex].split("-")[1],config.globals.categoryLabels[config.dataPointIndex],config.seriesIndex,config.dataPointIndex)
						}
					}
				},
				dropShadow: {
					enabled: true,
					color: "#000",
					top: 18,
					left: 7,
					blur: 10,
					opacity: 0.2,
				},
				animations: {
					enabled: true,
					easing: "linear",
					dynamicAnimation: {
						speed: 1000,
					},
				},
				toolbar: { show: false },
				zoom: { enabled: false },
			},
			colors: ["#0ea1ff", "#9ea1ff"],
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: "smooth",
				width: 3,
				dashArray: [0, 0, 0],
			},
			markers: {
				size: 6,
				fillOpacity: 0,
			},
			xaxis: {
				categories: [],
				tooltip: {
					enabled: false,
				},
				axisTicks: {
					show: true,
					height: 11,
				},
			},
			yaxis: {
				show: false,
			},
			tooltip: {
				x: {
					formatter: function (value) {
						return "Month: " + allMonths[value-1];
					},
				},
				y: {
					formatter: function (value) {
						var formatedAmount = value;
						if (!isNaN(value)) {
							value = value.toFixed(2);
							formatedAmount = "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
						}
						return formatedAmount;
					},
				},
			},
			grid: { show: false },
			legend: {
				show: true,
				horizontalAlign: "center",
				onItemClick: {
					toggleDataSeries: true,
				},
				position: "top",
				markers: {
					width: 40,
					height: 15,
					radius: 0,
					strokeWidth: 3,
					strokeColor: ["#42c7c8", "#0ea1ff", "#9ea1ff"],
				},
			},
		}
	}

  chart1Config: IChartConfig[] = [
    {
      label: "",
      backgroundColor: "#E3F2FD",
      borderColor: "#009cff",
      name : "sales",
      borderWidth: 3,
      fill: true,
      data: []
    }
  ]
  chart2Config: IChartConfig[] = [
    {
      label: "",
      backgroundColor: "transparent",
      borderColor: "#009cff",
      borderWidth: 3,
      name : "sales",
      fill: true,
      data: []
    },
  ]
  chart3Config: IChartConfig[] = [
    {
      label: "",
      backgroundColor: "#E3F2FD",
      borderColor: "#009cff",
      name : "sales",
      borderWidth: 3,
      fill: true,
      data: []
    }
  ]
  chart1Label: string[] = []
  chart2Label: string[] = []
  chart3Label: string[] = []

  $refs!: {
    chart1: ILineChart,
    chart2: ILineChart,
    chart3: ILineChart,
  }
  hasBLFilterChange: boolean = false;

  async created() {
    this.fetchDashboardData(this.currYear);
  }

  async fetchDashboardData(year) {
    var yearValue = year;
    this.hasBLFilterChange = false;
    this.loading = true;
    try {
      var dataChartObj = {
        controller: "Dashboard",
        FunctionName: "Main",
        ReportType: "Orders",
        DataType: '6',
        view: 'CurrentUsers',
        Unit: "both",
        customerId : this.customerid,
        fyear: year,
        selectedView: false,
        businessLines: this.businessLineIds.join(',')
      };

      const resultData = await dashboardRepo.fetchData(dataChartObj);

      try {
        this.accountTagsWO = resultData.accountTagsWO;
        const chart1Data: number[] = [];
        const chart1Label: string[] = [];
        for (const item of resultData.MONTHORDERSTHISYEAR) {
          chart1Label.push(item.MONTHSTRING + '-' + item.SALESYEAR)
          this.chartOptions.xaxis.categories = chart1Label;
          chart1Data.push(item.TOTALPRICE)
        }
        this.chart1Config[0].data = chart1Data
        this.chart1Label = chart1Label;
        const chart2Data: number[] = []
        const chart2Label: string[] = []
        for (const item of resultData.MONTHORDERSLASTYEAR) {
          chart2Label.push(item.MONTHSTRING + '-' + item.SALESYEAR)
          this.barOptions.xaxis.categories = chart2Label;
          chart2Data.push(item.TOTALPRICE)
        }
        this.chart2Config[0].data = chart2Data
        this.chart2Label = chart2Label;

        const chart3Data: number[] = []
        const chart3Label: string[] = []

        for (const item of resultData.MONTHORDERSYTD) {
          chart3Label.push(item.MONTHSTRING + '-' + item.SALESYEAR)
          this.Chartoptions2.xaxis.categories = chart3Label;
          chart3Data.push(item.TOTALPRICE)
        }
        this.chart3Config[0].data = chart3Data
        this.chart3Label = chart3Label;

        if(typeof this.$refs.chart1 != "undefined") {
          this.$refs.chart1.renderLineChart();
        }
        if(typeof this.$refs.chart2 != "undefined") {
          this.$refs.chart2.renderLineChart();
        }
        if(typeof this.$refs.chart3 != "undefined") {
          this.$refs.chart3.renderLineChart();
        }
        this.businessLines = resultData.BUSINESSLINES;
        if (typeof resultData.BUSINESSLINES != "undefined" && this.businessLineIds.length == 0) {
          for (let i = 0; i < resultData.BUSINESSLINES.length; i++) {
            this.businessLineIds.push(resultData.BUSINESSLINES[i].id);
            this.businessLineName.push(resultData.BUSINESSLINES[i].name);
          }
        }
        if (
          !this.selectedView &&
          typeof resultData["userSelectedCurrentView"] != "undefined"
        ) {
          this.selectedView = true;
          this.dashView = resultData["userSelectedCurrentView"];
        }
      } catch (e) {
        //handle error
        console.error("CustomerDashboard error : ", e);
        throw e
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      await this.fetchSourceData();
      this.loading = false;
      }
    }   

  getCurrentViewTitle() {
    if (this.selectedTags.length) {
        return this.selectedTags.map((tmp: any) => tmp.TAGNAME).join(", ");
    }
    return "";
  }

	lastday(y, m) {
		return new Date(y, m, 0).getDate();
	}
	
	saleRedirect(year,monthtitle,index,itemIndex){
		var monthasNum = ["01","02","03","04","05","06","07","08","09","10","11","12"];
		// if(index == 0){
		// 	year = year - 1;
		// }
		var month = monthasNum[itemIndex];
		var sDate = year + "-" + month + "-01";
		var eDate = year + "-" + month + "-" + this.lastday(year, month);
		console.log("month",sDate,eDate)
		var filterView =
			this.gTagIDs.length || this.tagIDs.length ? 1 : this.dashView;
		var routeQuery: any = {
			sDate: sDate,
			eDate: eDate,
			filterView: filterView,
			viewFrom: "dashboard",
			tagIDs: this.tagIDs,
      businessLineId: this.businessLineIds.map(item => String(item)),
			businessLineName: this.businessLineName,
			gTagIDs: this.gTagIDs,
		};
		var currItem = index == 2 ? "Quotes" : "Orders";
		if (currItem == "Orders") {
			routeQuery["sStatus"] = ["0", "1", "2", "3", "4", "5", "10", "13"];
		} else if (currItem == "Quotes") {
			routeQuery["qOpenStatus"] = ["0", "1", "2", "5", "6", "7", "8"];
		}
		routeQuery["selectedAccs"] =this.customerid;

		this.$router.push({
			name: currItem,
			query: routeQuery,
		});

    }

    selectView(item, e) {
        if (isNaN(item.ACCOUNTTAGID)) {
            // selected my customers / company customers
            this.tagIDs = [];
            this.gTagIDs = [];
            this.groupTagIDs = [];
            this.selectedTags = []; // reset
            this.CurrentView = item.TAGNAME;
            this.dashView = item.ACCOUNTTAGID;
            this.selectedView = true;
            this.CurrentHeader = item.TAGNAME;
        } else {
            this.CurrentView = "Grouping"
            e.stopPropagation();
        }
    }

    async filterByTag() {
    // selected a tag/gtag
    // this.tagIDs = [];
    // this.gTagIDs = [];
    // this.groupTagIDs = [];
    this.sourceIDs = [];
    if (this.selectedTags.length) {
        this.selectedView = true;
        this.CurrentView = this.getCurrentViewTitle();
        this.CurrentHeader = this.getCurrentViewTitle();
        this.dashView = "1"; //just set a number
        for (let i in this.selectedTags) {
        let item: any = this.selectedTags[i];
        // if (item.ISSOURCE) {
        this.sourceIDs.push(item.ACCOUNTTAGID);
        // }else {
        // this.tagIDs.push(item.ACCOUNTTAGID);
        // }
    }
    } else {
        // reset
        this.selectedView = false;
        this.CurrentHeader = '';
    }
    await this.fetchSourceData();
  }

  async fetchSourceData() {
    this.loading = true;
    try {
        var dataChartObj = {
        controller: "customers",
        FunctionName: "filter",
        tagIDs: this.tagIDs.join(),
        sourceIDs: this.sourceIDs.join(),
        aID: this.aID,
        businessLines: this.businessLineIds.join(','),
        getSelectedTagsAID: this.customerid
    };

    const resultData = await dashboardRepo.fetchData(dataChartObj);

    try {
      this.grouping = resultData.GROUPS.sort((a, b) => b.PYEAR - a.PYEAR);
    } catch (e) {
        //handle error
        console.error("CustomerDashboard error : ", e);
    }
    } catch (err) {
        notifier.alert(err.message);
    } finally {
        this.loading = false;
    }
  }

  async checkBusinessLineList(bLine: string, name: string) {
    this.resetBLIdArr();
    const index = this.businessLineIds.findIndex(s => s == '-1')
    if (index >= 0) {
      this.businessLineIds = [];
      this.businessLineName = [];
    }
    if (this.businessLineIds != undefined && this.businessLineName != undefined) {
      const i = this.businessLineIds.findIndex(s => s === bLine);
      const bLineIndex = this.businessLineName.findIndex(
        bLineValue => bLineValue === name
      );
      if (i === -1) {
        this.businessLineIds.push(bLine);
      } else {
        this.businessLineIds.splice(i, 1);
      }
      if (bLineIndex === -1) {
        this.businessLineName.push(name);
      } else {
        this.businessLineName.splice(bLineIndex, 1);
      }
    }
    if (!this.businessLineIds.length) {
      this.uncheckBL();
    }
    this.hasBLFilterChange = true;
  }

  handleBLClick(e) {
    e.stopPropagation();
  }

  async uncheckAllBLFilter() {
    this.uncheckBL()
    await this.fetchDashboardData(this.currYear);
  }

  async resetBLFilter() {
    this.resetBLIdArr()
    for (let i = 0; i < this.businessLines.length; i++) {
      const index = this.businessLineIds.findIndex(s => s === this.businessLines[i].id);
      if (index === -1) {
        this.businessLineIds.push(this.businessLines[i].id);
        this.businessLineName.push(this.businessLines[i].name);
      }
    }
    await this.fetchDashboardData(this.currYear);
  }

  resetBLIdArr() {
    const index = this.businessLineIds.findIndex(s => s == '-1')
    if (index >= 0) {
      this.businessLineIds = [];
      this.businessLineName = [];
    }
  }

  uncheckBL() {
    this.businessLineIds = ['-1'];
    this.businessLineName = ['None'];
  }
}
