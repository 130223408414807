import { render, staticRenderFns } from "./SourceAdd.vue?vue&type=template&id=0ed69652"
import script from "./SourceAdd.vue?vue&type=script&lang=tsx"
export * from "./SourceAdd.vue?vue&type=script&lang=tsx"
import style0 from "./SourceAdd.vue?vue&type=style&index=0&id=0ed69652&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports