









































































































































































































































import axios from "axios";
import { UserAPIRepo } from "../repos/UserAPIRepo";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { notifier, printHtml, wait } from "../models/common";
import Pagination from "../components/Pagination.vue";
import Footer from "../components/Footer.vue";
import { ApiHelper } from "@/helpers/all";

declare const $: any;
declare const dataURL: string;
declare const getMaxRows: Function;
const REQUIRED_CHARS = 2

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    Footer
  }
})
export default class Search extends TSXComponent<void> {
  $refs!: {
    footerComponent: Footer;
  };
  currRole = sessionStorage.getItem("userRole");
  $parent: any;
  $allowedFunctions: any;
  $userFunctions: any;
  debounceTime: any = null;
  totalSearchResults = 0;
  searchIn: string = "";
  searchKey: string = "";

  details: any = { TOTALPAGES: 1, TOTAL: 10, CURRENTPAGE: 1 };
  loading = false;
  searching = false
  enableSearch = false
  search = ''
  searchKeyError = "";
  viewType = 'CurrentUsers'
  selectedView = false
  pageNumber = 1;
  searchResults: any[] = []

  searchForDescription = {
    id: 'Var ID',
    name: 'Name',
    skuName: 'SKU Name',
    sku: 'SKU',
    serialNumber: 'Serial Number',
    orderStatus: 'Status'
  };

  selectedSearchIn: string[] = [];

  searchInOptions = [
    {
      text: 'Accounts',
      value: 'accounts',
    },
    {
      text: 'Quotes',
      value: 'quotes',
    },
    {
      text: 'Orders',
      value: 'orders',
    },
    {
      text: 'Invoices',
      value: 'invoices',
    },
    {
      text: 'Deployments',
      value: 'deployments',
    },
    {
      text: 'Hardwares',
      value: 'hardwares',
    }
  ]

  selectedSearchFor: string[] = [];

  searchForOptions = [
    {
      text: 'Var Id',
      value: 'id',
    },
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'SKU Name',
      value: 'skuName',
    },
    {
      text: 'SKU',
      value: 'sku',
    },
    {
      text: 'Serial Number',
      value: 'serialNumber',
    },
    {
      text: 'Status',
      value: 'orderStatus',
    }
  ];

  get onlySearchInOrder() {
    return this.selectedSearchFor.length === 1 && this.selectedSearchFor.includes('orderStatus');
  }

  @Watch('selectedSearchFor', { deep: true })
  onSearchForChange(value: string[], oldValue: string[]) {
    if (oldValue.includes('orderStatus')) {
      if (value.length > 1) {
        this.selectedSearchFor = value.filter((v) => v !== 'orderStatus');
        this.checkAll('searchIn')
      }
    } else {
      if (value.includes('orderStatus') ) {
        this.selectedSearchFor = ['orderStatus'];
        this.selectedSearchIn = ['orders'];
      }
    }

    this.pageNumber = 1;
    this.searchInput();
  }

  @Watch('selectedSearchIn', { deep: true })
  onSearchInChange(value: string[], oldValue: string[]) {
    this.pageNumber = 1;
    this.searchInput();
  }

  created() {
    this.searchIn = this.$route.params.searchIn;
    this.searchKey = this.$route.params.key;

    if(this.$route.query && this.$route.query.viewType) {
      const { viewType } = this.$route.query;
      this.viewType = viewType.toString();
    } else {
      this.viewType = 'All';
    }

    if (this.searchKey) {
      this.search = this.searchKey;
    }

    if (this.$route.query && this.$route.query.searchFor) {
      this.selectedSearchFor = ['orderStatus'];
    } else {
      this.selectedSearchFor = this.searchForOptions.filter((opf) => opf.value !== 'orderStatus').map((op) => op.value);
    }

    const listSearchInOptions = this.searchInOptions.map((op) => op.value);
    if (this.searchIn && listSearchInOptions.includes(this.searchIn)) {
      this.selectedSearchIn = [this.searchIn];
    } else {
      this.selectedSearchIn = listSearchInOptions;
    }
  }

  beforeDestroy() {
    if (this.debounceTime) {
      clearTimeout(this.debounceTime); 
    }
  }

  uncheckAll(type) {
    if (this.loading) {
      return;
    }

    if (type === 'searchIn') {
      this.selectedSearchIn = [];
    } else if (type === 'searchFor') {
      this.selectedSearchFor = [];
    }
  }

  checkAll(type) {
    if (this.loading) {
      return;
    }

    if (type === 'searchIn') {
      this.selectedSearchIn = this.searchInOptions.map((op) => op.value);
    } else if (type === 'searchFor') {
      this.selectedSearchFor = this.searchForOptions.filter((opf) => opf.value !== 'orderStatus').map((op) => op.value);
    }
  }

  async toggleCustomView(viewType){
    if (this.loading) {
      return;
    }

    this.pageNumber = 1;
    this.viewType = viewType;
    this.selectedView = true;
    await this.searchInput();
  }

  debounceSearchInput() {
    if (this.debounceTime) {
      clearTimeout(this.debounceTime); 
    }

    this.debounceTime = setTimeout(async () => {
      this.pageNumber = 1;
      await this.searchInput();
    }, 1000);
  }

  // pagination
  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      await this.searchInput();
    }
  }

  gotoGlobalSearch(params) {
    const routeQuery = {};
    if(this.$allowedFunctions.includes(this.$userFunctions.All_Milestone_Accounts)) {
      routeQuery["viewType"] = params.viewType;
      if (this.onlySearchInOrder) {
        routeQuery["searchFor"] = params.searchFor;
      }
    }

    // this.$router.replace({
    //     path: "/search/" + params.key + "/for/" + params.type,
    //     query: routeQuery
    //   });
    const url = `#/search/${params.key}/for/${params.type}?viewType=${routeQuery["viewType"]}${this.onlySearchInOrder ? `&searchFor=${params.searchFor}` : ''}`;
    window.location.replace(url);
    setTimeout(() => window.location.reload(), 500);
  }

  async searchInput() {
    if (this.loading) {
      return;
    }
    
    var currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "");


    this.loading = true

    if(!this.selectedView && typeof this.$parent.viewType != "undefined") {
      // get viewType of parent page in case user didn't select a view type yet
      try {
        const parentViewType = (typeof this.$parent.viewType != "undefined" ? this.$parent.viewType : "");
        if(["All", "CurrentUsers"].includes(parentViewType)) {
          this.viewType = parentViewType;
        }else if(this.$parent.$route.name == "Spiffs") {
          if(parentViewType == "all_spiffs") {
            this.viewType = "All";
          }else if(parentViewType == "my_spiffs") {
            this.viewType = "CurrentUsers";
          }
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    }

    try {
      if (!this.search || this.selectedSearchIn.length === 0 || this.selectedSearchFor.length === 0) {
        this.searchResults = [];
        this.details.TOTALPAGES = 1;
        this.details.CURRENTPAGE = 1;
        this.totalSearchResults = 0;
        return;
      }

      const reqBody = {
        session: sessionStorage.getItem("sessionID"),
        userRole: sessionStorage.getItem("userRole"),
        Controller: "Search",
        FunctionName: "Var360Search",
        search: this.search,
        selectedSearchIn: this.selectedSearchIn,
        selectedSearchFor: this.selectedSearchFor,
        view: this.viewType,
        pageNumber: this.pageNumber,
        selectedView: this.selectedView,
        aID: currentUser[0]["ACCOUNTID"],
        fromSearchPage: 1
      };

      const response = await axios.post(dataURL + "?ReturnType=JSON", reqBody);

      if (typeof response.data.ERROR != "undefined") {
        throw new Error(response.data.ERROR);
      }
      if (
        typeof response.data.STATUS != "undefined" &&
        response.data.STATUS !== 1
      ) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (typeof response.data.SEARCHRETURN != "undefined") {
        this.searchResults = response.data.SEARCHRETURN || [];
        this.details.TOTALPAGES = response.data.TOTALPAGES || 1;
        this.details.CURRENTPAGE = response.data.CURRENTPAGE || 1;
        this.totalSearchResults = response.data.TOTAL || 0;
      }


    } finally {
      setTimeout(() => {
          this.loading = false
      }, 300);
    }
  }

}
