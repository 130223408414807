


















































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { validateFn } from "../../../helpers";
import directives from "../../../helpers/directives";
import { notifier } from "../../../models/common";
// import CustomerCustomFieldsModal from "../../../components/CustomerCustomFieldsModal.vue";
import EmployeeMultiselect from "../../../components/EmployeeMultiselect.vue";
import SecurityMultiSelect from "../../../components/SecurityMultiSelect.vue";
import CustomerDashboard from "../../../components/CustomerDashboard.vue";
import LaddaButton from "../../../components/LaddaButton.vue";
import { ErrorBag } from "vee-validate";
import axios from "axios";
import AccountTagSelect from "../../../components/AccountTagSelect.vue";
import subAccountsSelect from "../../../components/subAccountmultiselect.vue";
// import AcctsCommUpdateModal from "../../../components/AcctsCommUpdateModal.vue";
import PhoneInput from "../../../components/PhoneInput.vue";
import customerContacts from "../../../components/CustomerDetailsTabContacts.vue";
import CustomerDetailsTabSettings from "../../../components/CustomerDetailsTabSettings.vue";
import CustomerDetailsTabAddresses from "../../../components/CustomerDetailsTabAddresses.vue";
import PageTitle from "@/components/pageTitle.vue";
import CustomerProfitTargetModal from "@/components/CustomerProfitTargetModal.vue";

interface addrObj {
  ADDRTYPE: number;
  ISDEFAULT: number;
  ID: number;
  NAME: string;
  ADDR1: string;
  ADDR2: string;
  CITY: string;
  STATE: string;
  ZIP: string;
  ACCOUNTSADDRESS_ID: string;
  SELECTEDNETTERM?: number;
}

declare const $: any;
declare const dataURL: string;
declare const getRouteData: Function;
declare const htmlCheck: Function;
declare const htmlParse: Function;

@Component({
  inheritAttrs: false,
  components: {
    PageTitle,
    // CustomerCustomFieldsModal,
    EmployeeMultiselect,
    SecurityMultiSelect,
    CustomerDashboard,
    LaddaButton,
    AccountTagSelect,
    // AcctsCommUpdateModal,
    subAccountsSelect,
    PhoneInput,
    customerContacts,
    CustomerDetailsTabSettings,
    CustomerDetailsTabAddresses,
    CustomerProfitTargetModal
  },
  directives
})
export default class customerDetails extends TSXComponent<void> {
  // declare errors: any;
  loading = false;
  isActive = "customerDashboard";
  // customFieldobj: {
  //   class?: string;
  //   id?: string;
  //   label?: string;
  //   placeholder?: string;
  //   type?: string;
  //   value?: string;
  //   maxlength?: number;
  //   required?: number;
  // }[] = [];
  // customFields = this.customFieldobj;
  customDataVisible = false;
  fullCustomData: object[] = [];
  customDataLoading = false;
  customDataIndex = -1;
  aID = "";
  titleAccName = "";
  currentRole = sessionStorage.getItem("userRole");
  userID = 0;
  commAvail = 0;
  remainingComm = 99;
  errorMsg = {
    forAccountID: "",
    forPercentage: ""
  };
  // customFieldsModalVisible = false;
  customerRemainingPer = "";
  userName = "";
  selectedUserIds: (number | undefined)[] = [];
  selectedSecurityIds: (number | undefined)[] = [];
  userAvailable = false;
  domain = sessionStorage.getItem("domain");
  saving: any = false;
  externalKey = "";
  commissionAdding = false;
  addingCustomer: any = false;
  addressItems = [
    {
      NAME: "kali",
      ADDR1: "west block",
      ADDR2: "KFC street",
      CITY: "moroco",
      STATE: "Tokyo",
      ZIP: "",
      ID: 0,
      ISDEFAULT: 0,
      ADDRTYPE: 1,
      ACCOUNTSADDRESS_ID: ""
    }
  ];
  billingAddressItems:addrObj[] = [];
  shippingAddressItems:addrObj[] = [];

  contactsItems = [
    {
      NAME: "kali",
      MAIL: "KALI@MILESTONETECH.COM",
      PHONE: "",
      ID: 0,
      ISDUPLICATE: 0,
      ACCOUNTCONTACTID: ""
    }
  ];
  departmentItems = [
    {
      NAME: "",
      ID: 0,
      ISDUPLICATE: 0
    }
  ];
  domainsList = [];
  submitFrom = 1;
  userType = 1; //use for add commission %
  options = [];
  items = {
    ALLACCOUNTS: []
  };
  usersObj: {
    USERID: number;
    UFULLNAME?: string;
  }[] = [];
  securityObj: {
    SECURITYGROUPID: number;
    SECURITYGROUPNAME?: string;
  }[] = [];
  custLoginObj: {
    ACCOUNTNAME?: string;
    UEMAIL?: string;
    UFNAME?: string;
    DOMAIN?: string;
    DOMAINNAME?: string;
  }[] = [];
  details = {
    CUSTOMERLOGINSTATUS: 0,
    CUSTOMERLOGINDETAILS: this.custLoginObj,
    SELECTEDGROUPS: this.securityObj,
    SELECTEDUSERS: this.usersObj,
    SELECTEDUSERSTOTAL: 0,
    ALLSECURITYGROUPS: this.securityObj,
    ALLUSERS: this.usersObj,
    PORTALUSERS: []
  };
  templateObj: {
    ACCOUNTID?: number;
    TEMPLATEID?: number;
    TEMPLATELOCATION?: string;
    TEMPLATENAME?: string;
    TEMPLATETYPE?: number;
  }[] = [];
  custLoginDetails: any = this.custLoginObj;
  custLoginStatus = 0;
  subaccounts = [];
  customerAccounts = [];
  templateResult = [];
  invoice_template = this.templateObj;
  order_template = this.templateObj;
  quote_template = this.templateObj;
  quote_templateSelected = 0;
  order_templateSelected = 0;
  invoice_templateSelected = 0;
  invoiceTemplates: number[] = [];
  orderTemplates: number[] = [];
  quoteTemplates: number[] = [];
  quoteTemplateSelected = false;
  orderTemplateSelected = false;
  invoiceTemplateSelected = false;
  custSubaccounts: object[] = [];
  selectedSubAccsID: number[] = [];
  accountTags: object[] = [];
  selectedTags: object[] = [];
  selectedTagsID: number[] = [];
  selectedTagsIDComm: number[] = [];
  selectedTagsComm: object[] = [];
  selectedSecurityIdsComm: number[] = [];
  selectedSGComm: object[] = [];
  allSGComm: object[] = [];
  currAID = 0;
  currACCID = "";
  currAccName = "";
  currAccAlias = "";
  settingItems: {
    SHIPMENTNOTES: string;
    QUOTENOTES: string;
    ENDMESSAGE: string;
    PURCHASINGINSTRUCTION: string;
    hpDirectTaxRate: string;
    indirectTaxRate: string;
    hardwareTable: string;
    shippingAutoTax: any;
    billingAutoTax: any;
    priceTranslation: any;
  } = {
    SHIPMENTNOTES: "",
    QUOTENOTES: "",
    ENDMESSAGE: "",
    PURCHASINGINSTRUCTION: "",
    hpDirectTaxRate: "",
    indirectTaxRate: "",
    hardwareTable: "",
    shippingAutoTax: 0,
    billingAutoTax: 0,
    priceTranslation: 0
  };
  commissionUsersList: object[] = [];
  createdPortal = false;
  fromPortals = 0;
  noMultiReseller = 1;
  // fromPages = 0;
  grouping: object[] = [];
  businessLineOptions: any = [];
  remainingBusinessLineComm: any = [];
  businessLines = false;
  businessLineId = 0;
  selectedChangeInfoIds: string[] = [];
  hardwareTables = [];
  cy_target: any = {};
  profit_targets: any = [];
  showProfitTarget = false;
  allNetTerms = [];
  targetBusinessLineOptions: any = [];

  async created() {
    this.initialLoad(-1);
  }

  mounted() {
    if (window.location.href.indexOf("?tab=") > -1) {
      var activeTab = window.location.href.split("?tab=");
      if (activeTab.length > 1) {
        this.isActive = activeTab[1];
        if (activeTab[1] == "commission") {
          this.isActive = "settingDet";
        }
      }
    }
    if (window.location.href.indexOf("?portals=") > -1) {
      this.fromPortals = 1;
    }
    // if (window.location.href.indexOf("?page=") > -1) {
    //   this.fromPages = 1;
    // }
  }
  @Prop({ required: false, default: true })
  ispage!: boolean;

  @Prop({ required: false, default: "" })
  commissionaccountid!: string;

  @Prop({ required: false, default: "" })
  commissionfromTab!: string;

  @Prop({ required: false, default: 0 })
  totalAll!: number;

  validateFn;

  get currentCustomID(): string {
    let url: string = "";
    if (
      $(location)
        .attr("href")
        .indexOf("?tab=") > -1
    ) {
      const urls = $(location)
        .attr("href")
        .split("?tab=");
      url = urls[0].split("/");
    } else if (
      $(location)
        .attr("href")
        .indexOf("?portals=") > -1
    ) {
      const urls = $(location)
        .attr("href")
        .split("?portals=");
      url = urls[0].split("/");
    } 
    // else if (
    //   $(location)
    //     .attr("href")
    //     .indexOf("?page=") > -1
    // ) {
    //   const urls = $(location)
    //     .attr("href")
    //     .split("?page=");
    //   url = urls[0].split("/");
    // } 
    else {
      url = $(location)
        .attr("href")
        .split("/");
    }

    const currentCustomID = url[url.length - 1];
    if (this.commissionaccountid != "") {
      return this.commissionaccountid;
    }
    return currentCustomID;
  }

  async fetchTabDetails() {
    const response = await axios.post(
      dataURL + "?ReturnType=JSON&tab=details",
      {
        controller: "Customers",
        FunctionName: "View",
        aID: this.currentCustomID
      }
    );

    this.items = response.data.CUSTOMERS;
  }

  async fetchTabSecurity() {
    const response = await axios.post(
      dataURL + "?ReturnType=JSON&tab=security",
      {
        controller: "Customers",
        FunctionName: "View",
        aID: this.currentCustomID
      }
    );

    Object.assign(this.details, response.data);
  }

  async fetchTabContacts() {
    const response = await axios.post(
      dataURL + "?ReturnType=JSON&tab=contacts",
      {
        controller: "Customers",
        FunctionName: "View",
        aID: this.currentCustomID
      }
    );

    this.contactsItems = response.data.CUSTOMERS[0].CUSTOMERCONTACTS;
  }

  async initialLoad(fromTab = 0, isAddres = -1, callback) {
    // var self = this;
    this.loading = true;
    if (this.commissionfromTab != "") {
      this.isActive = this.commissionfromTab;
    }

    var dataObj = {};
    dataObj["controller"] = "Customers";
    dataObj["FunctionName"] = "View";
    dataObj["aID"] = this.currentCustomID;
    dataObj['securityGroupType'] = 'Account Group';
    // var customerResult = getRouteData(dataObj);
    // await customerResult.then((response, statusText) => {
    const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    var thisCustomer = response.data.CUSTOMERS[0];
    if (fromTab == 8 || fromTab == -1) {
      if (response.data.SELECTEDUSERS.length > 0) {
        response.data.SELECTEDUSERS.forEach(element => {
          if (this.selectedUserIds.indexOf(element.USERID) < 0) {
            this.selectedUserIds.push(element.USERID);
          }
        });
      }
      if (response.data.SELECTEDGROUPS.length > 0) {
        response.data.SELECTEDGROUPS.forEach(element => {
          if (this.selectedSecurityIds.indexOf(element.SECURITYGROUPID) < 0) {
            this.selectedSecurityIds.push(element.SECURITYGROUPID);
          }
        });
      }
    }
    var tmpParams: any = {};
    if (
      typeof response.data.CUSTOMERS[0].ACCOUNTPARAMS != "undefined" &&
      response.data.CUSTOMERS[0].ACCOUNTPARAMS.length
    ) {
      tmpParams = JSON.parse(response.data.CUSTOMERS[0].ACCOUNTPARAMS);
      if (typeof tmpParams["ExternalKey"] != "undefined") {
        this.externalKey = tmpParams.ExternalKey;
      }
    }

    this.grouping = response.data.GROUPS.sort((a, b) => b.PYEAR - a.PYEAR);
    
    if (fromTab == 2 || fromTab == -1) {
      this.items = response.data.CUSTOMERS; //
    }
    this.items = response.data.CUSTOMERS; //
    this.subaccounts = response.data.SUBACCOUNTS;
    this.customerAccounts = response.data.CUSTOMERACCOUNTS;
    this.details = response.data; //
    if (fromTab == -1 || fromTab == 7) {
      this.remainingComm = response.data.CUSTOMERS[0].REMAININGCOMM;
      this.remainingBusinessLineComm = response.data.CUSTOMERS[0].REMAININGBUSINESSLINECOMM;
      this.commAvail = response.data.CUSTOMERS[0].REMAININGCOMM;
      this.commissionUsersList = response.data.CUSTOMERS[0].COMMISIONUSERS;
      this.businessLines = response.data.CUSTOMERS[0].COMMISIONBLINES;
      this.setBusinessLines();
    }
    this.allNetTerms = response.data.ALLNETTERMS;
    if (fromTab == 6 || fromTab == -1) {
      this.settingItems = {
        SHIPMENTNOTES: response.data.CUSTOMERS[0].SHIPMENTNOTES,
        QUOTENOTES: response.data.CUSTOMERS[0].QUOTENOTES,
        ENDMESSAGE: response.data.CUSTOMERS[0].ENDMESSAGE,
        PURCHASINGINSTRUCTION: response.data.CUSTOMERS[0].PURCHASINGINSTRUCTION,
        hpDirectTaxRate: thisCustomer.HPDIRECTTAXRATE,
        indirectTaxRate: thisCustomer.INDIRECTTAXRATE,
        hardwareTable: thisCustomer.HARDWARETABLE || "",
        shippingAutoTax: parseInt(thisCustomer.SHIPPINGAUTOTAX) === 1 ? true : false || false,
        billingAutoTax: parseInt(thisCustomer.BILLINGAUTOTAX) === 1 ? true : false || false,
        priceTranslation: parseInt(thisCustomer.PRICETRANSLATION) === 1 ? true : false || false
      };
    }
    if (fromTab == 3 || fromTab == -1) {
      this.contactsItems = response.data.CUSTOMERS[0].CUSTOMERCONTACTS; //
      this.departmentItems = response.data.CUSTOMERS[0].CUSTOMERDEPARTMENTS;
      this.addRow(this.contactsItems.length, 0);
      this.addDeptRow(this.departmentItems.length);
    }
    if (fromTab == 4 || fromTab == -1) {
      this.addressItems = response.data.CUSTOMERS[0].CUSTOMERADDRESSES;
      if (isAddres == 1 || isAddres == -1) {
        this.shippingAddressItems =
          response.data.CUSTOMERS[0].SHIPPINGADDRESSES;
      }
      if (isAddres == 0 || isAddres == -1) {
        this.billingAddressItems =
          response.data.CUSTOMERS[0].BILLINGADDRESSES;
      }
      this.addRow(this.addressItems.length, 1, isAddres);
    }
    this.loading = false;

    if (fromTab == 5 || fromTab == -1) {
      this.domainsList = response.data.DOMAINS;
      this.custLoginDetails = response.data.CUSTOMERLOGINDETAILS[0];
      // more data for tab advanced
      this.custLoginDetails.SMARTSTOREAPIKEY = response.data.CUSTOMERS[0].SMARTSTOREAPIKEY || "";
      this.custLoginDetails.APIKEY = response.data.CUSTOMERS[0].APIKEY || "";
      this.custLoginDetails.PRICETYPE = response.data.CUSTOMERS[0].PRICETYPE || 0;
      this.custLoginDetails.GENERATEHARDWARE = response.data.CUSTOMERS[0].GENERATEHARDWARE || 0;

      if (typeof response.data.CUSTOMERLOGINDETAILS[0].NOMULTIRESELLER != "undefined") {
        this.noMultiReseller = parseInt(response.data.CUSTOMERLOGINDETAILS[0].NOMULTIRESELLER)
      }
      this.custLoginStatus = response.data.CUSTOMERLOGINSTATUS;
    }
    // this.customFields = [];
    this.titleAccName = response.data.CUSTOMERS[0].ANAME || "";
    this.currAID = response.data.CUSTOMERS[0].AID;
    this.currACCID = response.data.CUSTOMERS[0].ACCOUNTID;
    this.currAccName = response.data.CUSTOMERS[0].ANAME;
    this.currAccAlias = response.data.CUSTOMERS[0].ACCOUNTALIAS;
    if (fromTab == 2 || fromTab == -1) {
      if (
        typeof response.data.CUSTOMERS[0].ACCOUNTPARAMS != "undefined" &&
        response.data.CUSTOMERS[0].ACCOUNTPARAMS != ""
      ) {
        tmpParams = JSON.parse(response.data.CUSTOMERS[0].ACCOUNTPARAMS);
        // if (typeof tmpParams["customFields"] != "undefined") {
        //   this.customFields = tmpParams["customFields"];
        // }
      }
      this.order_templateSelected = response.data.OrderTemplateId;
      this.invoice_templateSelected = response.data.InvoiceTemplateId;

      this.quote_template = [];
      this.order_template = [];
      this.invoice_template = [];
      var templateResult = response.data.TEMPLATES;
      for (let i = 0; i < templateResult.length; i++) {
        if (templateResult[i].TEMPLATETYPE == 1) {
          //quote
          templateResult[i].selected = false;
          if (
            templateResult[i].TEMPLATEID ==
            response.data.AccountQuoteTemplateId
          ) {
            templateResult[i].selected = true;
            this.quote_templateSelected = templateResult[i].TEMPLATEID;
          }
          this.quote_template.push(templateResult[i]);
          this.quoteTemplates.push(templateResult[i].TEMPLATEID);
        } else if (templateResult[i].TEMPLATETYPE == 3) {
          //order
          templateResult[i].selected = false;
          if (
            templateResult[i].TEMPLATEID ==
            response.data.AccountOrderTemplateId
          ) {
            templateResult[i].selected = true;
            this.order_templateSelected = templateResult[i].TEMPLATEID;
          }
          this.order_template.push(templateResult[i]);
          this.orderTemplates.push(templateResult[i].TEMPLATEID);
        } else if (templateResult[i].TEMPLATETYPE == 2) {
          //invoice
          templateResult[i].selected = false;
          if (
            templateResult[i].TEMPLATEID ==
            response.data.AccountInvoiceTemplateId
          ) {
            templateResult[i].selected = true;
            this.invoice_templateSelected = templateResult[i].TEMPLATEID;
          }
          this.invoice_template.push(templateResult[i]);
          this.invoiceTemplates.push(templateResult[i].TEMPLATEID);
        }
      }

      this.accountTags = response.data.ACCOUNTTAGS;
      this.custSubaccounts = response.data.CUSTOMERSUBACCARR;
      this.selectedSubAccsID = response.data.SELECTEDSUBACCARR;
      this.selectedTags = response.data.SELECTEDTAGS;
      this.selectedTagsID = this.selectedTags.map(
        (val: any) => val.ACCOUNTTAGID
      );
      this.allSGComm = this.details.ALLSECURITYGROUPS.filter(
        (val: any) => val.SYSTEM_GROUP != 1
      );
    }

    this.createdPortal =
      response.data.CUSTOMERLOGINSTATUS === 1 ? true : false;
    this.selectedChangeInfoIds = thisCustomer.SELECTEDCHANGEINFOIDS ? thisCustomer.SELECTEDCHANGEINFOIDS.split(",") : [];
    this.hardwareTables = response.data.hardwareTables || [];
    this.profit_targets = response.data.PROFIT_TARGETS;
    this.cy_target = response.data.CY_TARGET;
    // });

    if(callback) {
      callback();
    }
  }

  hideCustomEditBox() {
    this.customDataIndex = -1;
  }
  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  updateDefault(index) {
    $.each(this.addressItems, function(i, val) {
      if (i == index) {
        val.ISDEFAULT = 1;
      } else {
        val.ISDEFAULT = 0;
      }
    });
  }
  updateDefaultContact(index) {
    $.each(this.contactsItems, function(i, val) {
      if (i == index) {
        val.ISDEFAULT = 1;
      } else {
        val.ISDEFAULT = 0;
      }
    });
  }

  /* moved to child component CustomerDetailsTabCommission
  async addCommission(commAvail) {
    var errorTab = 0;
    var result = true;
    if ($("#accountName input[type=text]").val() == "") {
      $(".errorMsg.userName").text("The User Name / Var  field is required");
      result = false;
      errorTab = 2;
    } else {
      $(".errorMsg.userName").text("");
    }

    if ($("#commissionPercentage").val() != "") {
      if (isNaN(this.remainingComm)) {
        $(".errorMsg.commPercent").text("Please Enter Valid numeric format");
        result = false;
        errorTab = 2;
      } else {
        $(".errorMsg.commPercent").text("");
      }
    }

    if (
      $("#commissionPercentage").val() > 100 ||
      $("#commissionPercentage").val() <= 0
    ) {
      result = false;
      errorTab = 2;
    }

    if ($("#commissionPercentage").val() > commAvail) {
      $(".errorMsg.commPercent").text(
        "The Commission Percentage field must be " + commAvail + " or less"
      );
      result = false;
      errorTab = 2;
    }

    var existedLinkID: any = [];
    var val: any;
    var realCommAvail = 0;
    if (this.items[0].COMMISIONUSERS.length) {
      //check if existed in list
      for (val of this.items[0].COMMISIONUSERS) {
        if (
          val.USERID == this.userID &&
          val.TYPE == this.userType &&
          typeof val.LINKPAYMENTID != "undefined"
        ) {
          existedLinkID.push(val.LINKPAYMENTID);
        }
      }
    }

    if (result) {
      var dataObj = {
        controller: "Users",
        FunctionName: "AccountCommission",
        userID: this.userID,
        aID: this.aID,
        percentage: this.remainingComm,
        type: this.userType,
        linkPaymentId: existedLinkID.length ? existedLinkID[0] : 0
      };
      var self = this;

      this.commissionAdding = true;
      var resultRes = getRouteData(dataObj);
      await resultRes.then(function(response, statusText) {
        try {
          if (response.data.STATUS) {
            //clear selected user before
            self.userID = 0;
            self.userName = "";

            var dataObj = {};
            dataObj["controller"] = "Customers";
            dataObj["FunctionName"] = "View";
            dataObj["aID"] = self.currentCustomID;
            dataObj["userID"] = self.aID;
            dataObj["maxRows"] =
              sessionStorage.getItem("maxRows") != ""
                ? sessionStorage.getItem("maxRows")
                : getMaxRows();
            self.loading = true;
            var customResult = getRouteData(dataObj);
            customResult.then(function(response, statusText) {
              self.items[0].COMMISIONUSERS =
                response.data.CUSTOMERS[0].COMMISIONUSERS;
              self.remainingComm = response.data.CUSTOMERS[0].REMAININGCOMM;
              self.commAvail = response.data.CUSTOMERS[0].REMAININGCOMM;
              self.loading = false;
            });
            $(".IZ-select__input input").val("");
          } else {
            notifier.alert(response.data.MESSAGE);
          }
        } catch (e) {
          //handle error
          console.log("In commission create customer user ", e);
        }
      });
      this.commissionAdding = false;
    } else {
      if (errorTab) {
        var tabErr = ["customerDetail", "commission"];
        this.toggleActive(tabErr[errorTab - 1]);
      }
    }
  } */

  /*addCustomer() {
    // var result=true;
    this.submitFrom = 5;
    $("#advancedDet input.initial.error").removeClass("initial");
    this.$validator.validateAll().then(async result => {
      if (this.$validator.errors.has(`emailAddress`)) {
        result = false;
      } else {
        result = true;
      }

      if ($("#CompanyName").val() == "") {
        $(".errorMsg.CompanyName").text(
          "The Customer Company Name field is required"
        );
        result = false;
      } else {
        $(".errorMsg.CompanyName").text("");
      }
      if ($("#subDomain").val() == "") {
        $(".errorMsg.subDomain").text("The site Name field is required");
        result = false;
      } else {
        $(".errorMsg.subDomain").text("");
      }
      if ($("#emailAddress").val() == "") {
        $(".errorMsg.emailAddress").text("The email Address field is required");
        result = false;
      } else {
        $(".errorMsg.emailAddress").text("");
      }
      if ($("#contactName").val() == "") {
        $(".errorMsg.contactName").text("The contact Name field is required");
        result = false;
      } else {
        $(".errorMsg.contactName").text("");
      }
      if ($("#emailAddress").val() != "") {
        if (
          !$("#emailAddress")
            .val()
            .match("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}")
        ) {
          $(".errorMsg.emailAddress").text(
            "The email field must be a valid email"
          );
          result = false;
        }
      }
      var self = this;
      if (result) {
        this.addingCustomer = true;
        var dataObj = {
          controller: "CustomerPortals",
          accName: $("#CompanyName").val(),
          emailAddress: $("#emailAddress").val(),
          domain: $("#subDomain").val() + "." + $("#domain").val(),
          cName: $("#contactName").val(),
          address: $("#aAddress").val(),
          city: $("#aCity").val(),
          state: $("#aState").val(),
          zip: $("#aZip").val(),
          externalKey: $("#externalKey").val(),
          aId: this.items[0].ACCOUNTID
        };
        if (
          this.details.CUSTOMERLOGINSTATUS === 1 ||
          this.details.CUSTOMERLOGINSTATUS === 3
        ) {
          dataObj["FunctionName"] = "updateCustomer";
        } else {
          dataObj["FunctionName"] = "CreatePortal";
        }
        getRouteData(dataObj).then(function(response, statusText, jqXHR) {
          self.addingCustomer = false;
          if (response.data.STATUS == 1) {
            notifier.success(response.data.STATUSMSG);

            self.details.CUSTOMERLOGINDETAILS = [];
            self.details.CUSTOMERLOGINSTATUS = response.data.STATUS;
            self.details.CUSTOMERLOGINDETAILS.push({
              ACCOUNTNAME: response.data.ACCNAME,
              UEMAIL: response.data.EMAIL,
              UFNAME: response.data.CNAME,
              DOMAIN: response.data.DOMAIN,
              DOMAINNAME: response.data.DOMAINNAME
            });
          } else {
            notifier.alert(response.data.STATUSMSG);
            self.addingCustomer = "error";
            self.details.CUSTOMERLOGINSTATUS = response.data.STATUS;
            self.details.CUSTOMERLOGINDETAILS.push({
              ACCOUNTNAME: response.data.ACCNAME,
              UEMAIL: response.data.EMAIL,
              UFNAME: response.data.CNAME,
              DOMAIN: response.data.DOMAIN,
              DOMAINNAME: response.data.DOMAINNAME
            });
          }
        });
      }
    });
  }*/

  allAccounts(e) {
    if (this.options.length) {
      e.preventDefault();
      this.options = this.items.ALLACCOUNTS;
      $(".js-example-tags.form-control").click();
    }
  }
  onChange() {
    var included = 0;
    const acctList = this.details.SELECTEDUSERS;
    for (var i = 0; i < acctList.length; i++) {
      if (
        acctList[i].USERID ==
        $(".addAccount option:selected span").attr("data-id")
      ) {
        included = 1;
      }
    }

    if (included != 1) {
      this.details.SELECTEDUSERS.push({
        USERID: $(".addAccount option:selected span").attr("data-user"),
        UFULLNAME: $(".addAccount option:selected span").attr("data-name")
      });
      this.details.SELECTEDUSERSTOTAL = this.details.SELECTEDUSERS.length;
    }

    // $(".addAccount option:selected").remove();
    // $(".addAccount option:first-child").prop('selected');
    const numb = $(".addAccount option:selected span").attr("data-id");
    $(".addAccount option[val=" + numb + "]").attr("disabled", true);
    $(".addAccount option[val=" + numb + "]").attr("class", "highlight");
    $(".addAccount option:first-child").prop("selected", true);
  }

  toggleActive(div) {
    this.isActive = div;
  }
  customerIcon() {
    if (this.fromPortals) {
      this.$router.push({ name: "CustomerPortals" });
    } else {
      // this.$router.push({ name: "Customers" });
      //To go previous page
      // window.history.back();
      this.$router.push({
        name: "Customers",
        query: { page :this.$route.query.page}
      });
    }
  }
  // addCustomField(theField) {
  //   //init
  //   if (typeof this.customFields == "undefined") {
  //     this.customFields = [];
  //   }

  //   //just add custom field if field ID is not exist before
  //   if (!$("#" + theField.id).length) {
  //     this.customFields.push(theField);
  //   }
  //   if ($(".customField").length >= 2) {
  //     $("#page-details.detailCommission").css("height", "auto");
  //   }
  // }
  // removeCustomField(fieldID) {
  //   //get custom field
  //   // var theField:any = {};
  //   // var fieldIndex: any = 0;
  //   // for(var i in this.customFields) {
  //   // 	console.log('i',i,typeof(i),this.customFields)
  //   // 		if(this.customFields[i].id == fieldID) {
  //   // 				theField = this.customFields[i];
  //   // 				fieldIndex = i;
  //   // 		}
  //   // }
  //   const fieldIndex = this.customFields.findIndex(
  //     field => field.id === fieldID
  //   );
  //   const theField = this.customFields[fieldIndex];
  //   if (Object.keys(theField).length) {
  //     if (
  //       confirm(
  //         "This custom field (" +
  //           theField.label +
  //           ") will be deleted. Are you sure?"
  //       )
  //     ) {
  //       this.$delete(this.customFields, fieldIndex);
  //       this.$forceUpdate();
  //     }
  //   }
  //   if ($(".customField").length <= 3) {
  //     $("#page-details.detailCommission").removeAttr("style");
  //   }
  //   if ($(".customField").length > 4) {
  //     window.scrollTo(0, 10);
  //   }
  // }

  /* moved to child component CustomerDetailsTabCommission
  async updateCommission(userItem) {
    var canAdd = 1;
    if (
      isNaN(userItem.PERCENTAGE) ||
      userItem.PERCENTAGE == "" ||
      userItem.PERCENTAGE <= 0
    ) {
      notifier.alert("Please enter valid PERCENTAGE");
      canAdd = 0;
    } else {
      var totalCommission = 0;
      for (
        var index = 0;
        index < this.items[0].COMMISIONUSERS.length;
        index++
      ) {
        totalCommission += parseFloat(
          this.items[0].COMMISIONUSERS[index].PERCENTAGE
        );
      }
      if (totalCommission > 100) {
        notifier.alert("Cannot save as total commission exceeds 100%");
        canAdd = 0;
      }
    }
    if (canAdd) {
      var dataObj = {
        controller: "Users",
        FunctionName: "AccountCommission",
        userID: userItem.USERID,
        aID: this.items[0].AID,
        percentage: userItem.PERCENTAGE,
        linkPaymentId: userItem.LINKPAYMENTID,
        type: userItem.TYPE
      };
      var self = this;
      self.loading = true;
      var result = getRouteData(dataObj);
      await result.then(function(response, statusText) {
        var msg = response.data.MESSAGE;
        try {
          if (response.data.STATUS) {
            var dataObj = {};
            dataObj["controller"] = "Customers";
            dataObj["FunctionName"] = "View";
            dataObj["aID"] = self.currentCustomID;
            dataObj["userID"] = self.aID;
            dataObj["maxRows"] =
              sessionStorage.getItem("maxRows") != ""
                ? sessionStorage.getItem("maxRows")
                : getMaxRows();
            self.loading = true;
            var customResult = getRouteData(dataObj);
            customResult.then(function(response, statusText) {
              self.items[0].COMMISIONUSERS =
                response.data.CUSTOMERS[0].COMMISIONUSERS;
              self.remainingComm = response.data.CUSTOMERS[0].REMAININGCOMM;
              self.commAvail = response.data.CUSTOMERS[0].REMAININGCOMM;
              self.loading = false;
              notifier.success(msg);
            });
            $(".IZ-select__input input").val("");
          } else {
            notifier.alert(response.data.MESSAGE);
            self.loading = false;
          }
        } catch (e) {
          //handle error
          console.log("In commission create customer user ", e);
          self.loading = false;
        }
      });
    } else {
      this.initialLoad();
    }
  } */

  isInactive() {
    if (this.items[0] && this.items[0].AACTIVE == 2) {
      return true;
    }
    return false;
  }
  removeSpaceId() {
    var regex = /[^a-zA-Z0-9]/gi;
    if (this.items[0].ACCOUNTID != "") {
      this.items[0].ACCOUNTID = this.items[0].ACCOUNTID.replace(regex, "");
    }
  }
  addRow(index, from, isAddress = -1) {
    if (from) {
      if (index == this.addressItems.length) {
        if (isAddress == 0 || isAddress == -1) {
          var item = {
            NAME: "",
            ADDR1: "",
            ADDR2: "",
            CITY: "",
            STATE: "",
            ZIP: "",
            ID: 0,
            ADDRTYPE: 2,
            ISDEFAULT: 0,
            ACCOUNTSADDRESS_ID: "",
            SELECTEDNETTERM: 30
          };
          // this.$set(this.addressItems, index+1, item);
          this.billingAddressItems.push(item);
        }
        if (isAddress == 1 || isAddress == -1) {
          item = {
            NAME: "",
            ADDR1: "",
            ADDR2: "",
            CITY: "",
            STATE: "",
            ZIP: "",
            ID: 0,
            ADDRTYPE: 1,
            ISDEFAULT: 0,
            ACCOUNTSADDRESS_ID: "",
            SELECTEDNETTERM: 0
          };
          this.shippingAddressItems.push(item);
        }
      }
    } else {
      if (index == this.contactsItems.length) {
        var contact = {
          NAME: "",
          MAIL: "",
          PHONE: "",
          ID: 0,
          ISDUPLICATE: 0,
          ISDEFAULT: 0,
          ACCOUNTCONTACTID: "",
          ACONTACTPHONEEXT: "US +1"
        };
        // this.$set(this.contactsItems, index+1, item);
        this.contactsItems.push(contact);
      }
    }
  }
  addDeptRow(index) {
    if (index == this.departmentItems.length) {
      var department = {
        NAME: "",
        ID: 0,
        ISDUPLICATE: 0
      };
      this.departmentItems.push(department);
    }
  }
  async removeItem(index, from) {
    var reqBody = {
      FunctionName: "Delete"
    };
    var isDelete = 0;

    $(".EmailCheck").text("");
    if (this.contactsItems[index].ID) {
      reqBody["Controller"] = "Contacts";
      reqBody["accountcontactID"] = this.contactsItems[index].ACCOUNTCONTACTID;
      isDelete = 1;
    }
    this.contactsItems.splice(index, 1);
    if (isDelete) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", reqBody);

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.MESSAGE);
      }
      if (response.data.STATUS == 1) {
        notifier.success(response.data.MESSAGE);
      }
    }
  }
  sortItems(from) {
    // if (from) {
    //   console.log(this.addressItems);
    // } else {
    //   console.log(this.contactsItems);
    // }
  }

  isContactsRequired(i) {
    if (
      this.contactsItems[i].NAME != "" ||
      this.contactsItems[i].MAIL != "" ||
      this.contactsItems[i].PHONE != ""
    ) {
      return "required";
    }
    return "";
  }
  async checkEmail(mail, pos) {
    var count = 1;
    this.contactsItems[pos].ISDUPLICATE = 0;
    const ACCOUNTCONTACTID = this.contactsItems[pos].ACCOUNTCONTACTID || 0;
    $("#" + pos).removeClass("initial");
    const reqBody = {
      FunctionName: "emailCheck",
      Controller: "Contacts",
      Email: mail,
      aID: this.currAID,
      contactID: ACCOUNTCONTACTID,
    };
    if (
      !this.$validator.errors.has(`contact_email_${pos}`) &&
      mail.length > 3 &&
      mail.match("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}") != null
    ) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", reqBody);
      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      $(".checkMail:not(#" + pos + ")").each(function(this: Window) {
        if ($(".checkMail#" + pos).val() == $(this).val()) {
          count = 0;
        }
      });
      if (response.data.STATUS !== 1) {
        if (
          response.data.CONTACTARR[0].ACCOUNTCONTACTID !=
          this.contactsItems[pos].ID
        ) {
          $("#customerContacts #" + pos).removeClass("initial");
          this.contactsItems[pos].ISDUPLICATE = 1;
          $(".errorMsg#EmailCheck_" + pos).addClass("errorMail");
          $(".errorMsg#EmailCheck_" + pos).text(response.data.MESSAGE);
          // this.notification('alert', response.data.MESSAGE);
          // throw new Error(response.data.MESSAGE);
        }
      }
      if (response.data.STATUS == 1 && count == 1) {
        $(".errorMsg#EmailCheck_" + pos).removeClass("errorMail");
        $(".errorMsg#EmailCheck_" + pos).text("");
        // this.notification('success', response.data.MESSAGE);
      }
      if (count == 1) {
        $("#" + pos).removeClass("error");
      }
    } else {
      if (!$("#" + pos).hasClass("error")) {
        $("#" + pos).addClass("error");
      }
      $("#" + pos).removeClass("initial");
      $(".EmailCheck").text("");
    }
  }
  
  async setBusinessLines() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "list"
    });
    this.businessLineOptions = [];
    if(this.businessLines) {
      (response.data.businessLineOptions || []).map(item => (
        this.businessLineOptions.push({
          ID: item.ID,
          TEXT: item.BUSINESSLINENAME,
          data: item
        })
      ));
      this.businessLineId = 1;
    } else {
      this.businessLineOptions.push({
        ID: 0,
        TEXT: "ALL"
      });
      this.businessLineId = 0;
    }
    if (response.data.STATUS === 1) {
      this.targetBusinessLineOptions = [
        {
          ID: 0,
          TEXT: "All"
        }
      ];
      (response.data.businessLineOptions || []).map((item: any) => {
        this.targetBusinessLineOptions.push({
          ID: item.ID,
          TEXT: item.BUSINESSLINENAME,
          data: item
        })
      });
    }
  }

  settingDetTab = '';
  gotoSettingTab(tab) {
    this.settingDetTab = tab;
    this.isActive = 'settingDet';
  }

  changeInfoOptions = [
    {
      id: "Quotes",
      text: "Quotes"
    },
    {
      id: "Orders",
      text: "Orders"
    },
    {
      id: "Hardware",
      text: "Hardware"
    },
    {
      id: "Deployments",
      text: "Deployments"
    }
  ];
  toggleChangeInfoOption(id, text) {
    if (!this.selectedChangeInfoIds.includes(id)) {
      this.selectedChangeInfoIds.push(id);
    } else {
      this.selectedChangeInfoIds = this.selectedChangeInfoIds.filter(
          t => t != id
      );
    }
    // sort
    const ret: any = [];
    for (const t of this.changeInfoOptions) {
      if (this.selectedChangeInfoIds.includes(t.id)) {
        ret.push(t.id);
      }
    }
    this.selectedChangeInfoIds = ret;
  }

  selectAllInfoOption() {
    const ret: any = [];
    for (const t of this.changeInfoOptions) {
      ret.push(t.id);
    }
    this.selectedChangeInfoIds = ret;
  }

  updateSelectedChangeInfoIds(type, index, item) {
    if (type == "delete") {
      this.selectedChangeInfoIds = this.selectedChangeInfoIds.filter(t => t != item);
    }
  }



  // tags
  toggleSelectedTags(selectedID, tagName) {
    const index = this.selectedTagsID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedTagsID.push(selectedID);
      this.selectedTags.push({
        ACCOUNTTAGID: selectedID,
        TAGNAME: tagName
      });
      return;
    }

    this.selectedTagsID.splice(index, 1);
    this.$delete(this.selectedTags, index);
  }

  selectAllTags() {
    this.resetTags();
    var comInstance = this;
    this.accountTags.forEach(function(val: any) {
      comInstance.selectedTagsID.push(val.ACCOUNTTAGID);
      comInstance.selectedTags.push({
        ACCOUNTTAGID: val.ACCOUNTTAGID,
        TAGNAME: val.TAGNAME
      });
    });
  }

  resetTags() {
    this.selectedTags = [];
    this.selectedTagsID = [];
  }

  updateTagList(type, index, tagID) {
    if (this.selectedTagsID && type == "delete") {
      this.$delete(this.selectedTagsID, index);
      this.$delete(this.selectedTags, index);
    }
  }
}
