































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";

@Component({
  inheritAttrs: true,
  components: {}
})

export default class TargetChangesComponent extends TSXComponent<void> {

  @Prop({ required: false, default: 0 })
  aID!: number;

  loading: boolean = false;
  changes: any[] = [];

  created() {
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    const response = await ApiHelper.callApi("post", {
      controller        : "Customers",
      FunctionName      : "GetTargetChanges",
      aID               : this.aID
    });

    if (response.STATUS === 1) {
      this.changes = response.CHANGES || [];
    }
    this.loading = false;
  }

  clickOutside() {
    this.$emit("close");
  }

}
