













































































































































































































































































































































































































































































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { getTimezoneInfo } from "@/helpers/ApiHelper";
import moment from "moment";

import InputDate from "../components/InputDate.vue";
import Pagination from "../components/Pagination.vue";
import EmployeeMultiselect from "../components/EmployeeMultiselect.vue";
import LaddaButton from "../components/LaddaButton.vue";
import { PaycheckResp } from "../models/PaycheckResp";
import { notifier, wait, downloadFileUrl } from "../models/common";
import InfoTooltip from "../components/InfoTooltip.vue";
import InputDateRange from "../components/customDateComponent.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import HPAgentDetailsRowTooltip from "../components/HPAgentDetailsRowTooltip.vue";
import PriceWarnTooltip from "../components/PriceWarnTooltip.vue";

interface Props {}

interface Events {
	onClose: void;
	onGenerated: void;
}

declare const $: any;
declare const dataURL: string;
declare const getMaxRows: Function;

@Component({
	inheritAttrs: false,
	components: {
		InputDate,
		Pagination,
		EmployeeMultiselect,
		LaddaButton,
		InfoTooltip,
		InputDateRange,
		ConfirmRemoveItemModal,
		HPAgentDetailsRowTooltip,
		PriceWarnTooltip
	},
})
export default class PaycheckGenerateModal extends TSXComponent<Props, Events> {
	loading = true;
	tooltips = {};
	generating = false;
	confirmPaycheckGenerate = false;
	paycheckLoading = false;
	noOfOrders = 0;
	paycheckTotalAmount = 0;
	statementAmount = 0;
	indirectAmount = 0;
	statementsTotal = 0;
	indirectTotal = 0;
	grandTotal = "0";

	name = "";
	startDate: Date = new Date();
	endDate: Date = new Date();
	startDateOrder: Date = new Date();
	endDateOrder: Date = new Date();
	selectedMonth = null;
	dateFilter = 0;
	selectedUserIds: number[] = [];
	selectedStatementIds: number[] = [];
	selectedOrderIds: number[] = [];
	indirectSale = false;
	// ordersInOtherPaychecks = false;
	// statementInOtherPaychecks = false;
	onlyPaidOrders = true;
	paycheckIncludeName = "";

	pageData: PaycheckResp.Root = {
		Users: [],
		ORDERS: [],
		CommissionStatements: [],
		CURRENTPAGE: 1,
		EXCLUDEDORDERS: 0,
		TOTALPAGES: 1,
	};

	activeTab: "statements" | "orders" = "orders";

	// Allocation tool tip
	allocationTooltipVisibleIndex = -1;
	allocationTooltipLoading = false;
	allocationTooltipList = [];
	allocationTooltipaID = 0;
	selectedPaychecks: any = [];
	confirmAllocation = false;
	remainingBusinessLineComm: any = [];
	businessLines = false;
	excludeCompOrderIds: number[] = [];
	excludeCompStatementIds: number[] = [];

	$refs!: {
		modal: HTMLDivElement;
		table: HTMLDivElement;
		customdate: InputDateRange;
	};

  async created() {
		const timeInfo = await getTimezoneInfo();
		const dynamicTzo = timeInfo.dynamicTzo;
		const absTzo = Math.abs(dynamicTzo);
		// Convert the time zone offset to the format ±hhmm 
		const timezone = `${dynamicTzo >= 0 ? '+' : '-'}${String(Math.floor(absTzo / 60)).padStart(2, '0')}${String(absTzo % 60).padStart(2, '0')}`;

		const date = new Date(new Date().toLocaleString('en-US', {timeZone: timezone, hour12: false}));
		this.endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		this.startDate = new Date(
			this.endDate.getFullYear(),
			this.endDate.getMonth(),
			1
		);
		this.endDateOrder = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		this.startDateOrder = new Date(
			this.endDateOrder.getFullYear(),
			this.endDateOrder.getMonth(),
			1
		);
	}

	async startDateChange() {
		this.dateFilter = 1;
		var count = 0;
		if (
			!$("#startDate")
				.val()
				.match(
					/^\d{4}(\/|-|\.)?((((0[13578])|(1[02]))(\/|-|\.)?(([0-2][0-9])|(3[01])))|(((0[469])|(11))(\/|-|\.)?(([0-2][0-9])|(30)))|(02(\/|-|\.)?[0-2][0-9]))$/
				)
		) {
			count++;
			$(".errorMsg.date").text(
				"Please enter a valid value. The field is incomplete or has an invalid date"
			);
		} else {
			$(".errorMsg.date").text("");
		}
		if (count == 0) {
			await this.fetchData();
		}
	}

	async endDateChange() {
		this.dateFilter = 1;
		var count = 0;
		if (
			!$("#endDate")
				.val()
				.match(
					/^\d{4}(\/|-|\.)?((((0[13578])|(1[02]))(\/|-|\.)?(([0-2][0-9])|(3[01])))|(((0[469])|(11))(\/|-|\.)?(([0-2][0-9])|(30)))|(02(\/|-|\.)?[0-2][0-9]))$/
				)
		) {
			count++;
			$(".errorMsg.date").text(
				"Please enter a valid value. The field is incomplete or has an invalid date"
			);
		} else {
			$(".errorMsg.date").text("");
		}
		if (count == 0) {
			await this.fetchData();
		}
	}

	async updateMonth(date) {
		this.dateFilter = 1;
		if (this.activeTab == "statements") {
			this.startDate = date.startOf("month").toDate();
			this.endDate = date.endOf("month").toDate();
		} else {
			this.startDateOrder = date.startOf("month").toDate();
			this.endDateOrder = date.endOf("month").toDate();
		}
		this.$refs.customdate.dateRange.endDate = date.endOf("month").toDate();
		this.$refs.customdate.dateRange.startDate = date.startOf("month").toDate();
		this.$refs.customdate.checkOpen();
		await this.fetchData();
	}

	async fetchData(isReload = false) {
		this.loading = true;
		try {
			const ORDER_ROW_HEIGHT = 90;
			const TABLE_HEADER_HEIGHT = 30;
			const tableHeight = $(this.$refs.table).height();
			const maxRows = Math.floor(
				(tableHeight - TABLE_HEADER_HEIGHT) / ORDER_ROW_HEIGHT
			);

			const paycheckDefinitionResp = await axios.post(
				dataURL + "?ReturnType=JSON",
				{
					controller: "Finances",
					FunctionName: "paycheckDefinition",
					StatementsDate: moment(this.startDate).format("MM/DD/YYYY"),
					statementeDate: moment(this.endDate).format("MM/DD/YYYY"),
					sDate: moment(this.startDateOrder).format("MM/DD/YYYY"),
					eDate: moment(this.endDateOrder).format("MM/DD/YYYY"),
					status: this.onlyPaidOrders ? "5" : "",
					// includeOrderPaychecks: this.ordersInOtherPaychecks ? 1 : 0,
					// includeOStatementPaychecks: this.statementInOtherPaychecks ? 1 : 0
				}
			);
			this.pageData = paycheckDefinitionResp.data;
			/* if (this.activeTab == "statements" && !isReload) {
				// this.selectedStatementIds = this.pageData.CommissionStatements.map(
				// 	(o) => o.MC_CSTATEMENT_ID
				// );
				this.statementsTotal = paycheckDefinitionResp.data.STATEMENTTOTAL;
			}
			if (this.activeTab != "statements" && !isReload) {
				// if (this.indirectSaleOrdersCheckAll) {
					// this.indirectTotal = paycheckDefinitionResp.data.INDIRECTTOTAL;
				// }

				// this.selectedOrderIds = this.pageData.ORDERS.filter(
				// 	(o) => o.ALLOCATIONPERCENT > 0
				// ).map((o) => o.PURCHASEID);
			} */
			this.statementAmount = paycheckDefinitionResp.data.STATEMENTTOTAL;
			this.indirectAmount = paycheckDefinitionResp.data.INDIRECTTOTAL;
			if (this.excludeCompOrderIds.length) {
				(this.pageData.ORDERS || []).map((item: any) => {
					if (this.excludeCompOrderIds.includes(item.INVOICEID)) {
						item.ISCOMPGEN = 0;
					}
				});
			}
			if (this.excludeCompStatementIds.length) {
				(this.pageData.CommissionStatements || []).map((item: any) => {
					if (this.excludeCompStatementIds.includes(item.MC_CSTATEMENT_ID)) {
						item.ISCOMPGEN = 0;
					}
				});
			}

			if (!this.dateFilter) {
				this.selectedOrderIds = this.selectedOrderIds.filter((id) =>
					this.pageData.ORDERS.some((item) => item.INVOICEID === id)
				);
				this.selectedUserIds = this.pageData.Users.map((u) => u.USERID);
				this.selectedStatementIds = this.selectedStatementIds.filter((id) =>
					this.pageData.CommissionStatements.some(
						(item) => item.MC_CSTATEMENT_ID === id
					)
				);
			} else {
				this.dateFilter = 0;
			}
		} finally {
			this.loading = false;
		}
	}

	async mounted() {
		$(this.$refs.modal).modal("show");

		$(this.$refs.modal).on("hide.bs.modal", () => {
			this.$emit("close");
		});

		await this.fetchData();
	}

	async generateConfirmation() {
		if(this.confirmAllocation) {
			this.confirmAllocation = false;
		}
		this.confirmPaycheckGenerate = true;
		this.noOfOrders =
			this.uiSelectedStatementIds.length + this.uiSelectedOrderIds.length;
		this.paycheckTotalAmount = this.indirectTotal + this.statementsTotal;
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
		});
		this.grandTotal = formatter.format(this.paycheckTotalAmount);
		this.paycheckIncludeName = this.activeTab == "statements" ? 'statements' : 'invoices';
	}

	async generate() {
		if (this.generating) return;

		const ok = await this.$validator.validateAll();
		if (!ok) {
			return;
		}

		// this.generating = true;
		this.paycheckLoading = true;
		try {
			var sDate, eDate;
			if (this.activeTab == "statements") {
				sDate = moment(this.startDate).format("MM/DD/YYYY");
				eDate = moment(this.endDate).format("MM/DD/YYYY");
			} else {
				sDate = moment(this.startDateOrder).format("MM/DD/YYYY");
				eDate = moment(this.endDateOrder).format("MM/DD/YYYY");
			}
			let invCompGenIds = (this.pageData.ORDERS || [])
				.filter((item: any) => this.uiSelectedOrderIds.includes(item.INVOICEID) && item.ISCOMPGEN)
				.map((item: any) => item.INVOICEID);
			let agentCompGenIds = (this.pageData.CommissionStatements || [])
				.filter((item: any) => this.uiSelectedStatementIds.includes(item.MC_CSTATEMENT_ID) && item.ISCOMPGEN)
				.map((item: any) => item.MC_CSTATEMENT_ID);
			const payl = {
				controller: "Finances",
				FunctionName: "payCheckGenerate",
				indirectSale: 1,
				userids: this.selectedUserIds.join(","),
				statementids: this.uiSelectedStatementIds.join(","),
				orderscheckall: this.indirectSaleOrdersCheckAll,
				orderids: this.uiSelectedOrderIds.join(","),
				sDate: sDate,
				eDate: eDate,
				title: this.name,
				invCompGenIds: invCompGenIds.join(","),
				agentCompGenIds: agentCompGenIds.join(",")
			};

			const resp = await axios.post(dataURL + "?ReturnType=JSON", payl);

			// this.generating = false;
			this.paycheckLoading = false;
			await wait(1000);

			this.$emit("generated", { PCGroupId: resp.data.PCGroupId });
		} catch (err) {
			notifier.alert(err.message);
		} finally {
			// this.generating = false;
			this.paycheckLoading = false;
		}
	}

	indirectSaleOrdersCheckAll = false;
	indirectSaleOrdersCheckAllChange() {
		this.indirectSaleOrdersCheckAll = !this.indirectSaleOrdersCheckAll;

		if (!this.indirectSaleOrdersCheckAll) {
			this.selectedOrderIds = [];
			this.indirectTotal = 0;
		} else {
			this.indirectTotal = this.indirectAmount;
			this.selectedOrderIds = this.pageData.ORDERS.filter(
				(o) => o.ALLOCATIONPERCENT > 0

			).map((o) => o.INVOICEID);
		}
	}

	get uiSelectedOrderIds() {
		if (this.indirectSaleOrdersCheckAll) {
			return (this.selectedOrderIds = this.pageData.ORDERS.filter(
				(o) => o.ALLOCATIONPERCENT > 0
			).map((o) => o.INVOICEID));
		}

		return this.selectedOrderIds;
	}

	statementsCheckAll = false;
	statementsCheckAllChange() {
		this.statementsCheckAll = !this.statementsCheckAll;
		if (!this.statementsCheckAll) {
			this.selectedStatementIds = [];
			this.statementsTotal = 0;
			this.indirectSale = true;
		} else {
			this.statementsTotal = this.statementAmount;
			this.selectedStatementIds = this.pageData.CommissionStatements.map(
				(o) => o.MC_CSTATEMENT_ID
			);
		}
	}

	get uiSelectedStatementIds() {
		if (this.statementsCheckAll) {
			return this.pageData.CommissionStatements.map((o) => o.MC_CSTATEMENT_ID);
		}

		return this.selectedStatementIds;
	}

	get generateBtnEnabled(): boolean {
		if (this.uiSelectedOrderIds.length > 0) {
			return true;
		}
		if (this.selectedUserIds.length === 0) {
			return false;
		}

		// if(this.name.length === 0){
		//   return false
		// }

		// case statements
		if (this.uiSelectedStatementIds.length > 0) {
			return true;
		}

		// case indirect sale orders
		if (this.indirectSale || this.uiSelectedOrderIds.length > 0) {
			if (this.selectedOrderIds.length === 0) {
				return false;
			}
			return true;
		}

		return false;
	}

	indirectSaleOrdersCheckChange(id, isEnabled, total, item) {
		this.indirectSaleOrdersCheckAll = false;

		// if (isEnabled) {
			const index = this.selectedOrderIds.findIndex(
				(oid) => oid.toString() === id.toString()
			);
			if (index === -1) {
				this.selectedOrderIds.push(id);
				this.indirectTotal += total;
			} else {
				this.selectedOrderIds.splice(index, 1);
				this.indirectTotal -= total;
			}
			if (
				this.selectedOrderIds.length + this.pageData.EXCLUDEDORDERS ===
				this.pageData.ORDERS.length
			) {
				this.indirectSaleOrdersCheckAll = true;
			}
		// }
	}

	statementCheckChange(id, total) {
		this.statementsCheckAll = false;
		const index = this.selectedStatementIds.findIndex(
			(oid) => oid.toString() === id.toString()
		);
		if (index === -1) {
			this.selectedStatementIds.push(id);
			this.statementsTotal += total;
		} else {
			this.selectedStatementIds.splice(index, 1);
			this.statementsTotal -= total;
		}

		if (
			this.selectedStatementIds.length ===
			this.pageData.CommissionStatements.length
		) {
			this.statementsCheckAll = true;
		}

		if (this.uiSelectedStatementIds.length === 0) {
			this.indirectSale = true;
		}
	}

	beforeDestroy() {
		$(this.$refs.modal).modal("hide");
	}

	toggleSelectedUserId(userId: number) {
		const index = this.selectedUserIds.findIndex((id) => id === userId);
		if (index === -1) {
			this.selectedUserIds.push(userId);
			return;
		}

		this.selectedUserIds.splice(index, 1);
	}

	selectAllUsers() {
		this.selectedUserIds = this.pageData.Users.map((u) => u.USERID);
	}

	resetUsers() {
		this.selectedUserIds = [];
	}
	async updatedate(dat, idx) {
		if (idx) {
			this.startDate = dat.startDate;
			this.endDate = dat.endDate;
		} else {
			this.startDateOrder = dat.startDate;
			this.endDateOrder = dat.endDate;
		}
		await this.fetchData();
	}
	empInfoTooltipData = "";
	empInfoTooltipVisibleIndex = -1;
	async showUsersCntInfo(index, item) {
		var tooltipHeight = $(this.$refs[`agentTooltip${index}`]).offset().top - $(window).scrollTop();
		var bottom = $(this.$refs[`agentTooltip${index}`]).offset().top - $(window).scrollTop() + 180;
		var container = $("body").height();
		bottom = container - bottom;

		if (bottom <= tooltipHeight && $("#details-content-container .detail-item").length > 3) {
			if (!$(this.$refs[`agentTooltip${index}`]).parent().hasClass("topTooltip")) {
				$(this.$refs[`agentTooltip${index}`]).parent().addClass("topTooltip");
			}
		} else {
			$(this.$refs[`agentTooltip${index}`]).parent().removeClass("topTooltip");
		}

		if (item.TOTALEMPLOYEES > 0) {
			this.empInfoTooltipData = "";
			this.empInfoTooltipVisibleIndex = index;
			try {
				const response = await axios.post(dataURL + "?ReturnType=JSON", {
					controller: "Finances",
					FunctionName: "GetAssginedPercent",
					cs_id: item.MC_CSTATEMENT_ID,
				});
				this.empInfoTooltipData =
					response.data.STATUS == 1 ? response.data.ASSIGNEDPERCENTARR : [];
			} catch (err) {
				// console.log(err.message);
			}
		}
	}
	async downloadPaycheck() {
		this.loading = true;
		if (
			(this.activeTab == "statements" && this.uiSelectedStatementIds.length) ||
			(this.activeTab != "statements" && this.uiSelectedOrderIds.length)
		) {
			// this.generating = true;
			this.paycheckLoading = true;
			var sDate, eDate;
			if (this.activeTab == "statements") {
				sDate = moment(this.startDate).format("MM/DD/YYYY");
				eDate = moment(this.endDate).format("MM/DD/YYYY");
			} else {
				sDate = moment(this.startDateOrder).format("MM/DD/YYYY");
				eDate = moment(this.endDateOrder).format("MM/DD/YYYY");
			}
			const payl = {
				controller: "Finances",
				FunctionName: "paycheckDownload",
				StatementsDate: moment(this.startDate).format("MM/DD/YYYY"),
				statementeDate: moment(this.endDate).format("MM/DD/YYYY"),
				sDate: moment(this.startDateOrder).format("MM/DD/YYYY"),
				eDate: moment(this.endDateOrder).format("MM/DD/YYYY"),
				userids: this.selectedUserIds.join(","),
				selectedID: this.uiSelectedOrderIds.join(","),
				statementids: this.uiSelectedStatementIds.join(","),
				status: this.onlyPaidOrders ? "5" : "",
				currTab: this.activeTab,
				// includeOrderPaychecks: this.ordersInOtherPaychecks ? 1 : 0,
				// includeOStatementPaychecks: this.statementInOtherPaychecks ? 1 : 0,
				paycheckCSVDownload : true
			};
			let fileUrl, response;
			try {
				response = await axios.post(dataURL + "?ReturnType=JSON", payl);
				fileUrl = response.data.S3URL;
			} catch (err) {
				notifier.alert("Export CSV error: " + err.message);
			}
			this.paycheckLoading = false;
			this.loading = false;
			return downloadFileUrl(fileUrl);
		} else {
			// console.log("select any order");
			notifier.alert("Please select at least one statement or sales order");
		}
		this.loading = false;
		this.paycheckLoading = false;
	}

	// Allocatio tool tip
	async showTooltipAllocation(item, index, e) {
		var tooltipHeight = $(this.$refs[`paycheckId${index}`]).offset().top - $(window).scrollTop();
		var bottom = $(this.$refs[`paycheckId${index}`]).offset().top - $(window).scrollTop() + 180;
		var container = $("body").height();
		bottom = container - bottom;
		if (bottom <= tooltipHeight && $("#details-content-container .detail-item").length > 3) {
			if (!$(this.$refs[`paycheckId${index}`]).parent().hasClass("topTooltip")) {
				$(this.$refs[`paycheckId${index}`]).parent().addClass("topTooltip");
			}
		} else {
			$(this.$refs[`paycheckId${index}`]).parent().removeClass("topTooltip");
		}
		if (this.allocationTooltipVisibleIndex === index) {
			return;
		}

		var acountID =
			typeof item.MC_CDETAIL_ACCOUNTID != "undefined"
				? item.MC_CDETAIL_ACCOUNTID
				: "";
		acountID = item.ACCOUNTID;

		if (!acountID) return;
		// this.reloadedParent = false;

		// this.linkAccountTooltipVisible_1 = -1;
		try {
			this.allocationTooltipLoading = true;
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				Controller: "Finances",
				FunctionName: "GetAssginedPercent",
				accountID: acountID,
				invoiceId: item.INVOICEID,
				oneTimeComm: item.INVOICECOMMISSION
			});

			if (response.data.STATUS == 1) {
				this.allocationTooltipList = response.data.ASSIGNEDPERCENTARR;
				this.allocationTooltipList.forEach(function (val: any) {
					val.oldPercent = val.PERCENTAGE;
				});
				this.allocationTooltipaID = response.data.aID;
				this.remainingBusinessLineComm = response.data.REMAININGBUSINESSLINECOMM;
				this.businessLines = response.data.COMMISIONBLINES;
				this.allocationTooltipVisibleIndex = index;
			}

			this.allocationTooltipLoading = false;
		} catch (err) {
			notifier.alert(err.message);
			this.allocationTooltipVisibleIndex = -1;
		}
	}
	hideTooltipAllocation(item, index) {
		this.allocationTooltipVisibleIndex = -1;
	}
	reload(){
		this.allocationTooltipVisibleIndex = -1;
		this.updateAllocation({});
	}
	updateAllocation(info) {
		// Reset Allocation
		this.fetchData(true);
	}
	async allocationConfirmation() {
		this.selectedPaychecks = this.pageData.ORDERS.filter(
			(item: any) => this.selectedOrderIds.includes(item.INVOICEID) && (
				item.INVOICECOMMISSION ? item.INVCOMMISSIONPERCENTAGE > 0 && item.INVCOMMISSIONPERCENTAGE < 100 : item.ALLOCATIONPERCENT > 0 && item.ALLOCATIONPERCENT < 100
			)
		).map((item) => item);
		const ok = await this.$validator.validateAll();
		if (!ok) {
			return;
		} else if(this.selectedPaychecks.length) {
			this.confirmAllocation = true;
			return;
		}
		this.generateConfirmation();
	}
	mouseover(invoiceId, event) {
		// Ensure tooltips object exists to track each tooltip's visibility
		this.$set(this.tooltips, invoiceId, true);

		this.$nextTick(() => {
			// Find the tooltip element. You might need to adjust the selector based on your actual DOM structure.
			const tooltip = event.target.querySelector('.PriceWarnTooltip');
			if (!tooltip) return;

			const container = document.getElementById('details-content');
			if (!container) return;

			// Calculate the positions
			const tooltipRect = tooltip.getBoundingClientRect();
			const containerRect = container.getBoundingClientRect();

			// Distance from the bottom of the tooltip to the bottom of the container
			const distanceToBottom = containerRect.bottom - tooltipRect.bottom;

			if(distanceToBottom <= 18) {
				console.dir(tooltip);
				tooltip.classList.add('top');
			}
		});
	}
	mouseout(itemId) {
		this.$set(this.tooltips, itemId, false);
	}

	checkCompGen(item, type) {
		item.ISCOMPGEN = !item.ISCOMPGEN;
		if (type === "invoice") {
			const index = this.excludeCompOrderIds.findIndex(id => id === item.INVOICEID);
			if (index === -1) {
				this.excludeCompOrderIds.push(item.INVOICEID);
			} else {
				this.excludeCompOrderIds.splice(index, 1);
			}
		} else if (type === "agent") {
			const index = this.excludeCompStatementIds.findIndex(id => id === item.MC_CSTATEMENT_ID);
			if (index === -1) {
				this.excludeCompStatementIds.push(item.MC_CSTATEMENT_ID);
			} else {
				this.excludeCompStatementIds.splice(index, 1);
			}
		}
	}
}
