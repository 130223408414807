
















































































































































































































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import moment from "moment";

import { LedgerPaymentAPIRepo,Filters, Sort, SortField } from "../repos/LedgerPaymentAPIRepo";
import { notifier, wait, downloadFileUrl } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import Loader from './Loader.vue';
import Datepicker from "./Datepicker.vue";
import { getTimezoneInfo } from "@/helpers/ApiHelper";

interface Props {}

interface Events {
  onClose: void;
  onGenerated: void;
}

declare const $: any;
declare const dataURL: string;
declare const getMaxRows: Function;
const paymnetRepo = new LedgerPaymentAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    ConfirmRemoveItemModal,
    FilterCurrencyBox,
    Loader,
    Datepicker
  },
})

export default class LedgerPaymentModal extends TSXComponent<Props, Events> {
  @Prop({required: true})
  title!: string

  @Prop({required: true, default: false})
  ledgerPayment!: string

  loading = false;
  confirmPaymentGenerate = false;
  paymentLoading = false;
  noOfOrders = 0;
  paymentName = "";
  dateFilter = 0;
  filters: Filters = {
    paymentPurchaseID: "",
    paymentSource: [],
    paymentsTotalMin: "",
    paymentsTotalMax: "",
    paymentInvoiceID: "",
    paymentInvoicesDueDate: undefined,
    paymentInvoiceeDueDate: undefined
  };
  sort = {
    field: 0,
    direction: {
      [SortField.paymentPurchaseID]: 2,
      [SortField.paymentSource]: 1,
      [SortField.paymentAmount]: 1,
      [SortField.paymentInvoiceID]: 1,
      [SortField.paymentDueDate]: 1
    }
  };
  viewType = "CurrentUsers";
  selectedView = false;
  paymentDetails:any = [];
  pageNumber = 1;
  selectedID: (number | undefined)[] = [];
  excludedIDs: number[] = [];
  selectedAll = false;
  selectPageAll = false;
  showFilter = 0;
  selectedPage = 0;
  newPagePayment: (number | undefined)[] = [];
  totalLedgers: (number | undefined)[] = [];
  sourceList = [];
  selectedPaymentTotal = 0;
  selectedIdPaymentArr:any = [];
  firstClick = true;
  disableClick = true;

  $refs!: {
    modal: HTMLDivElement;
    table: HTMLDivElement;
  };

  paidDate = "";

  async mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
    $(this.$refs.modal).on('hidden.bs.modal', () => {
      if ($('.modal.show:visible').length) {
        $('body').addClass('modal-open');
      }
    });
  }

 async created() {
    const timeInfo = await getTimezoneInfo();
    this.paidDate = timeInfo.formattedDate;
    await this.fetchData(true);
  }

  async fetchData(initialLoad: boolean = false) {
    this.loading = true;
    try {
      const ret = await paymnetRepo.find(
      this.filters,
      this.sort,
      this.pageNumber,
      this.viewType,
      this.selectedView,
      initialLoad
    );
    this.paymentDetails = ret.PAYMENTOVERVIEW;
    this.sourceList = ret.sourcesObj;
    if(typeof this.sourceList != 'undefined' && this.filters.paymentSource.length == 0 && initialLoad) {
      this.filters.paymentSource = Object.keys(this.sourceList);
    }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
    if (this.selectedAll === true) {
      this.selectAll(true);
    }
    var checkAllPages = this.newPagePayment.includes(this.pageNumber);
    if (checkAllPages)  {
      this.totalLedgers = [];
      for (var val of this.paymentDetails) {

        if (!this.excludedIDs.includes(val.PLID)) {
          this.totalLedgers.push(val.PLID);
        }
      }
      var intersectingArrays = this.getArraysIntersection(this.totalLedgers, this.selectedID);
      if (intersectingArrays.length == this.paymentDetails.length) {
        this.paymentCheckAll = true;
      } else {
        this.paymentCheckAll = false;
      }
    }
    else {
      this.paymentCheckAll = false;
    }
    await wait(500);
    this.disableClick = true;
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async searchById() {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.filters.paymentPurchaseID == "") {
      return;
    } else {
      await this.fetchData();
    }
  }

  async resetSearchById() {
    this.pageNumber = 1;
    this.filters.paymentPurchaseID = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }  

  async checkSourceList(paymentSource: string) {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.filters.paymentSource.findIndex(s => s === paymentSource);
    if (i === -1) {
      this.filters.paymentSource.push(paymentSource);
    } else {
      this.filters.paymentSource.splice(i, 1);
    }
    await this.fetchData();
  }
  async checkAllSourceList() {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.paymentSource = Object.keys(this.sourceList);
    await this.fetchData();
  }
  async uncheckAllSourceList() {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.paymentSource = [];
    await this.fetchData();
  }
  async resetSourceList() {
    // this.pageNumber = 1;
    // this.selectedAll = false;
    // this.excludedIDs = [];
    this.filters.paymentSource = [];
    this.checkAllSourceList();
    // await this.fetchData();
  }

  async searchByAmount() {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async resetSearchByAmount() {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.paymentsTotalMin = "";
    this.filters.paymentsTotalMax = "";
    await this.fetchData();
  }

  async searchByInvoiceID() {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.filters.paymentInvoiceID == "") {
      return;
    } else {
      await this.fetchData();
    }
  }
  async resetSearchByInvoiceID() {
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.paymentInvoiceID = "";
    await this.fetchData();
  }

  async searchByDueDate() {
    this.pageNumber = 1;
    if (
      (this.filters.paymentInvoicesDueDate == undefined ||
        this.filters.paymentInvoicesDueDate == "") &&
      (this.filters.paymentInvoiceeDueDate == "" ||
        this.filters.paymentInvoiceeDueDate == undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      await this.fetchData();
    }
  }
  async resetSearchByDueDate() {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      (this.filters.paymentInvoicesDueDate == undefined ||
        this.filters.paymentInvoicesDueDate == "") &&
      (this.filters.paymentInvoiceeDueDate == "" ||
        this.filters.paymentInvoiceeDueDate == undefined)
    ) {
      return;
    } else {
      this.filters.paymentInvoicesDueDate = "";
      this.filters.paymentInvoiceeDueDate = "";
      await this.fetchData();
    }
  }

  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }
  async sortingByOrderID() {
    await this.sortBy(SortField.paymentPurchaseID);
  }
  async sortingBySource() {
    await this.sortBy(SortField.paymentSource);
  }
  async sortingByLedgerAmount() {
    await this.sortBy(SortField.paymentAmount);
  }
  async sortingByLedgerInvoiceID() {
    await this.sortBy(SortField.paymentInvoiceID);
  }
  async sortingByLedgerDueDate() {
    await this.sortBy(SortField.paymentDueDate);
  }

  paymentCheckAll = false;
  paymentCheckAllChange() {
    this.selectedPaymentTotal = 0;
    this.paymentCheckAll = !this.paymentCheckAll;
    if (!this.paymentCheckAll) {
      this.selectInPage(false);
    } else {
      this.selectInPage(true);
      this.sumCheckedPayment(this.paymentDetails);
    }
  }

  sumCheckedPayment(element){
    element.forEach(item => {
      this.selectedPaymentTotal += parseFloat(item.PL_AMOUNT_NOTFORMATTED);
    });
  }

  selectInPage(selectedAllPage = true) {
    this.selectPageAll = selectedAllPage;
    this.showFilter = 0;
    if (this.selectPageAll == true) {
      this.selectedPage = this.pageNumber
      this.newPagePayment.push(this.selectedPage);
      for (var val of this.paymentDetails) {
        // if (!this.excludedIDs.includes(val.PLID)) {
          if(this.selectedID.indexOf(val.PLID) == -1)  {
            this.selectedID.push(val.PLID);
            this.totalLedgers.push(val.PLID);
            var ledgerDetails = {"PLID": val.PLID, "PAYMENTAMOUNT": val.PL_AMOUNT_NOTFORMATTED, "PURCHASEID": val.PURCHASEID};
            this.selectedIdPaymentArr.push(ledgerDetails);
          }
        // }
      }
    } else {
      for (var val of this.paymentDetails) {
        let selectedIDIndex = this.selectedID.indexOf(val.PLID);
        let totalLedgersIndex = this.totalLedgers.indexOf(val.PLID);
        this.selectedID.splice(selectedIDIndex, 1);
        this.totalLedgers.splice(totalLedgersIndex, 1);
      }
      let newPagePaymentIndex = this.newPagePayment.indexOf(this.pageNumber)
      this.newPagePayment.splice(newPagePaymentIndex, 1);
      this.selectedIdPaymentArr = [];
    }
  }

  checkExcludedIDs(paymentDetails, key, index) {
    if (this.selectedAll || this.selectPageAll) {
      for (var paymentDetail of paymentDetails) {
        if (!this.selectedID.includes(paymentDetail[key]) && !this.excludedIDs.includes(paymentDetail[key])) {
          this.excludedIDs.push(paymentDetail[key]);
          this.paymentCheckAll = false;
        } else if (this.excludedIDs.includes(paymentDetail[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex((excludedID) => excludedID == paymentDetail[key])
          );
        }
      }
    }
    this.paymentCheckAll = false;
    if(this.selectedID.length == this.totalLedgers.length && this.selectedID.length !=0) {
      this.paymentCheckAll = true;
    }

    const selectedIndex = this.selectedID.findIndex(id => id === paymentDetails[index].PLID);
    var plId = paymentDetails[index].PLID;
    var checkPlId = this.selectedIdPaymentArr.findIndex((s) => s.PLID === plId);

    if (selectedIndex === -1)
      this.selectedIdPaymentArr.splice(checkPlId, 1);
    else {
      var ledgerDetails = {"PLID": plId, "PAYMENTAMOUNT": paymentDetails[index].PL_AMOUNT_NOTFORMATTED, "PURCHASEID": paymentDetails[index].PURCHASEID};
      if(checkPlId === -1)
        this.selectedIdPaymentArr.push(ledgerDetails);
      else
        this.selectedIdPaymentArr[checkPlId] = ledgerDetails;
    }
    var total = 0;
    this.selectedIdPaymentArr.forEach(ledgerAmount => {
      total += parseFloat(ledgerAmount.PAYMENTAMOUNT);
    });
    this.selectedPaymentTotal = total;
  }

  selectAll(selectedAll = true) {
    this.paymentCheckAll = false;
    this.excludedIDs = [];
    this.selectedAll = selectedAll;
    this.selectedID = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (var val of this.paymentDetails) {
        if (!this.excludedIDs.includes(val.PLID)) {
          this.selectedID.push(val.PLID);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }
  
  getArraysIntersection(getLedgersOne,getLedgersTwo) {
    return getLedgersOne.filter(function(n) { return getLedgersTwo.indexOf(n) !== -1;});
  }

  get generateBtnEnabled(): boolean {
    if (this.selectedID.length > 0) {
      return true;
    }
    return false;
  }
  async generateConfirmation() {
    const ok = await this.$validator.validateAll();
    let validPaidDate = true;
      if(this.paidDate !="" && !moment(this.paidDate, "MM/DD/YYYY", true).isValid()) {
        validPaidDate = false;
        this.$validator.errors.add({
          field: "paidDate",
          msg: "Invalid Date (Date must be in the format mm/dd/yyyy)"
        });
      }
    if (!ok || !validPaidDate) {
      return;
    } else {
      this.confirmPaymentGenerate = true;
      this.noOfOrders = this.selectedID.length;
    }
  }
  async generate() {
    this.paymentLoading = true;
    try {
      const payment = {
        controller: "Finances",
        FunctionName: "ledgerPayment",
        action: "paymentUpdate",
        ledgerID: this.selectedID.join(","),
        paymentName: this.paymentName,
        paymentAmount: this.selectedPaymentTotal,
        paidDate: this.paidDate
      };
      const response = await axios.post(dataURL + "?ReturnType=JSON", payment);
      this.paymentLoading = false;
      if (response.data.STATUS == 1 && !this.ledgerPayment) {
        this.$router.push({name:"ledgerPayments"});
      } else if (response.data.STATUS == 1 && this.ledgerPayment) {
        this.$emit("close");
        this.$emit("ledgerPaymentLoad");
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.paymentLoading = false;
    }
  }
  async sorting() {
    if (!this.disableClick) {
      return;
    }
    this.disableClick = false;
    if(this.selectedID.length && this.firstClick) {
      var arr: any = [];
      var id = this.selectedID;
      var details = this.paymentDetails
      for (let i=0; i < this.paymentDetails.length; i++) {
        if($.inArray(this.paymentDetails[i].PLID, id) != -1) {
          arr.push(this.paymentDetails[i]);
          details.splice(i,1);
          i--;
        }
      }
      arr.reverse().forEach(item => {
        details.splice(0, 0, item);
      });
      this.paymentDetails = details;
      this.firstClick = false;
      await wait(500);
      this.disableClick = true;
    }
    else {
      this.firstClick = true;
      await this.fetchData();
    }
  }
  clickOutside() {
    this.$emit("close");
  }
}
