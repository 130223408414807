
















import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import directives from "@/helpers/directives";

import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";
import { SecurityGroupDetails } from "../models/SecurityGroupDetails";

interface Props {
  defaultText?: string
  value: string
}

interface Events {
  onInput: string
}

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
  directives
})
export default class SecurityAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, default : false })
  show?: boolean

  @Prop({ required: false, default: "" })
  targetYear?: string;

  @Prop({ required: false, default: "Security Group"})
  placeholderText?: string;

  @Prop({ required: false, default: "bottom" })
  tooltipPosition!: string;

  @Prop({ required: false, default: false})
  titles?: string;

  @Prop({ required: false, default: 'accounts' })
  groupType!: string;

  @Prop({ required: false, default: false })
  lastThreeYearsMargin?: boolean;

  @Prop({ required: false, default: false })
  allowFinancialBoard?: boolean;

  @Prop({ required: false, default: 0 })
  businessLineId?: number;

  @Prop({ required: false, default: "Security Groups"})
  field?: string;

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string

  options: Option[] = [];
  searchData: SecurityGroupDetails.SecurityAccountsArr[] = [];

  createOptions(securityGroups: SecurityGroupDetails.SecurityAccountsArr[]) {
    const options = securityGroups.map(
      (acc): Option => {
        return {
          value: acc.SECURITYGROUPID.toString(),
          text: acc.SECURITYGROUPNAME
        };
      }
    );

    this.options = options;
    this.searchData = securityGroups;

    return {
      options,
    };
  }

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    if (!search) {
      if (this.groupType == "all") {
        const response = await ApiHelper.callApi("post", {
          controller    : "Queries",
          FunctionName  : "GetAllEmployeeGroups",
          subsystem     : "Helpers",
          queryType     : "top5UsedGroups"
        });
        if (response.ERROR || !response.ALLSECURITYGROUPS) {
          return {
            options: []
          };
        }
  
        return this.createOptions(response.ALLSECURITYGROUPS)
      } else {
        const response = await ApiHelper.callApi("post", {
          controller    : "Finances",
          FunctionName  : "EmpPlanList",
          action        : "securityGroups",
          queryType     : "top5UsedGroups",
          targetYear    : this.targetYear,
          lastThreeYearsMargin : this.lastThreeYearsMargin,
          allowFinancialBoard   : this.allowFinancialBoard,
          businessLineId       : this.businessLineId
        });
        if (response.ERROR || !response.ALLSECURITYGROUPS) {
          return {
            options: []
          };
        }
  
        return this.createOptions(response.ALLSECURITYGROUPS)
      }
    }

    if (this.groupType == "all") {
      const response = await ApiHelper.callApi("post", {
        controller      : "Queries",
        FunctionName    : "GetAllEmployeeGroups",
        subsystem       : "Helpers",
        search          : search
      });
      if (response.ERROR || !response.ALLSECURITYGROUPS) {
        return {
          options: []
        };
      }
  
      return this.createOptions(response.ALLSECURITYGROUPS)
    } else {
      const response = await ApiHelper.callApi("post", {
        controller      : "Finances",
        FunctionName    : "EmpPlanList",
        action          : "securityGroups",
        search          : search,
        targetYear      : this.targetYear,
        lastThreeYearsMargin : this.lastThreeYearsMargin,
        allowFinancialBoard   : this.allowFinancialBoard,
        businessLineId       : this.businessLineId
      });
      if (response.ERROR || !response.ALLSECURITYGROUPS) {
        return {
          options: []
        };
      }
  
      return this.createOptions(response.ALLSECURITYGROUPS)
    }
  };

  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return

    const option = this.options.find((o) => o.value === value)
    if (!option) {
      //console.log('this.options', this.options)
      //console.warn(`no option found for value[${value}]`)
      return
    }
    const securityGroups = this.searchData.find((item) => item.SECURITYGROUPID.toString() === value)
    if (!securityGroups) {
      // console.warn(`no securityGroups found for value[${value}]`)
      return
    }
    this.$emit('selection', {
      option,
      securityGroups,
    })
  }
}
