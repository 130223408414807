var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " Cost Price ";
},"3":function(container,depth0,helpers,partials,data) {
    return " Price Per ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.BUILDHEADER : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "    <tr "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ISCONFIG : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":50}}})) != null ? stack1 : "")
    + ">\n      <td width=\"50%\" style=\"padding-left: "
    + alias4(((helper = (helper = helpers.SKUINDENT || (depth0 != null ? depth0.SKUINDENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SKUINDENT","hash":{},"data":data,"loc":{"start":{"line":22,"column":43},"end":{"line":22,"column":56}}}) : helper)))
    + "px; font-style: "
    + alias4(((helper = (helper = helpers.FONTSTYLE || (depth0 != null ? depth0.FONTSTYLE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"FONTSTYLE","hash":{},"data":data,"loc":{"start":{"line":22,"column":72},"end":{"line":22,"column":85}}}) : helper)))
    + ";\">\n        "
    + ((stack1 = ((helper = (helper = helpers.POPRODDESC || (depth0 != null ? depth0.POPRODDESC : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"POPRODDESC","hash":{},"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":24}}}) : helper))) != null ? stack1 : "")
    + "<br/>\n        <strong style=\"text-transform: uppercase;\">"
    + ((stack1 = ((helper = (helper = helpers.POPRODSKU || (depth0 != null ? depth0.POPRODSKU : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"POPRODSKU","hash":{},"data":data,"loc":{"start":{"line":25,"column":51},"end":{"line":25,"column":66}}}) : helper))) != null ? stack1 : "")
    + "</strong>\n      </td>\n      <td width=\"10%\" style=\"text-align: right;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.EXPORTPO),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":34,"column":15}}})) != null ? stack1 : "")
    + "      </td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + ((stack1 = ((helper = (helper = helpers.POPRODQUANTITY || (depth0 != null ? depth0.POPRODQUANTITY : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"POPRODQUANTITY","hash":{},"data":data,"loc":{"start":{"line":36,"column":49},"end":{"line":36,"column":69}}}) : helper))) != null ? stack1 : "")
    + "</td>\n      <td width=\"15%\" style=\"text-align: right;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.EXPORTPO),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "      </td>\n    </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function";

  return "      <tr "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ISCONFIG : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":10,"column":52}}})) != null ? stack1 : "")
    + ">\n        <td width=\"50%\" style=\"padding-left: "
    + container.escapeExpression(((helper = (helper = helpers.SKUINDENT || (depth0 != null ? depth0.SKUINDENT : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SKUINDENT","hash":{},"data":data,"loc":{"start":{"line":11,"column":45},"end":{"line":11,"column":58}}}) : helper)))
    + "px; font-style: regular;\">\n          <div style=\"border-bottom: 2px solid #f0f0f0; padding-bottom: 3px;display: inline-block;\">\n            <strong style=\"font-size: 12px; font-weight: 600; color: #000000;\">"
    + ((stack1 = ((helper = (helper = helpers.BUILDHEADER || (depth0 != null ? depth0.BUILDHEADER : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BUILDHEADER","hash":{},"data":data,"loc":{"start":{"line":13,"column":79},"end":{"line":13,"column":96}}}) : helper))) != null ? stack1 : "")
    + "</strong>\n          </div>\n        </td>\n        <td width=\"10%\"></td>\n        <td width=\"10%\"></td>\n        <td width=\"15%\"></td>\n      </tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "class=\"config-line\"";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "          "
    + ((stack1 = ((helper = (helper = helpers.POPRICEREG_FORMATTED || (depth0 != null ? depth0.POPRICEREG_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"POPRICEREG_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":29,"column":10},"end":{"line":29,"column":36}}}) : helper))) != null ? stack1 : "")
    + " \n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ISFEE : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":34,"column":8}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "          "
    + ((stack1 = ((helper = (helper = helpers.POCUSTOMERPRICE_FORMATTED || (depth0 != null ? depth0.POCUSTOMERPRICE_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"POCUSTOMERPRICE_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":33,"column":10},"end":{"line":33,"column":41}}}) : helper))) != null ? stack1 : "")
    + " \n        ";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "          "
    + ((stack1 = ((helper = (helper = helpers.LIPOPRICEREG_FORMATTED || (depth0 != null ? depth0.LIPOPRICEREG_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"LIPOPRICEREG_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":39,"column":10},"end":{"line":39,"column":38}}}) : helper))) != null ? stack1 : "")
    + " \n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ISFEE : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":44,"column":8}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "          "
    + ((stack1 = ((helper = (helper = helpers.LICUSTOMERTOTALPRICE_FORMATTED || (depth0 != null ? depth0.LICUSTOMERTOTALPRICE_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"LICUSTOMERTOTALPRICE_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":43,"column":10},"end":{"line":43,"column":46}}}) : helper))) != null ? stack1 : "")
    + " \n        ";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.taxMsg), depth0))
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TAXMESSAGE),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":61,"column":12},"end":{"line":65,"column":19}}})) != null ? stack1 : "")
    + "            : "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.LSDATE), depth0))
    + "\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TAXMESSAGE), depth0))
    + "\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "              PLEASE NOTIFY US IMMEDIATELY IF THIS <br>ORDER CANNOT BE SHIPPED COMPLETE <br>TAX ON OR BEFORE\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <tr style=\"font-weight: bold;\">\n      <td width=\"50%\">&nbsp;</td>\n      <td width=\"40%\" style=\"text-align: right; color: #B1B1B1;\" colspan=\"2\">Amount Paid ("
    + alias2(alias1(((stack1 = (data && data.root)) && stack1.INCLUDEPAYMENTTYPE), depth0))
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.INCLUDEPAYMENTNUMBER),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":118},"end":{"line":89,"column":190}}})) != null ? stack1 : "")
    + "):</td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + alias2(alias1(((stack1 = (data && data.root)) && stack1.INCLUDEPAYMENTAMOUNT), depth0))
    + "</td>\n    </tr>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.INCLUDEPAYMENTNUMBER), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n    <td width=\"50%\" style=\"border-bottom: 5px solid #b1b1b1;\">LINE ITEM / SKU</td>\n    <td width=\"10%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.EXPORTPO),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":81},"end":{"line":4,"column":141}}})) != null ? stack1 : "")
    + "</td>\n    <td width=\"10%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">Qty</td>\n    <td width=\"15%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">TOTAL</td>\n  </tr>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (data && data.root)) && stack1.ORDERITEMS),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":47,"column":11}}})) != null ? stack1 : "")
    + "  <tr style=\"font-weight: bold;\">\n    <td width=\"50%\" style=\"border-top: 5px solid #b1b1b1;\">&nbsp;</td>    \n    <td width=\"40%\" style=\"text-align: right; color: #B1B1B1; border-top: 5px solid #b1b1b1;\" colspan=\"2\">Subtotal:</td>\n    <td width=\"10%\" style=\"text-align: right; border-top: 5px solid #b1b1b1;\">"
    + alias3(alias2(((stack1 = (data && data.root)) && stack1.ORDERITEMSTOTAL), depth0))
    + "</td>\n  </tr>\n    <tr style=\"font-weight: bold;\">\n      <td width=\"50%\">\n        <strong>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":57,"column":10},"end":{"line":67,"column":17}}})) != null ? stack1 : "")
    + "        </strong>\n      </td>\n      <td width=\"40%\" style=\"text-align: right; color: #B1B1B1;\" colspan=\"2\">Tax:</td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + alias3(alias2(((stack1 = (data && data.root)) && stack1.TOTALTAXFORMATTED), depth0))
    + "</td>\n    </tr>\n    <tr style=\"font-weight: bold;\">\n      <td width=\"50%\">&nbsp;</td>\n      <td width=\"40%\" style=\"text-align: right; color: #B1B1B1;\" colspan=\"2\">Shipping:</td>\n      <td width=\"10%\" style=\"text-align: right;\">"
    + alias3(alias2(((stack1 = (data && data.root)) && stack1.TOTALSHIPPINGFORMATTED), depth0))
    + "</td>\n    </tr>\n  <tr style=\"font-weight: bold;\">\n    <td width=\"50%\">&nbsp;</td>\n    <td width=\"40%\" style=\"text-align: right; color: #B1B1B1;\" colspan=\"2\">Grand Total:</td>\n    <td width=\"10%\" style=\"text-align: right;\">"
    + alias3(alias2(((stack1 = (data && data.root)) && stack1.GRANDTOTALFORMATTED), depth0))
    + "</td>\n  </tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.INCLUDEPAYMENT),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":2},"end":{"line":92,"column":9}}})) != null ? stack1 : "")
    + "</table>\n";
},"useData":true});