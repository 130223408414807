




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import { CustomerAPIRepo } from "../repos/CustomerAPIRepo";
import SecurityGroupRowTooltip, { Item } from '../components/SecurityGroupRowTooltip.vue'
import draggable from "vuedraggable";
import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
import {
  colsResizeable,
  colsResizeableReset,
  getEUAddress,
  dollarFormat, getRangeFilter
  , checkTooltip, getRandomNumber, colsResizeableUpdate
} from "@/helpers/ApiHelper";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import MergeAccountListModal from "../components/MergeAccountListModal.vue";
import ConfirmMergeAccountModal from "../components/ConfirmMergeAccountModal.vue";
import { UserAPIRepo } from "../repos/UserAPIRepo"
import AccountTagsAssignModal from "../components/AccountTagsAssignModal.vue";
import { AcctsListResp } from "../models/AcctsListResp";
import HPAgentDetailsLinkAccountTooltip from "../components/HPAgentDetailsLinkAccountTooltip.vue";
import CustomerAdd from "../components/HPAgentDetailsCustomerAddModal.vue";
import ImageUploader from "@/components/ImageUploader.vue";
import FilterCurrencyBox from "@/components/FilterCurrencyBox.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { ApiHelper } from "@/helpers/all";
import TargetChangesComponent from "@/components/Customer/TargetChanges.vue";
import moment from "moment";

declare const $: any;
declare const dataURL: string;
declare function require(params: any): any;

const template = require("../templates/customers/customerList.handlebars");

const CustomerRepo = new CustomerAPIRepo();
const userRepo = new UserAPIRepo();

interface Props {
  title: string;
  selectedIDs: string[];
}

interface Events {
  onClose: void;
  onConfirm: void;
}

@Component({
  inheritAttrs: false,
  components: {
    FilterCurrencyBox,
    ListHeaderActions,
    Pagination,
    draggable,
    ExportModal,
    SecurityGroupRowTooltip,
    ConfirmRemoveItemModal,
    MergeAccountListModal,
    ConfirmMergeAccountModal,
    AccountTagsAssignModal,
    HPAgentDetailsLinkAccountTooltip,
    CustomerAdd,
    ImageUploader,
    TargetChangesComponent
  },
  methods: {
    getEUAddress,
    dollarFormat
  }
})
export default class Customers extends TSXComponent<Props, Events> {
  aID = '';
  numArr :number[] = [];
  selectedID: (number | undefined)[] = [];
  primaryAccount:number = 0;
  item: string[] = [];
  confirmRemoveModalVisible = false;
  mergeAccountModalVisible = false;
  confirmAccountModalVisible = false;
  usersTooltipVisibleIndex = -1
  usersTooltipLoading = false
  usersTooltipList: Item[] = []
  exportModalVisible = false;
  loading:any = false;
  toggleTotalDollar = true;
  viewType = "CurrentUsers";
  isActive = "customerTag";
  currentRole = sessionStorage.getItem('userRole');
  selectedAll = false;
  excludedIDs: (number| undefined)[] = [];
  // from static js
  titleAccName = '';
  items: {
    ACCOUNTID?:number
  }[] = [];
  subaccounts: string[] = [];
  customerAccounts: string[] = [];
  tableColumns = [];
  customFields = [];
  userObj:{
    USERID?: number,
    disabled?: boolean,
    class?:string
  }[] = [];
  groupObj:{
    SECURITYGROUPID?: number,
    disabled?: boolean,
    class?:string
  }[] = [];
  logindetailsObj:{
    ACCOUNTNAME?: string,
    DOMAINNAME?: string,
    DOMAIN?: string,
    UFNAME?: string,
    UEMAIL?: string,
  }[] = [];
  details = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1,
    TOTALALERTS: 0,
    TOTAL: 0,
    searchName: '',
    userSelectedCurrentView: '',
    searchId: '',
    CUSTOMERLOGINDETAILS : this.logindetailsObj,
    SELECTEDUSERS  : this.userObj,
    SELECTEDGROUPS  : this.groupObj,
    ALLSECURITYGROUPS  : this.groupObj,
    ALLUSERS : this.userObj,
    customerIds : this.numArr,
    TOTALPROFIT: 0,
    TOTALREVENUE: 0,
    TOTALPROFITBK: 0,
    TOTALREVENUEBK: 0,
    PROFITPRECENTAGE: 0,
    LASTFAVCUSTOMERID: 0
  };
  targetViewDetails: any = {};

  statusCheckList = this.numArr;
  resellerCheckList = this.numArr;
  filters: any = {
    oppTotalMin: "",
    oppTotalMax: "",
    sAccountID: "",
    sAccountName: "",
    prevYearTPMin: "",
    prevYearTPMax: "",
    currYearTPMin: "",
    currYearTPMax: "",
    nonZeroAmountRevenue: false,
    nonZeroAmountAllTimeRevenue: false,
    currentRevenueTotalMin: "",
    currentRevenueTotalMax: "",
    nonZeroAmountProfit: false,
    currentProfitTotalMin: "",
    currentProfitTotalMax: "",
    spendMin: "",
    spendMax: "",
    profitMin: "",
    profitMax: "",
    marginMin: "",
    marginMax: "",
    hideProfitZero: false,
    AccountTagID: [],
    selectedSecurityGroups: [],
    aStatus: [1],
    aStatusName: ["Active"],
    allTimeRevenueTotalMin: "",
    allTimeRevenueTotalMax: "",
    groupIDs: []
  };
  searchFilters: any = {
    oppTotalMin: "",
    oppTotalMax: "",
    sAccountID: "",
    sAccountName: "",
    prevYearTPMin: "",
    prevYearTPMax: "",
    currYearTPMin: "",
    currYearTPMax: "",
    nonZeroAmountRevenue: false,
    nonZeroAmountAllTimeRevenue: false,
    currentRevenueTotalMin: "",
    currentRevenueTotalMax: "",
    nonZeroAmountProfit: false,
    currentProfitTotalMin: "",
    currentProfitTotalMax: "",
    spendMin: "",
    spendMax: "",
    profitMin: "",
    profitMax: "",
    marginMin: "",
    marginMax: "",
    hideProfitZero: false,
    accountTagName: [],
    selectedSecurityGroups: [],
    aStatus: [1],
    aStatusName: ["Active"],
    allTimeRevenueTotalMin: "",
    allTimeRevenueTotalMax: "",
    groupName: []
  };
  sort = {
    field : "",
    direction : "",
    type : ""
  }

  dataObj: any = {
    controller : "Customers",
    FunctionName : "List",
    sub : 2
  };
  accountTags: any = [];
  searchTagsTxt = "";
  AccountTagsAssignModalVisible = false;
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["customers"] ? JSON.parse(sessionStorage.pageDetails)["customers"] : 1 ;
  pageTargetViewNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["customers"] ? JSON.parse(sessionStorage.pageDetails)["customers"] : 1 ;
  filterBtn = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["customersType"] ? JSON.parse(sessionStorage.pageDetails)["customersType"] : "currentList";
  linkAccountTooltipVisible = -1;
  unidentifiedNumber = 0;
  currentCustIndex = -1;
  currentCustAddDetail = [];
  customerTargets: any = [];
  currentView = 'Performance'; // performance|growth|shrink|target
  securityGroups: any[] = [];
  selectedFavCustomer: any[] = [];
  currentYear = moment().format('YYYY');
  businessLines: any = {};
  businessLineIds: string[] = [];
  businessLineName: string[] = [];
  hasChange: boolean = false;
  currYear: number = new Date().getFullYear();
  viewYear: number | string = this.currYear;
  targetYear: number = this.currYear;
  selectPageAll: boolean = false;
  totalCustomers: any[] = [];
  maxRows: number = 0;

  sortTargetView: any = {
    field: 0,
    direction: {
      [1]: 1,
      [2]: 1,
      [3]: 1,
      [4]: 1,
      [5]: 1,
      [6]: 1,
      [7]: 1
    }
  };
  searchHeader:string = "";
  searchByHeader() {
    const filteredHeader = this.pageHeaders
        .filter((item) => item.value.toLowerCase().search(this.searchHeader.toLowerCase()) !== -1);

    return filteredHeader;
  }

  pageHeaders = [
    { key: "STATUS", value: "Status"},
    { key: "RANK", value: "Rank"},
    { key: "ACCOUNTID", value: "Account Id" },
    { key: "ANAME", value: "Customer" },
    { key: "OPPORTUNITY", value:"Opportunities" },
    { key: "TOTAL365_FORMATTED", value: "Orders (past 365)" },
    { key: "QUOTES365_FORMATTED", value: "Quotes (past 365)" },
    { key: "ALLTIMEREVENUE", value: "All Time Revenue" },
    // { key: "YEARWISE", value: "" },
    // { key: "YTD", value: "Total YTD/Prior YTD $" },
    { key: "SECURITYGROUPCOUNT", value: "Security Group" },
    { key: "LASTORDERDATE", value: "Last Order" },
    { key: "LASTQUOTEDATE", value: "Last Quote" },
    { key: "CURRENTPROFIT", value: "Current / Prior Profit (YTD)"},
    { key: "CURRENTREVENUE", value: "Current / Prior Revenue (YTD)"},
    { key: "CURRENTTARGET", value: `${this.viewYear} Target` }
  ];
  headerCustomer = [
    "STATUS",
    "RANK",
    "ACCOUNTID",
    "ANAME",
    "OPPORTUNITY",
    "TOTAL365_FORMATTED",
    "QUOTES365_FORMATTED",
    "ALLTIMEREVENUE",
    // "YEARWISE",
    // "YTD",
    "SECURITYGROUPCOUNT",
    "LASTORDERDATE",
    "LASTQUOTEDATE",
    "CURRENTPROFIT",
    "CURRENTREVENUE",
    "CURRENTTARGET"
  ];
  selectedHeaders = [
    "STATUS",
    "RANK",
    "ACCOUNTID",
    "ANAME",
    "OPPORTUNITY",
    "TOTAL365_FORMATTED",
    "QUOTES365_FORMATTED",
    // "YEARWISE",
    // "YTD",
    "CURRENTPROFIT",
    "CURRENTREVENUE",
    "CURRENTTARGET"
  ];
  securityGroupTooltipVisibleIndex = -1
  colors = [
    '#86a8a9',
    '#f2e497',
    '#dec691',
    '#60798c',
    '#4d6091'
  ];
  prevYear: string = ""
  selectedGrandTotal: number = 0;
  selectedProfitTotal: number = 0;
  hasChangedAccountFilters: boolean = false;
  hasChangedStatusFilters: boolean = false;
  expandedView: boolean = false;
  targetChangesTooltipIndex: number = -1;
  nonDraggableHeaders: string[] = ['STATUS', 'RANK'];
  hasChangedSecurityGroupFilters: boolean = false;

  async created(){
    // const customers = await CustomerRepo.customersList(this.selectedID,0);
    const currentView = sessionStorage.getItem('currentView') || "Performance";

    if (currentView !== 'target') {
      this.currentView = 'Performance';
      this.dataObj["pageNumber"] = this.pageNumber;
      this.maxRows = sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows();
      this.dataObj["maxRows"] = this.maxRows;
      this.grabCustomers(this.dataObj, this, '', '', 'page');
    } else {
      this.currentView = 'target';
      this.grabCustomerTargetView();
    }
  }

  beforeDestroy () {
		sessionStorage.removeItem('currentView');
	}

  @Watch("selectedheaders")
  colsChange(val: any) {
    colsResizeable();
  }

  get isTargetView() {
    return this.currentView === 'target';
  }

  async grabCustomers(dataObj, container, keyName, fieldname, option){
    // from static.js
    dataObj["businessLines"] = this.businessLineIds.join(',');
    // dataObj["maxRows"] = sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows();
    dataObj["aStatus"] = this.filters.aStatus.length ? this.filters.aStatus.join(",") : "-1";
    this.hasChangedStatusFilters = false;
    this.hasChangedAccountFilters = false;
    this.hasChangedSecurityGroupFilters = false;

    // customer view type
    if (this.filterBtn == 'unidentify') {
      this.dataObj["FunctionName"] = 'unidentify';
    } else {
      this.dataObj["FunctionName"] = 'List';
    }

    this.loading = true;
    // const response = await axios.post(dataURL + "?ReturnType=JSON", this.dataObj);
    var self = this;
    // if(typeof this.$route.query.page === "number") {
    //   dataObj["pageNumber"] = this.$route.query.page != '' ? this.$route.query.page : 1;
    // }
    var response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    // quoteData.then(function(response, statusText, jqXHR) {
      try {
          if (response.data.STATUS && response.data.STATUSMESSAGE) {
              self.items = response.data.CUSTOMERS || [];
              self.totalCustomersWithProfit = response.data.totalCustomersWithProfit || 0;
              self.subaccounts = response.data.SUBACCOUNTS || [];
              self.customerAccounts = response.data.CUSTOMERACCOUNTS || [];
              self.details = response.data || {};
              self.unidentifiedNumber = response.data.TOTALUNIDENTIFIEDNUMBER;
              self.securityGroups = response.data.GROUPS;
              self.selectedFavCustomer = response.data.FAVCUSTDATA;
              if(this.searchTagsTxt == "") {
                self.accountTags = self.details["accountTags"];
              }

              if(dataObj["FunctionName"] == "View") {//detail page
                  const selectedusers = self.details.SELECTEDUSERS;
                  const allUser = self.details.ALLUSERS;
                  var userListID:(number | undefined)[] = [];
                  if(selectedusers.length > 0) {
                      selectedusers.forEach(function(element) {
                          if(userListID.indexOf(element.USERID) < 0){
                              userListID.push(element.USERID);
                          }
                      });
                  }
                  if(allUser.length > 0) {
                      for(var i=0; i< allUser.length; i++) {
                          if(userListID.indexOf(allUser[i].USERID) >= 0) {
                              allUser[i].disabled = true;
                              allUser[i].class = "highlight";
                          }
                          else {
                              allUser[i].disabled = false;
                              allUser[i].class = "";
                          }
                      }
                  }
                  const selectedgroup = self.details.SELECTEDGROUPS;
                  const allGroup = self.details.ALLSECURITYGROUPS;
                  var securityID:(number | undefined)[] = [];
                  if(selectedgroup.length > 0) {
                      selectedgroup.forEach(function(element) {
                        if(element.SECURITYGROUPID)
                          securityID.push(element.SECURITYGROUPID);
                      });
                  }
                  if(allGroup.length > 0) {
                      for(var j=0; j < allGroup.length; j++) {
                          if(securityID.indexOf(allGroup[j].SECURITYGROUPID) >= 0) {
                              allGroup[j].disabled = true;
                              allGroup[j].class = "highlight";
                          }
                          else {
                              allGroup[j].disabled = false;
                              allGroup[j].class = "";
                          }
                      }
                  }
                  self.titleAccName =  response.data.CUSTOMERS[0].ANAME || '';
                  if(!self.details.CUSTOMERLOGINDETAILS.length){
                      self.details.CUSTOMERLOGINDETAILS = [
                          {
                            ACCOUNTNAME : '',
                            DOMAINNAME : self.titleAccName,
                            DOMAIN : "dev-us.com",
                            UFNAME : '',
                            UEMAIL : ''
                          }
                      ];
                  }
                  self.customFields = [];
                  if(typeof response.data.CUSTOMERS[0].ACCOUNTPARAMS != "undefined" && response.data.CUSTOMERS[0].ACCOUNTPARAMS != "") {
                      var tmpParams = JSON.parse(response.data.CUSTOMERS[0].ACCOUNTPARAMS);
                      if(typeof tmpParams["customFields"] != "undefined") {
                          self.customFields = tmpParams["customFields"];
                      }
                  }
              }

              this.totalCustomers = [];
              for (var val of this.items) {
                if (this.selectedID.includes(val.ACCOUNTID)) {
                  this.totalCustomers.push(val.ACCOUNTID);
                }
              }
              if (this.items.length == this.totalCustomers.length) {
                this.selectPageAll = true;
              } else {
                this.selectPageAll = false;
              }
              if (this.selectedID.length == 0) {
                this.resetSelectionTotal();
              }

              self.loading = false;
              if (option === 'new') {
                  self.details['searchName'] = '';
                  // self.details['searchEmail'] = '';
                  // self.details['sortingEmail'] = 1;
                  // self.details['sortingCustomer'] = 1;
                  // self.details['sortingDate'] = 1;

              } else if (option !== 'page') {
                  self.details[keyName] = dataObj[fieldname];
              }

              if(dataObj["FunctionName"] == "List") {
                  self.sort = {
                      "field"     : (typeof dataObj["order"] != "undefined" ? dataObj["order"] : null),
                      "direction" : (typeof dataObj["direction"] != "undefined" ? dataObj["direction"] : null),
                      "type"      : (typeof dataObj["type"] != "undefined" ? dataObj["type"] : null)
                  };
              }

              if (response.data.userCustomerHeaders && response.data.userCustomerHeaders.length) {
                self.selectedHeaders = response.data.userCustomerHeaders;
                if (!self.selectedHeaders.includes('STATUS')) {
                  self.selectedHeaders.splice(0, 0, 'STATUS');
                }
              }

            // if sorted header
            let savedSorting = response.data.userCustomerColumnOrder || [];
            if (savedSorting.length) {
              savedSorting = savedSorting.filter((item) => item != 'STATUS' && item != 'RANK');
              savedSorting = this.nonDraggableHeaders.concat(savedSorting);
              let newSorting: any = [];
              for (const header of savedSorting) {
                const inList = self.pageHeaders.find(item => item.key == header);
                if (inList) {
                  newSorting.push(inList);
                }
              }
              const newItems = self.pageHeaders.filter(
                  item => !savedSorting.includes(item.key)
              );
              newSorting = [...newSorting, ...newItems];
              self.pageHeaders = newSorting;

              // need to sort selectedHeaders
              self.sortSelectedHeader();
            }
            this.businessLines = response.data.BUSINESSLINES;
            if (typeof response.data.BUSINESSLINES != "undefined" && this.businessLineIds.length == 0) {
              for (let i = 0; i < response.data.BUSINESSLINES.length; i++) {
                this.businessLineIds.push(response.data.BUSINESSLINES[i].id);
                this.businessLineName.push(response.data.BUSINESSLINES[i].name);
              }
            }

            if (typeof response.data.GROUPS != "undefined" && this.filters.selectedSecurityGroups.length == 0) {
              for (let i = 0; i < response.data.GROUPS.length; i++) {
                this.filters.selectedSecurityGroups.push(response.data.GROUPS[i].SECURITYGROUPID);
                this.searchFilters.selectedSecurityGroups.push(response.data.GROUPS[i].SECURITYGROUPNAME);
              }
            }
            let currYearTargetIndex = this.pageHeaders.findIndex((item) => item.key == 'CURRENTTARGET');
            if (currYearTargetIndex >= 0) {
              this.pageHeaders[currYearTargetIndex].value = this.viewYear + " Target"
            }

          } else if (response.data.ERROR) {

              // console.log(response.data.ERROR);
          }
      } catch (e) {
          //handle error
          self.loading = 'error';
      }
    // }).catch(function(error) {
    //   self.loading = 'error';
    //   console.log(error);
    // });
    colsResizeable({ ms: 500 });
  }

  async grabCustomerTargetView() {
    this.loading = true;
    this.hasChangedAccountFilters = false;

    try {
      let requestData: any = {
        controller: "Customers",
        FunctionName: "TargetViewList",
        view: this.viewType,
        userID: sessionStorage.getItem('userId'),
        userRole: sessionStorage.getItem('userRole'),
        filters: this.filters,
        pageNumber: this.pageTargetViewNumber,
        order: this.sortTargetView.field,
        direction: this.sortTargetView.direction[this.sortTargetView.field],
        businessLines: this.businessLineIds.join(','),
        targetYear: this.targetYear,
        viewYear: this.viewYear
      };
      const response = await axios.post(
        dataURL + "?ReturnType=JSON",
        requestData
      );

      if (response.data.STATUS == 1) {
        this.targetViewDetails = response.data || {};
        const customerTargets = response.data.CUSTOMERS || [];
        this.accountTags = response.data.accountTags || [];
        this.prevYear = response.data.PREVYEAR || "";
        this.businessLines = response.data.BUSINESSLINES;
        if (typeof response.data.BUSINESSLINES != "undefined" && this.businessLineIds.length == 0) {
          for (let i = 0; i < response.data.BUSINESSLINES.length; i++) {
            this.businessLineIds.push(response.data.BUSINESSLINES[i].id);
            this.businessLineName.push(response.data.BUSINESSLINES[i].name);
          }
        }
        this.customerTargets = customerTargets.map((ct) => ({
          ...ct,
          isEditPrevYear: false,
          TARGET_PROFITBK: ct.TARGET_PROFIT,
          isEdit: false
        }))
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log('error ', error);
    } finally {
      this.loading = false;
    }
    colsResizeable({ ms: 500, selector:  '.page-list .page-list-container > table.tbl-target'});
  }

  async CustomerExport(type) {
    const invoicesData = await CustomerRepo.exportFileUrl(
      type,
      this.selectedID
    );
    try {
      if (invoicesData.length) {
        var link = document.createElement("a");
        link.target = "_blank";
        link.download = "Orders";
        link.href = invoicesData;
        link.click();
      }
    } catch (e) {
      //handle error
      // console.log("order.js : csvDownLoadFn error : ", e);
    }
  }
  // async taxTotal() {
  //   if(!$('.taxTotal').hasClass('taxInput')) {
  //     $('.taxTotal').addClass('taxInput');
  //     $('.taxpercent').addClass('displayNone');
  //     $('.dollarAmount').removeClass('displayNone');
  //   }
  //   else {
  //     $('.taxTotal').removeClass('taxInput');
  //     $('.taxpercent').removeClass('displayNone');
  //     $('.dollarAmount').addClass('displayNone');
  //   }
  // }
  updateValue(id,value) {
    if(value != '') {
      $('.search-list.right#'+id).removeAttr('style');
      $('.reset-list.right').removeClass('disableCursor');
    }
    else {
      $('.search-list.right#'+id).attr('style','cursor:not-allowed');
      $('.reset-list.right').addClass('disableCursor');
      if(id == 'sAccountID') {
        this.resetSearchByID();
      }
      else {
        this.resetSearchByAccountName();
      }
    }
  }
  showConfirmRemoveItemModal() {
    this.confirmRemoveModalVisible = true;
  }

  showMergeAccountsModal() {
    this.mergeAccountModalVisible = true;
  }

  async showMergeConfirm(AccId) {
    this.primaryAccount = AccId;
    this.mergeAccountModalVisible = false;
    this.confirmAccountModalVisible = true;
  }

  async closeMergeConfirmModal() {
    this.confirmAccountModalVisible = false;
    this.mergeAccountModalVisible = true;
  }
  selectallStatus() {
      this.selectedID = this.details.customerIds;
      $('.actionsBtn').removeClass('gray').addClass('teal');
  }
  UnselectallStatus() {
      this.selectedID = [];
       $('.selectedCount').text('');
      $('.actionsBtn').addClass('gray').removeClass('teal');
  }
  async mergeSelected() {
     this.loading = true;
    this.confirmAccountModalVisible = false;
    this.mergeAccountModalVisible = false;
    var primaryAccountID = this.primaryAccount;
    var subAccountIDs = this.selectedID;
    subAccountIDs.splice(subAccountIDs.indexOf(primaryAccountID),1);

    var dataObj = {
      "controller": "Customers",
      "FunctionName": "Add",
      "from": "mergeAccount",
      "primaryAccID": primaryAccountID,
      "subAccID": subAccountIDs
    };

    var self = this;
    const response1 = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    if (response1.data.STATUS !== 1) {
      if (response1.data.ERROR || response1.data.STATUSMESSAGE) {
        notifier.alert(response1.data.ERROR || response1.data.STATUSMESSAGE);
      }
    }else{
      notifier.success('Customer Account(s), Order(s), Quote(s) and Invoice(s) Merged Successfully');
      self.selectedID = [];
      const customers:any = await CustomerRepo.customersList(self.selectedID, 0, self.viewType, self.selectedAll, self.excludedIDs, self.filters, self.sort, self.maxRows);
      this.items = customers.CUSTOMERS;
      this.details = customers;
      this.loading = false;
    }
  }

  async removeSelected() {
    this.loading = true;
    this.confirmRemoveModalVisible = false;
    let successCount = 0;

    //for (const accountID of this.selectedID) {
      const reqBody = {
        session: sessionStorage.getItem("sessionID"),
        userRole: sessionStorage.getItem("userRole"),
        Controller: "customers",
        FunctionName: "Delete",
        //accountID,
        deleteCustomerAcc: "true",
        selectedAll: this.selectedAll,
        excludedIDs: this.excludedIDs,
        selectedIDs: this.selectedID.join(",")
      };

      const response = await axios.post(dataURL + "?ReturnType=JSON", reqBody);
      if (response.data.STATUS !== 1) {
        if (response.data.ERROR || response.data.STATUSMESSAGE) {
          notifier.alert(response.data.ERROR || response.data.STATUSMESSAGE);
        }
        //continue;
        successCount = 0;
      }else {
        successCount = this.selectedID.length;
      }
      //successCount++;
    //}

    if (successCount > 0) {
      $(".actionsBtn").addClass('gray').removeClass('teal');
      notifier.success(`${successCount} Customer(s) Deleted Successfully`);
      this.selectedID = [];
      this.selectedAll = false;
      this.excludedIDs = [];
    }
    const customers: any = await CustomerRepo.customersList(this.selectedID, 0, this.viewType, this.selectedAll, this.excludedIDs, this.filters, this.sort, this.maxRows);
    this.items = customers.CUSTOMERS;
    this.details = customers;
    this.loading = false;
  }

  async htmlDownLoadFn() {
    const html = await this.customersToHTML(true);

    return () => {
      if (!html) return;
      var fileNameExport =
        "CustomerList_" + Math.round(+new Date() / 1000) + ".html";
      downloadFile(fileNameExport, html);
    };
  }

  async customersToHTML(isExport = true): Promise<string | false> {
    try {
      const customers = await CustomerRepo.customersList(this.selectedID, (isExport ? 1 : 0), this.viewType, this.selectedAll, this.excludedIDs, this.filters, this.sort, this.maxRows, this.viewYear);
      return template(customers);
    } catch (err) {
     notifier.alert(err.message);
      return false;
    }
  }

  async print() {
    this.loading = true;
    const html = await this.customersToHTML(true);
    this.loading = false;
    if (!html) return;

    printHtml(html);
  }

  async pdfDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await CustomerRepo.exportFileUrl("PDF", this.selectedID, this.viewType, this.selectedAll, this.excludedIDs, this.filters, this.sort, this.viewYear);
    } catch (err) {
      notifier.alert("Export PDF error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async csvDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await CustomerRepo.exportFileUrl("CSV", this.selectedID, this.viewType, this.selectedAll, this.excludedIDs, this.filters, this.sort, this.viewYear);
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }
    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async performExport(formats: ExportFormat[]) {
    this.loading = true;
    this.exportModalVisible = false;

    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.csvDownLoadFn());
    }

    if (formats.includes(ExportFormat.html)) {
      exportFns.push(await this.htmlDownLoadFn());
    }

    if (formats.includes(ExportFormat.pdf)) {
      exportFns.push(await this.pdfDownLoadFn());
    }

    exportFns.forEach(fn => fn());

    this.loading = false;
  }

  async showTooltipUsers(item, index) {
    if (!item.length) return
    // console.log(item)
    // console.log(index)
    this.usersTooltipVisibleIndex = index

    try {
      this.usersTooltipLoading = true
      // const subAccounts = await CustomerRepo.findOneFewDetails(item.SECURITYGROUPID, FewDetailsJustGet.Users)

      this.usersTooltipList = item.map((item) => ({
        id: item.AID,
        text: item.SUBACCOUNT,
      }))

      this.usersTooltipLoading = false

      setTimeout(function(){
        if($("ul.list-group-flush").outerHeight() > $(".popover-content").outerHeight()){
            $(".ps__rail-y").css("height", "131px");
            $(".ps__thumb-y").css("height", "20px");
        }
        else{
            $(".ps__rail-y").css("height", "0");
            $(".ps__thumb-y").css("height", "0");
        }
      }, 100);

    } catch (err) {
      notifier.alert(err.message)
      this.usersTooltipVisibleIndex = -1
    }
  }
  hideTooltipUsers(item, index) {
    this.usersTooltipVisibleIndex = -1
  }
  toggleCustomView(currStatus){
    this.selectedAll = false;
    this.excludedIDs = [];
    this.selectedID = [];
    this.resetSelectionTotal();
    this.filters.AccountTagID = [];
    this.filters.groupIDs = [];
    this.searchFilters.accountTagName = [];
    this.searchFilters.groupName = [];
    this.viewType = currStatus;
    this.changeCustomerView(currStatus);
  }

  selectAll(selectedAll = true) {
    this.selectedAll = selectedAll;
    this.selectPageAll = true;
    this.selectedID = [];
    if(this.selectedAll == true) {
      for(var val of this.items) {
        if(!this.selectedID.includes(val.ACCOUNTID)) {
          if(val.ACCOUNTID)
          this.selectedID.push(val.ACCOUNTID);
          this.$delete(this.excludedIDs, this.excludedIDs.findIndex(id => id == val.ACCOUNTID));
        }
      }
      this.selectedGrandTotal = this.details.TOTALREVENUEBK;
      this.selectedProfitTotal = this.details.TOTALPROFITBK;
    }else {//uncheck
      this.excludedIDs = [];
      this.resetSelectionTotal();
    }
  }
  getSelectedTotal(total) {
    var ret = 0;
    if(this.selectedAll) {
      ret = total - this.excludedIDs.length;
    }else {
      ret = this.selectedID.length;
    }

    return ret;
  }
  checkExcludedIDs(items, key) {
    if(this.selectedAll) {
      for(var val of items) {
        if(!this.selectedID.includes(val[key])) {
          if(!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
          }
        }else if(this.excludedIDs.includes(val[key])) {
          this.$delete(this.excludedIDs, this.excludedIDs.findIndex((id) => id == val[key]));
        }
      }

      if(this.getSelectedTotal(this.details.TOTAL) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
  }

  @Watch("items")
  async itemsChanged() {
    if(this.selectedAll === true) {
      this.selectAll(true);
    }
    if(typeof this.details.userSelectedCurrentView != "undefined") {
      this.viewType = this.details.userSelectedCurrentView;
    }
  }

  resetSelected() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
  }
  // from static js
  changeCustomerView(view:string) {
    this.dataObj["pageNumber"] = 1;
    this.dataObj["view"] = view;
    this.dataObj["userID"] = sessionStorage.getItem('userId');
    this.dataObj["selectedTagIDs"] = "";
    this.dataObj["groupIDs"] = "";
    if (this.currentView == 'target') {
      this.grabCustomerTargetView();
    } else {
      this.grabCustomers(this.dataObj, this, 'sAccountName', 'sAccountName', '');
    }
  }
  loadPage(pageNumber:number, type) {
    if (type === "prev") {
      pageNumber = pageNumber - 1;
    } else if (type === "next") {
      pageNumber = pageNumber + 1;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["customers"]) {
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["customers"] = pageNumber;
      oldData["customersType"] = this.filterBtn;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (pageNumber <= this.details.TOTALPAGES && pageNumber >= 1) {
        this.dataObj["pageNumber"] = pageNumber;
        this.grabCustomers(this.dataObj, this, '', '', 'page');
    }
  }

  sortingById(){
    this.dataObj["order"] = 1;
    this.details['sortingId'] = (typeof this.details['sortingId'] != "undefined" ? this.details['sortingId'] : 1);
    this.dataObj["direction"] = parseInt(this.details['sortingId']) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, 'sortingId', 'direction', '');
  }
  sortingByCustomer() {
    this.dataObj["order"] = 2;
    this.details['sortingCustomer'] = (typeof this.details['sortingCustomer'] != "undefined" ? this.details['sortingCustomer'] : 1);
    this.dataObj["direction"] = parseInt(this.details['sortingCustomer']) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, 'sortingCustomer', 'direction', '');
  }
  sortingByOrder() {
    this.dataObj["order"] = 3;
    this.dataObj["type"] = 'order';
    this.details['sortingOrder'] = (typeof this.details['sortingOrder'] != "undefined" ? this.details['sortingOrder'] : 1);
    this.dataObj["direction"] = parseInt(this.details['sortingOrder']) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, 'sortingOrder', 'direction', '');
  }
  sortingByQuote() {
    this.dataObj["order"] = 4;
    this.details['sortingQuote'] = (typeof this.details['sortingQuote'] != "undefined" ? this.details['sortingQuote'] : 1);
    this.dataObj["direction"] = parseInt(this.details['sortingQuote']) === 1 ? 2 : 1;
    this.dataObj["type"] = 'quote';
    this.grabCustomers(this.dataObj, this, 'sortingQuote', 'direction', '');
  }
  sortingByNet(e) {
    this.dataObj["order"] = 6;
    this.details['sortingNet'] = (typeof this.details['sortingNet'] != "undefined" ? this.details['sortingNet'] : 1);
    this.dataObj["direction"] = e;
    this.dataObj["type"] = 'Net';
    this.grabCustomers(this.dataObj, this, 'sortingNet', 'direction', '');
  }

  sortingByTotal() {
    this.dataObj["order"] = 5;
    this.details['sortingTotal'] = (typeof this.details['sortingTotal'] != "undefined" ? this.details['sortingTotal'] : 1);
    this.dataObj["direction"] = parseInt(this.details['sortingTotal']) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, 'sortingTotal', 'direction', '');
  }

  sortingBySecurityGroup(){
    this.dataObj["order"] = 15;
    this.details['sortingSecurityGroup'] = this.details['sortingSecurityGroup'] ? this.details['sortingSecurityGroup'] : 1;
    this.dataObj["direction"] = parseInt(this.details['sortingSecurityGroup']) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, 'sortingSecurityGroup', 'direction', '');
  }

  sortingByOrderDate(){
    this.dataObj["order"] = 16;
    this.details['sortingOrderDate'] = this.details['sortingOrderDate'] ? this.details['sortingOrderDate'] : 1;
    this.dataObj["direction"] = parseInt(this.details['sortingOrderDate']) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, 'sortingOrderDate', 'direction', '');
  }

  sortingByQuoteDate(){
    this.dataObj["order"] = 17;
    this.details['sortingQuoteDate'] = this.details['sortingQuoteDate'] ? this.details['sortingQuoteDate'] : 1;
    this.dataObj["direction"] = parseInt(this.details['sortingQuoteDate']) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, 'sortingQuoteDate', 'direction', '');
  }
  sortingByAllTimeRevenue(){
    this.dataObj["order"] = 29;
    this.details['sortingAllTImeRevenue'] = this.details['sortingAllTImeRevenue'] ? this.details['sortingAllTImeRevenue'] : 1;
    this.dataObj["direction"] = parseInt(this.details['sortingAllTImeRevenue']) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, 'sortingAllTImeRevenue', 'direction', '');
  }
  sortingByStatus(){
    this.dataObj["order"] = 23;
    this.details['status'] = this.details['status'] ? this.details['status'] : 1;
    this.dataObj["direction"] = parseInt(this.details['status']) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, 'status', 'direction', '');
  }

  sortingTargetViewBy365DaySpend() {
    this.sortTargetView.field = 1;
    this.sortTargetView.direction[1] = this.sortTargetView.direction[1] === 1 ? 2: 1;
    this.grabCustomerTargetView();
  }

  sortingTargetViewByCurrentYearTarget() {
    if(this.currentView == 'target') {
      this.sortTargetView.field = 2;
      this.sortTargetView.direction[2] = this.sortTargetView.direction[2] === 1 ? 2: 1;
      this.grabCustomerTargetView();
    }
    else {
      this.dataObj["order"] = 25;
      this.details['sortingCurrentTarget'] = this.details['sortingCurrentTarget'] ? this.details['sortingCurrentTarget'] : 1;
      this.dataObj["direction"] = parseInt(this.details['sortingCurrentTarget']) === 1 ? 2 : 1;
      this.grabCustomers(this.dataObj, this, 'sortingCurrentTarget', 'direction', '');
    }
  }

  sortingByTargetViewId() {
    this.sortTargetView.field = 6;
    this.sortTargetView.direction[6] = this.sortTargetView.direction[6] === 1 ? 2: 1;
    this.grabCustomerTargetView();
  }

  sortingByTargetViewAccountName() {
    this.sortTargetView.field = 7;
    this.sortTargetView.direction[7] = this.sortTargetView.direction[7] === 1 ? 2: 1;
    this.grabCustomerTargetView();
  }

  sortingByRank() {
    this.dataObj["order"] = 26;
    this.details['sortingRank'] = (typeof this.details['sortingRank'] != "undefined" ? this.details['sortingRank'] : 1);
    this.dataObj["direction"] = parseInt(this.details['sortingRank']) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, 'sortingRank', 'direction', '');
  }
  sortingByOpportunities(){
    this.dataObj["order"] = 27;
    this.details['sortingOpportunity'] = (typeof this.details['sortingOpportunity'] != "undefined" ? this.details['sortingOpportunity'] : 1);
    this.dataObj["direction"] = parseInt(this.details['sortingOpportunity']) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, 'sortingOpportunity', 'direction', '');
  }
  sortingByCurrentProfit() {
    this.dataObj["order"] = 28;
    this.details['sortingCurrentProfit'] = (typeof this.details['sortingCurrentProfit'] != "undefined" ? this.details['sortingCurrentProfit'] : 1);
    this.dataObj["direction"] = parseInt(this.details['sortingCurrentProfit']) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, 'sortingCurrentProfit', 'direction', '');
  }

    //Search fields
  searchByID() {
    this.resetSelected();
    this.dataObj["pageNumber"] = 1;
    this.dataObj["sAccountID"] =  this.searchFilters.sAccountID = this.filters.sAccountID;
    if(this.filters.sAccountID != ''){
      this.grabCustomers(this.dataObj, this, 'sAccountID', 'sAccountID', '');
    }
  }

  searchByTargetViewID() {
    this.pageTargetViewNumber = 1;
    this.searchFilters.sAccountID = this.filters.sAccountID;
    this.grabCustomerTargetView();
  }

  searchByTargetViewAccountName() {
    this.pageTargetViewNumber = 1;
    this.searchFilters.sAccountName = this.filters.sAccountName;
    this.grabCustomerTargetView();
  }

  searchByAccountName() {
    this.resetSelected();
    this.dataObj["pageNumber"] = 1;
    this.dataObj["sAccountName"] = this.searchFilters.sAccountName = this.filters.sAccountName;
    if(this.filters.sAccountName != ''){
      this.grabCustomers(this.dataObj, this, 'sAccountName', 'sAccountName', '');
    }
  }

  searchByAll(searchData) {
    this.dataObj["pageNumber"] = 1;
    this.dataObj["sSearchAll"] = searchData;
    this.grabCustomers(this.dataObj, this, 'searchAll', 'sSearchAll', '');
  }

  //reset search fields
  resetSearchByID() {
    // $('.search-list').attr('style','cursor:not-allowed');
    // $('.reset-list.right').addClass('disableCursor');
    this.resetSelected();
    this.filters.sAccountID = this.searchFilters.sAccountID = '';
    this.dataObj["pageNumber"] = 1;
    this.dataObj["sAccountID"] = '';
    this.grabCustomers(this.dataObj, this, 'sAccountID', 'sAccountID', '');
  }

  resetSearchByTargetViewID() {
    this.searchFilters.sAccountID = '';
    this.filters.sAccountID = '';
    this.grabCustomerTargetView();
  }

  resetSearchByAccountName() {
    // $('.search-list').attr('style','cursor:not-allowed');
    // $('.reset-list.right').addClass('disableCursor');
    this.resetSelected();
    this.filters.sAccountName = this.searchFilters.sAccountName = '';
    this.dataObj["pageNumber"] = 1;
    this.dataObj["sAccountName"] = '';
    if(this.currentView == 'target') {
      this.pageTargetViewNumber = 1;
      this.grabCustomerTargetView();
    } else {
      this.grabCustomers(this.dataObj, this, 'sAccountName', 'sAccountName', '');
    }
  }

  sortingByQ(quater, selectedYear, order) {
    var key = 'sortingQ' + '_' + quater + '_' + selectedYear;
    this.dataObj["order"] = order;
    this.details[key] = (typeof this.details[key] != "undefined" ? this.details[key] : 1);
    this.dataObj["direction"] = parseInt(this.details[key]) === 1 ? 2 : 1;
    this.grabCustomers(this.dataObj, this, key, 'direction', '');
  }

  //filter by Tags
  async filterByTag(tagName = "", groupName = "") {
    this.resetSelected();
    this.hasChangedAccountFilters = true;
    this.dataObj["pageNumber"] = 1;
    this.dataObj["selectedTagIDs"] = this.filters.AccountTagID.join(",");
    this.dataObj["groupIDs"] = this.filters.groupIDs.join(",");
    if (tagName != "" && this.searchFilters.accountTagName != undefined) {
      const tagIndex = this.searchFilters.accountTagName.findIndex(
        tagValue => tagValue === tagName
      );
      if (tagIndex === -1) {
        this.searchFilters.accountTagName.push(tagName);
      } else {
        this.searchFilters.accountTagName.splice(tagIndex, 1);
      }
    }

    if (groupName != "" && this.searchFilters.groupName != undefined) {
      const groupIndex = this.searchFilters.groupName.findIndex(
        groupValue => groupValue === groupName
      );
      if (groupIndex === -1) {
        this.searchFilters.groupName.push(groupName);
      } else {
        this.searchFilters.groupName.splice(groupIndex, 1);
      }
    }
    // if(this.currentView == 'target') {
    //   this.grabCustomerTargetView();
    // } else {
    //   this.grabCustomers(this.dataObj, this, 'selectedTagIDs', 'selectedTagIDs', '');
    // }
  }
  async checkAllTag() {
    this.resetSelected();
    // this.filters.AccountTagID = this.accountTags.map(val => val.ACCOUNTTAGID);
    var $this = this;
		this.filters.AccountTagID = [];
    this.searchFilters.accountTagName = [];
		this.accountTags.forEach(function (val: any) {
			if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == 'undefined') {
				$this.filters.AccountTagID.push(val.ACCOUNTTAGID);
        $this.searchFilters.accountTagName.push(val.TAGNAME);
			}
		});
    this.dataObj["pageNumber"] = 1;
    this.dataObj["selectedTagIDs"] = this.filters.AccountTagID.join(",");
    this.dataObj["groupIDs"] = this.filters.groupIDs.join(",");
    if(this.currentView == 'target') {
      this.pageTargetViewNumber = 1;
      this.grabCustomerTargetView();
    } else {
      this.grabCustomers(this.dataObj, this, 'selectedTagIDs', 'selectedTagIDs', '');
    }
  }
  async checkAllGroupTag() {
    this.resetSelected();
    var $this = this;
		this.filters.groupIDs = [];
    this.searchFilters.groupName = [];
		this.accountTags.forEach(function (val: any) {
			if ($this.filters.groupIDs && !isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP != 'undefined') {
				$this.filters.groupIDs.push(val.ACCOUNTTAGID);
        $this.searchFilters.groupName.push(val.TAGNAME);
			}
		});
    this.dataObj["pageNumber"] = 1;
    this.dataObj["selectedTagIDs"] = this.filters.AccountTagID.join(",");
    this.dataObj["groupIDs"] = this.filters.groupIDs.join(",");
    this.grabCustomers(this.dataObj, this, 'selectedTagIDs', 'selectedTagIDs', '');
  }
  async resetTag(allowFetchData = true) {
    this.resetSelected();
    this.filters.AccountTagID = this.searchFilters.accountTagName = [];
    // this.filters.groupIDs = this.searchFilters.groupName = []
    this.dataObj["pageNumber"] = 1;
    this.dataObj["selectedTagIDs"] = this.filters.AccountTagID.join(",");
    // this.dataObj["groupIDs"] = this.filters.groupIDs.join(",");
    if (allowFetchData) {
      if(this.currentView == 'target') {
        this.pageTargetViewNumber = 1;
        this.grabCustomerTargetView();
      } else {
        this.grabCustomers(this.dataObj, this, 'selectedTagIDs', 'selectedTagIDs', '');
      }
    }
  }
  async resetGroup(allowFetchData = true) {
    this.resetSelected();
    this.filters.groupIDs = this.searchFilters.groupName = [];
    this.dataObj["pageNumber"] = 1;
    this.dataObj["groupIDs"] = this.filters.groupIDs.join(",");
    if (allowFetchData) {
      if(this.currentView == 'target') {
        this.pageTargetViewNumber = 1;
        this.grabCustomerTargetView();
      } else {
        this.grabCustomers(this.dataObj, this, 'selectedTagIDs', 'selectedTagIDs', '');
      }
    }
  }
  filterTagsInBox() {
    let options = this.details["accountTags"];

    if (this.searchTagsTxt) {
      options = options.filter((option) => `${option.TAGNAME}`.toLowerCase().indexOf(this.searchTagsTxt.toLowerCase()) !== -1)
    }

    this.accountTags = options;
  }

  toggleActive(div) {
		this.isActive = div;
	}

  showAddTagModal() {
    this.AccountTagsAssignModalVisible = true;
  }
  closeAddTagModal(reFetch = false) {
    this.AccountTagsAssignModalVisible = false
    if(reFetch) {
      this.grabCustomers(this.dataObj, this, '', '', 'page');
    }
  }

  toggleFilterBtn(filterBtn) {
		if (this.filterBtn == filterBtn) {
			return;
    }
    this.items = [];
    this.pageNumber = 1;
    this.dataObj["pageNumber"] = this.pageNumber;
    this.filterBtn = filterBtn;

    // reset filter before
    if((this.dataObj.sAccountID || "") != "") {
      this.dataObj.sAccountID = "";
    }
    if((this.dataObj.sAccountName || "") != "") {
      this.dataObj.sAccountName = "";
    }
    if((this.filters.sAccountID || "") != "") {
      this.filters.sAccountID = this.searchFilters.sAccountID = "";
    }
    if((this.filters.sAccountName || "") != "") {
      this.filters.sAccountName = this.searchFilters.sAccountName = "";
    }

    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["customers"]) {
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["customers"] = this.pageNumber;
      oldData["customersType"] = this.filterBtn;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }
		this.grabCustomers(this.dataObj, this, '', '', 'page');
  }
  async linkAccount(linkAccountId: string, acc: AcctsListResp.Accountsarr) {
    this.linkAccountTooltipVisible = -1;
    this.currentCustIndex = -1;
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "customers",
        FunctionName: "InsertAccLink",
        aID: acc.AID,
        LinkAccountId: linkAccountId,
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      this.grabCustomers(this.dataObj, this, '', '', 'page');
      notifier.success(
        "Linked " + linkAccountId + " to " + acc.ACCOUNTID + " - " + acc.ANAME
      );

    } catch (err) {
      notifier.alert("search accounts fail: " + err.message);
    } finally {
      this.loading = false;
    }
  }
  getColumnWidth(headerName) {
    var headerCls = "";
    switch (this.selectedHeaders.length) {
      case 7:
        switch (headerName) {
          case "ACCOUNTID":
            headerCls = "col-1-5";
            break;
          case "ANAME":
            headerCls = "col-2-5";
            break;
          case "YEARWISE":
            headerCls = "col-12";
            break;
          case "YTD":
            headerCls = "col-2 tar";
            break;
          default:
            headerCls = "col-1";
            break;
        }
        break;
      case 8:
        switch (headerName) {
          case "ACCOUNTID":
            headerCls = "col-1";
            break;
          case "ANAME":
            headerCls = "col-2";
            break;
          case "YEARWISE":
            headerCls = "col-12";
            break;
          case "YTD":
            headerCls = "col-2 tar";
            break;
          default:
            headerCls = "col-1";
            break;
        }
        break;
      case 9:
        switch (headerName) {
          case "ACCOUNTID":
            headerCls = "col-1";
            break;
          case "ANAME":
            headerCls = "col-2";
            break;
          case "YEARWISE":
            headerCls = "col-12";
            break;
          case "YTD":
            headerCls = "col-1-5 tar";
            break;
          default:
            headerCls = "col-1";
            break;
        }
        break;
      default:
        switch (headerName) {
          case "ANAME":
            headerCls = "col-2-5";
            break;
          case "YEARWISE":
            headerCls = "col-12";
            break;
          case "YTD":
            headerCls = "col-2 tar";
            break;
          default:
            headerCls = "col-1-5";
            break;
        }
        break;
    }
    if (
      (this.selectedHeaders[this.selectedHeaders.length - 1] == headerName ||
        this.selectedHeaders[this.selectedHeaders.length - 2] == headerName)
    ) {
      headerCls += " lastSearchBox";
    }
    return headerCls + ' headerName' + headerName;
  }
  async addHeader() {
    var sortedHeaders: string[] = [];
    colsResizeableReset();
    for (var customerHeader in this.headerCustomer) {
      var customerIndex: string = this.headerCustomer[customerHeader];
      const selectedIndex = this.selectedHeaders.includes(customerIndex);
      if (selectedIndex) {
        sortedHeaders.push(customerIndex);
      }
    }
    this.selectedHeaders = sortedHeaders;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: this.selectedHeaders.join(","),
      from: "customer",
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    colsResizeableReset();
  }


  getListHeader(key: string) {
    let ret = "";
    ret = (this.pageHeaders.find((item) => item.key == key) || { key: '', value: ''}).value;
    return ret;
  }

  removeColumn(header) {
    const sortedHeaders: string[] = [];
    for (const ts in this.headerCustomer) {
      const valIdx: string = this.headerCustomer[ts];
      const i = this.selectedHeaders.includes(valIdx);
      if (i && valIdx != header) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedHeaders = sortedHeaders;
    this.addHeader();
  }

  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    switch (header) {
      case 'ACCOUNTID':
        searchKey = this.searchFilters.sAccountID;
        break;
      case "ANAME":
        {
          const arr: string[] = [];
          if(this.searchFilters.sAccountName) {
            arr.push(`Customer: ${this.searchFilters.sAccountName}`);
          }
          if(this.searchFilters.accountTagName.length) {
            arr.push(`Customer Tags: ${this.searchFilters.accountTagName}`);
          }
          if(this.searchFilters.groupName.length) {
            arr.push(`Customer Groups: ${this.searchFilters.groupName}`);
          }
          searchKey = arr.join('; ');
          break;
        }
      case "SECURITYGROUPCOUNT":
        if ((this.securityGroups || []).length) {
          searchKey = '';
          if (
            this.searchFilters.selectedSecurityGroups &&
            !this.searchFilters.selectedSecurityGroups.length
          )
            searchKey = "None";
          else if (
            this.searchFilters.selectedSecurityGroups &&
            this.securityGroups.length !=
              this.searchFilters.selectedSecurityGroups.length
          ) {
            searchKey = this.searchFilters.selectedSecurityGroups.join(", ");
          }
        }
        break;
      case 'OPPORTUNITY':
        filter = getRangeFilter(this.filters.oppTotalMin, this.filters.oppTotalMax);
        break;
      case 'CURRENTPROFIT':
        {
          let tmpArr = [];
          if (this.filters.nonZeroAmountProfit) {
            tmpArr.push("Hide zero dollar profit");
          }
          filter = getRangeFilter(this.filters.currentProfitTotalMin, this.filters.currentProfitTotalMax);
          if (filter) {
            tmpArr.push(filter);
          }
          filter = tmpArr.join(", ");
          break;
        }
      case 'CURRENTREVENUE':
        {
          let tmpArr = [];
          if (this.filters.nonZeroAmountRevenue) {
            tmpArr.push("Hide zero dollar revenue");
          }
          let revenueFilterText = getRangeFilter(this.filters.currentRevenueTotalMin, this.filters.currentRevenueTotalMax);
          if (revenueFilterText) {
            tmpArr.push(revenueFilterText);
          }
          filter = tmpArr.join(", ");
          break;
        }
      case 'STATUS':
        if (this.filters.aStatusName.length == 1 && this.filters.aStatusName.includes("Active")) {
          filter = "";
        } else if (this.filters.aStatusName.length == 0) {
          filter = "None";
        } else {
          filter = this.filters.aStatusName.join(",");
        }
        break;
      case 'ALLTIMEREVENUE':
        {
          let tmpArr = [];
          if (this.filters.nonZeroAmountAllTimeRevenue) {
            tmpArr.push("Hide Zero Dollar Revenue");
          }
          let revenueFilterText = getRangeFilter(this.filters.allTimeRevenueTotalMin, this.filters.allTimeRevenueTotalMax);
          if (revenueFilterText) {
            tmpArr.push(revenueFilterText);
          }
          filter = tmpArr.join(", ");
          break;
        }
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  getRangeFilter(min, max) {
    const arr: string[] = [];
    if (min) {
      arr.push(`Min: ${min}`);
    }
    if (max) {
      arr.push(`Max: ${max}`);
    }
    return arr.join('; ');
  }

  resetColumn(header) {
    this.loading = true;
    switch (header) {
      case 'ACCOUNTID':
        this.resetSearchByID();
        break;
      case 'OPPORTUNITY':
        this.resetSearchByOpp();
        break;
      case 'CURRENTREVENUE':
        this.resetSearchByCurrentRevenueTotal();
        break;
      case 'CURRENTPROFIT':
        this.resetSearchByCurrentProfitTotal();
        break;
      case 'ANAME':
        this.resetTag(false);
        this.resetGroup(false);
        this.resetSearchByAccountName();
        break;
      case 'SECURITYGROUPCOUNT':
        this.checkAllSecurityGroups();
        break;
      case 'STATUS':
        this.resetStatusList();
        break;
      case 'ALLTIMEREVENUE':
        this.resetByAllTImeRevenue();
        break;
      default:
        break;
    }
  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }


  sortSelectedHeader() {
    let newSelectedHeaders: string[] = [];
    for (const item of this.pageHeaders) {
      if (!this.selectedHeaders.includes(item.key)) continue;
      if (this.nonDraggableHeaders.includes(item.key)) {
        // Make the Status and Rank columns the first columns by default.
        const index = this.nonDraggableHeaders.findIndex((defaultItem) => defaultItem == item.key);
        if (item.key == 'RANK' && !newSelectedHeaders.includes('STATUS')) {
          newSelectedHeaders = [item.key, ...newSelectedHeaders];
        } else {
          newSelectedHeaders.splice(index, 0, item.key);
        }
      }
      else {
        newSelectedHeaders.push(item.key);
      }
    }
    this.selectedHeaders = newSelectedHeaders;
  }

  async handleListChange(event) {
    const statusIndex = this.pageHeaders.findIndex((item) => item.key == 'STATUS');
    const statusItem = this.pageHeaders[statusIndex];
    this.pageHeaders.splice(statusIndex, 1);
    this.pageHeaders.splice(0, 0, statusItem);
    const rankIndex = this.pageHeaders.findIndex((item) => item.key == 'RANK');
    const rankItem = this.pageHeaders[rankIndex];
    this.pageHeaders.splice(rankIndex, 1);
    this.pageHeaders.splice(1, 0, rankItem);

    this.sortSelectedHeader();
    const dataObj = {
      controller: "Users",
      FunctionName: "sortListColumns",
      page: 'Customers',
      userid: sessionStorage.getItem("userId"),
      params: this.pageHeaders.map(item => item.key)
    };
    await axios.post(dataURL + "?ReturnType=JSON", dataObj);
  }

  switchToTargetView() {
    sessionStorage.setItem('currentView', "target");
    this.currentView = 'target';
    this.pageTargetViewNumber = 1;
    this.filters.AccountTagID = this.filters.AccountTagID || [];
    this.filters.groupIDs = this.filters.groupIDs || [];
    this.searchFilters.accountTagName = this.searchFilters.accountTagName || [];
    this.searchFilters.groupName = this.searchFilters.groupName || [];
    this.grabCustomerTargetView();
  }

  switchToPerformanceView(performanceType) {
    let currentView = 'Performance';

    if (performanceType === 1) {
      currentView = 'shrink';
    } else if (performanceType === 2) {
      currentView = 'growth';
    }

    sessionStorage.setItem('currentView', currentView);

    this.currentView = currentView;
    this.filters.AccountTagID = this.filters.AccountTagID || [];
    this.filters.groupIDs = this.filters.groupIDs || [];
    this.searchFilters.accountTagName = this.searchFilters.accountTagName || [];
    this.searchFilters.groupName = this.searchFilters.groupName || [];
    if (performanceType > 0) {
      this.sortingByNet(performanceType);
    } else {
      this.currentView = 'Performance';
      this.dataObj = {
        controller : "Customers",
        FunctionName : "List",
        sub : 2,
        selectedTagIDs: this.filters.AccountTagID.join(","),
        groupIDs: this.filters.groupIDs.join(","),
        sAccountName: this.filters.sAccountName,
        sAccountID: this.filters.sAccountID,
        viewYear: this.viewYear,
        targetYear: this.targetYear
      };
      this.grabCustomers(this.dataObj, this, '', '', 'page');
    }
  }

  async loadTargetViewPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageTargetViewNumber = this.pageTargetViewNumber - 1;
    } else if (type === "next") {
      this.pageTargetViewNumber = this.pageTargetViewNumber + 1;
    } else {
      this.pageTargetViewNumber = page;
    }

    if (this.pageTargetViewNumber <= this.targetViewDetails.TOTALPAGES && this.pageTargetViewNumber >= 1) {
      await this.grabCustomerTargetView();
    }
  }

  async saveTargetProfit(item, tpYear) {
    this.loading = true;

    try {
      let requestData: any = {
        controller: "Customers",
        FunctionName: "UpdateProfitTargets",
        aID: item.AID,
        userID: sessionStorage.getItem('userId'),
        userRole: sessionStorage.getItem('userRole'),
        target_year: tpYear == 'curYear' ? item.TARGETYEAR : item.TARGETPREVYEAR,
        target_profit: tpYear == 'curYear' ? item.TARGET_PROFIT : item.TARGET_PROFIT_PREVYEAR
      };

      if (tpYear == 'curYear' ? item.TARGETUUID : item.TARGETUUIDPREVYEAR) {
        requestData.action = 'updateProfitTarget';
        requestData.targetUUID = tpYear == 'curYear' ? item.TARGETUUID : item.TARGETUUIDPREVYEAR;
      } else {
        requestData.action = 'insert';
      }
      const response = await axios.post(
        dataURL + "?ReturnType=JSON",
        requestData
      );

      const message = response.data.STATUSMESSAGE || "";
      if (response.data.STATUS == 1) {
        notifier.success(message);
        let customerIndex = this.customerTargets.findIndex((customer) => customer.ACCOUNTID == item.ACCOUNTID);
        if (customerIndex >= 0) {
          this.customerTargets[customerIndex].CHANGESCOUNT += 1;
          if (tpYear == 'curYear') {
            this.customerTargets[customerIndex].TARGET_PROFIT_FORMATTED = item.TARGET_PROFIT;
            this.customerTargets[customerIndex].isEdit = false;
            let tempTotalTarget = parseFloat(this.targetViewDetails.TOTALTARGET.replace("$", "").replaceAll(",", ""));
            tempTotalTarget -= item.TARGET_PROFITBK;
            tempTotalTarget += item.TARGET_PROFIT;
            this.targetViewDetails.TOTALTARGET = dollarFormat(tempTotalTarget);
            this.customerTargets[customerIndex].TARGET_PROFITBK = item.TARGET_PROFIT;
          } else {
            this.customerTargets[customerIndex].TARGET_PROFIT_PREVYEAR_FORMATTED = item.TARGET_PROFIT_PREVYEAR;
            this.customerTargets[customerIndex].isEditPrevYear = false;
          }
        }
      } else {
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log('error ', error);
    } finally {
      this.loading = false;
    }
  }

  async uncheckSecurityGroups() {
    this.filters.selectedSecurityGroups = [-1];
    this.searchFilters.selectedSecurityGroups = [];
    this.dataObj["selectedSecurityGroups"] = "-1";
    await this.grabCustomers(this.dataObj, this, '', '', '');
  }

  async filterByGroup(groupName) {
    if (this.searchFilters.selectedSecurityGroups.includes(groupName)) {
      let groupIndex = this.searchFilters.selectedSecurityGroups.findIndex(item => item == groupName)
      this.searchFilters.selectedSecurityGroups.splice(groupIndex, 1);
    } else {
      this.searchFilters.selectedSecurityGroups.push(groupName);
    }
    this.dataObj["selectedSecurityGroups"] = this.filters.selectedSecurityGroups.join(',');
    this.hasChangedSecurityGroupFilters = true;
  }

  async checkAllSecurityGroups() {
    this.resetSelected();
    var data = this;
    this.filters.selectedSecurityGroups = [];
    this.searchFilters.selectedSecurityGroups = [];
    this.securityGroups.forEach(function(item: any) {
      data.filters.selectedSecurityGroups.push(item.SECURITYGROUPID);
      data.searchFilters.selectedSecurityGroups.push(item.SECURITYGROUPNAME);
    });
    this.dataObj["selectedSecurityGroups"] = this.filters.selectedSecurityGroups.join(',');
    await this.grabCustomers(this.dataObj, this, '', '', '');
  }

  async favCustomer(id) {
    let index = this.selectedFavCustomer.findIndex((favId:any) => favId === id);
    if (index === -1) {
      this.selectedFavCustomer.push(id);
    } else {
      this.selectedFavCustomer.splice(index, 1);
    }
    try {
      await axios.post(dataURL + "?ReturnType=JSON", {
          "FunctionName"        : "UpdateCustomerUser",
          "controller"          : "VARUsers",
          "subsystem"           : "VAR360",
          "selectedFavCustomer" : this.selectedFavCustomer
      })
    } catch (err) {
      // notifier.alert(err.message)
      console.log(err);
    }
    this.grabCustomers(this.dataObj, this, '', '', 'page');
  }

  async checkBusinessLineList(bLine: string, name: string) {
    this.resetBLIdArr()

    if (this.businessLineIds != undefined && this.businessLineName != undefined) {
      const i = this.businessLineIds.findIndex(s => s === bLine);
      const bLineIndex = this.businessLineName.findIndex(
        bLineValue => bLineValue === name
      );
      if (i === -1) {
        this.businessLineIds.push(bLine);
      } else {
        this.businessLineIds.splice(i, 1);
      }
      if (bLineIndex === -1) {
        this.businessLineName.push(name);
      } else {
        this.businessLineName.splice(bLineIndex, 1);
      }
    }
    if (!this.businessLineIds.length) {
      this.uncheckBL()
    }
    this.hasChange = true;
  }

  handleBLClick(e) {
    e.stopPropagation();
  }
  uncheckAllBLFilter() {
    this.uncheckBL()
    if (this.isTargetView) {
      this.grabCustomerTargetView();
    } else {
        this.grabCustomers(this.dataObj, this, '', '', 'page');
    }
  }
  resetBLFilter() {
    this.resetBLIdArr()
    for (let i = 0; i < this.businessLines.length; i++) {
      const index = this.businessLineIds.findIndex(s => s === this.businessLines[i].id);
      if (index === -1) {
        this.businessLineIds.push(this.businessLines[i].id);
        this.businessLineName.push(this.businessLines[i].name);
      }
    }
    if (this.isTargetView) {
      this.grabCustomerTargetView();
    } else {
        this.grabCustomers(this.dataObj, this, '', '', 'page');
    }
  }
  resetBLIdArr() {
    const index = this.businessLineIds.findIndex(s => s == '-1')
    if (index >= 0) {
      this.businessLineIds = [];
      this.businessLineName = [];
    }
  }
  uncheckBL() {
    this.businessLineIds = ['-1'];
    this.businessLineName = ['None'];
  }

  async clickOutside() {
    if (this.hasChange) {
      this.hasChange = false;
      if (this.isTargetView) {
        await this.grabCustomerTargetView();
      } else {
        await this.grabCustomers(this.dataObj, this, '', '', 'page');
      }
    } else {
      this.hasChange = false;
    }
  }

  async searchByAmount() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.oppTotalMin = this.searchFilters.oppTotalMin;
    this.filters.oppTotalMax = this.searchFilters.oppTotalMax;
    this.dataObj["pageNumber"] = 1;
    this.dataObj["oppTotalMin"] = this.filters.oppTotalMin;
    this.dataObj["oppTotalMax"] = this.filters.oppTotalMax;
    await this.grabCustomers(this.dataObj, this, '', '', 'page');
  }
  async resetSearchByOpp(allowFetchData = true) {
    this.filters.oppTotalMin = this.searchFilters.oppTotalMin = undefined;
    this.filters.oppTotalMax = this.searchFilters.oppTotalMax = undefined;
    this.dataObj["pageNumber"] = 1;
    this.dataObj["oppTotalMin"] = this.filters.oppTotalMin;
    this.dataObj["oppTotalMax"] = this.filters.oppTotalMax;
    await this.grabCustomers(this.dataObj, this, '', '', 'page');
  }

  createCustomer() {
    this.$router.push({name: "AddCustomer"});
  }

  async filterByPrevYearTP() {
    this.filters.prevYearTPMin = this.searchFilters.prevYearTPMin;
    this.filters.prevYearTPMax = this.searchFilters.prevYearTPMax;
    this.pageTargetViewNumber = 1;
    await this.grabCustomerTargetView();
  }

  async resetFilterByPrevYearTP() {
    this.filters.prevYearTPMin = this.searchFilters.prevYearTPMin = "";
    this.filters.prevYearTPMax = this.searchFilters.prevYearTPMax = "";
    this.pageTargetViewNumber = 1;
    await this.grabCustomerTargetView();
  }

  async filterByCurrYearTP() {
    this.filters.currYearTPMin = this.searchFilters.currYearTPMin;
    this.filters.currYearTPMax = this.searchFilters.currYearTPMax;
    this.pageTargetViewNumber = 1;
    await this.grabCustomerTargetView();
  }

  async resetFilterByCurrYearTP() {
    this.filters.currYearTPMin = this.searchFilters.currYearTPMin = "";
    this.filters.currYearTPMax = this.searchFilters.currYearTPMax = "";
    this.pageTargetViewNumber = 1;
    await this.grabCustomerTargetView();
  }

  sortByPrevYearTarget() {
    this.sortTargetView.field = 3;
    this.sortTargetView.direction[3] = this.sortTargetView.direction[3] === 1 ? 2: 1;
    this.grabCustomerTargetView();
  }

  sortByProfit() {
    this.sortTargetView.field = 4;
    this.sortTargetView.direction[4] = this.sortTargetView.direction[4] === 1 ? 2: 1;
    this.grabCustomerTargetView();
  }

  sortByMargin() {
    this.sortTargetView.field = 5;
    this.sortTargetView.direction[5] = this.sortTargetView.direction[5] === 1 ? 2: 1;
    this.grabCustomerTargetView();
  }

  async filterBySpend() {
    this.filters.spendMin = this.searchFilters.spendMin;
    this.filters.spendMax = this.searchFilters.spendMax;
    this.pageTargetViewNumber = 1;
    await this.grabCustomerTargetView();
  }

  async resetFilterBySpend() {
    this.filters.spendMin = this.searchFilters.spendMin = "";
    this.filters.spendMax = this.searchFilters.spendMax = "";
    this.pageTargetViewNumber = 1;
    await this.grabCustomerTargetView();
  }

  async filterByProfit() {
    this.filters.profitMin = this.searchFilters.profitMin;
    this.filters.profitMax = this.searchFilters.profitMax;
    this.pageTargetViewNumber = 1;
    await this.grabCustomerTargetView();
  }

  async resetFilterByProfit() {
    this.filters.profitMin = this.searchFilters.profitMin = "";
    this.filters.profitMax = this.searchFilters.profitMax = "";
    this.pageTargetViewNumber = 1;
    await this.grabCustomerTargetView();
  }

  async zeroDollar(field, hideZero) {
    if (field == 'profit') {
      this.filters.hideProfitZero = this.searchFilters.hideProfitZero = hideZero;
    }
    this.pageTargetViewNumber = 1;
    await this.grabCustomerTargetView();
  }

  async filterByMargin() {
    this.filters.marginMin = this.searchFilters.marginMin;
    this.filters.marginMax = this.searchFilters.marginMax;
    this.pageTargetViewNumber = 1;
    await this.grabCustomerTargetView();
  }

  async resetFilterByMargin() {
    this.filters.marginMin = this.searchFilters.marginMin = "";
    this.filters.marginMax = this.searchFilters.marginMax = "";
    this.pageTargetViewNumber = 1;
    await this.grabCustomerTargetView();
  }

  getTotalPrice(item) {
    if(this.selectedID.includes(item.ACCOUNTID)) {
      this.selectedGrandTotal += parseFloat(item.TOTALSALESBK);
      this.selectedProfitTotal += parseFloat(item.CURTOTALPROFITBK);
    } else {
      this.selectedGrandTotal -= parseFloat(item.TOTALSALESBK);
      this.selectedProfitTotal -= parseFloat(item.CURTOTALPROFITBK);
    }
  }

  getCurrentViewTitle() {
    if (
      (this.filters.AccountTagID && this.filters.AccountTagID.length) ||
      (this.filters.gTagIDs && this.filters.gTagIDs.length) ||
      (this.filters.groupIDs && this.filters.groupIDs.length)
    ) {
      var fulltitle: any = [];

      var $this = this;
      this.accountTags.forEach(function(val: any) {
        if (!isNaN(val.ACCOUNTTAGID)) {
          if (
            typeof val.ISGLOBALTAG == "undefined" &&
            typeof val.ISGROUP == "undefined" &&
            $this.filters.AccountTagID &&
            $this.filters.AccountTagID.includes(val.ACCOUNTTAGID)
          ) {
            fulltitle.push(val.TAGNAME);
          }
          if (
            typeof val.ISGLOBALTAG != "undefined" &&
            $this.filters.gTagIDs &&
            $this.filters.gTagIDs.includes(val.ACCOUNTTAGID)
          ) {
            fulltitle.push(val.TAGNAME);
          }
          if (
            typeof val.ISGROUP != "undefined" &&
            $this.filters.groupIDs &&
            $this.filters.groupIDs.includes(val.ACCOUNTTAGID)
          ) {
            fulltitle.push(val.TAGNAME);
          }
        }
      });
      return fulltitle.join(", ");
    }

    return "";
  }

  isChecked(accountId) {
    return this.selectedID.includes(accountId);
  }

  async selectView(item, e) {
    if (isNaN(item.ACCOUNTTAGID)) {
      this.filters.groupIDs = [];
      this.filters.AccountTagID = [];
      this.viewType = item.ACCOUNTTAGID;
      this.toggleCustomView(item.ACCOUNTTAGID);
    } else {
      e.stopPropagation();
    }
  }

  async updateTarget(item) {
    if (item.TARGET_PROFIT != item.PROFIT) {
      let curYear = new Date().getFullYear();
      const response = await ApiHelper.callApi("post", {
        controller        : "Customers",
        FunctionName      : "UpdateProfitTargets",
        aID               : item.AID,
        target_year       : curYear,
        target_profit     : item.PROFIT,
        action            : "insert"
      });

      if (response.STATUS === 1) {
        // eslint-disable-next-line require-atomic-updates
        item.TARGET_PROFIT = item.PROFIT;
        // eslint-disable-next-line require-atomic-updates
        item.TARGET_PROFIT_FORMATTED = item.PROFIT_FORMATTED;
        // eslint-disable-next-line require-atomic-updates
        item.CHANGESCOUNT += 1;
        let tempTotalTarget = parseFloat(this.targetViewDetails.TOTALTARGET.replace("$", "").replaceAll(",", ""));
        tempTotalTarget -= item.TARGET_PROFITBK;
        tempTotalTarget += item.PROFIT;
        this.targetViewDetails.TOTALTARGET = dollarFormat(tempTotalTarget);
        // eslint-disable-next-line require-atomic-updates
        item.TARGET_PROFITBK = item.PROFIT;
        notifier.success(response.STATUSMESSAGE);
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
    }
  }

  checkAccTooltip(index) {
    checkTooltip($(this.$refs[`targetChangesTooltip${index}`]), this.customerTargets.length);
  }


  async searchByCurrentProfitTotal() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.nonZeroAmountProfit = this.searchFilters.nonZeroAmountProfit;
    this.filters.currentProfitTotalMin = this.searchFilters.currentProfitTotalMin;
    this.filters.currentProfitTotalMax = this.searchFilters.currentProfitTotalMax;
    this.dataObj["pageNumber"] = 1;
    this.dataObj["nonZeroAmountProfit"] = this.filters.nonZeroAmountProfit ? 1 : 0;
    this.dataObj["currentProfitTotalMin"] = this.filters.currentProfitTotalMin;
    this.dataObj["currentProfitTotalMax"] = this.filters.currentProfitTotalMax;
    await this.grabCustomers(this.dataObj, this, '', '', 'page');

  }
  async resetSearchByCurrentProfitTotal(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.nonZeroAmountProfit = this.searchFilters.nonZeroAmountProfit = false;
    this.filters.currentProfitTotalMin = this.searchFilters.Profit = '';
    this.filters.currentProfitTotalMax = this.searchFilters.currentProfitTotalMax = '';
    this.dataObj["pageNumber"] = 1;
    this.dataObj["Profit"] = this.filters.Profit;
    this.dataObj["nonZeroAmountProfit"] = this.filters.nonZeroAmountProfit ? 1 : 0;
    this.dataObj["currentProfitTotalMin"] = this.filters.currentProfitTotalMin;
    this.dataObj["currentProfitTotalMax"] = this.filters.currentProfitTotalMax;
    await this.grabCustomers(this.dataObj, this, '', '', 'page');
  }
  async searchNonZeroAmountRevenue(value) {
    this.searchFilters.nonZeroAmountRevenue = value;
    await this.searchByCurrentRevenueTotal();
  }
  async searchNonZeroAmountProfit(value) {
    this.searchFilters.nonZeroAmountProfit = value;
    await this.searchByCurrentProfitTotal();
  }
  async searchByCurrentRevenueTotal() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.nonZeroAmountRevenue = this.searchFilters.nonZeroAmountRevenue;
    this.filters.currentRevenueTotalMin = this.searchFilters.currentRevenueTotalMin;
    this.filters.currentRevenueTotalMax = this.searchFilters.currentRevenueTotalMax;
    this.dataObj["pageNumber"] = 1;
    this.dataObj["nonZeroAmountRevenue"] = this.filters.nonZeroAmountRevenue ? 1 : 0;
    this.dataObj["currentRevenueTotalMin"] = this.filters.currentRevenueTotalMin;
    this.dataObj["currentRevenueTotalMax"] = this.filters.currentRevenueTotalMax;
    await this.grabCustomers(this.dataObj, this, '', '', 'page');

  }
  async resetSearchByCurrentRevenueTotal(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.nonZeroAmountRevenue = this.searchFilters.nonZeroAmountRevenue = false;
    this.filters.currentRevenueTotalMin = this.searchFilters.currentRevenueTotalMin = '';
    this.filters.currentRevenueTotalMax = this.searchFilters.currentRevenueTotalMax = '';
    this.dataObj["pageNumber"] = 1;
    this.dataObj["nonZeroAmountRevenue"] = this.filters.nonZeroAmountRevenue ? 1 : 0;
    this.dataObj["currentRevenueTotalMin"] = this.filters.currentRevenueTotalMin;
    this.dataObj["currentRevenueTotalMax"] = this.filters.currentRevenueTotalMax;
    await this.grabCustomers(this.dataObj, this, '', '', 'page');
  }

  checkStatusList(status: number, name: string) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      this.filters.aStatus != undefined &&
      this.filters.aStatusName != undefined
    ) {
      const i = this.filters.aStatus.findIndex(s => s === status);
      const statusIndex = this.filters.aStatusName.findIndex(
        statusValue => statusValue === name
      );
      if (i === -1) {
        this.filters.aStatus.push(status);
      } else {
        this.filters.aStatus.splice(i, 1);
      }

      if (statusIndex === -1) {
        this.filters.aStatusName.push(name);
      } else {
        this.filters.aStatusName.splice(statusIndex, 1);
      }
    }
    this.hasChangedStatusFilters = true;
  }

  uncheckStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.aStatus = [];
    this.filters.aStatusName = [];
    this.hasChangedStatusFilters = true;
  }

  async resetStatusList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.aStatus = [1];
    this.filters.aStatusName = ["Active"];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.hasChangedStatusFilters = true;
  }

  async filterByAllTimeRevenue() {
    this.filters.allTimeRevenueTotalMin = this.searchFilters.allTimeRevenueTotalMin;
    this.filters.allTimeRevenueTotalMax = this.searchFilters.allTimeRevenueTotalMax;
    this.filters.nonZeroAmountAllTimeRevenue = this.searchFilters.nonZeroAmountAllTimeRevenue;
    this.dataObj["pageNumber"] = 1;
    this.dataObj["nonZeroAmountAllTimeRevenue"] = this.filters.nonZeroAmountAllTimeRevenue ? 1 : 0;
    this.dataObj["allTimeRevenueTotalMin"] = this.searchFilters.allTimeRevenueTotalMin;
    this.dataObj["allTimeRevenueTotalMax"] = this.searchFilters.allTimeRevenueTotalMax;
    await this.grabCustomers(this.dataObj, this, '', '', '');
  }

  async resetByAllTImeRevenue() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.nonZeroAmountAllTimeRevenue = this.searchFilters.nonZeroAmountAllTimeRevenue = false;
    this.filters.allTimeRevenueTotalMin = this.searchFilters.allTimeRevenueTotalMin = "";
    this.filters.allTimeRevenueTotalMax = this.searchFilters.allTimeRevenueTotalMax = "";
    this.dataObj["pageNumber"] = 1;
    this.dataObj["nonZeroAmountAllTimeRevenue"] = this.filters.nonZeroAmountAllTimeRevenue ? 1 : 0;
    this.dataObj["allTimeRevenueTotalMin"] = this.filters.allTimeRevenueTotalMin;
    this.dataObj["allTimeRevenueTotalMax"] = this.filters.allTimeRevenueTotalMax;
    await this.grabCustomers(this.dataObj, this, '', '', '');
  }

  async filterNonZeroAllTimeRevenue(value) {
    this.searchFilters.nonZeroAmountAllTimeRevenue = value;
    await this.filterByAllTimeRevenue();
  }

  isManyLongHeaders() {
    let needFixHeaderWidth = false;
    let totalLongName = 0;
    this.selectedHeaders.map(header => {
      const headerName = this.getListHeader(header);
      if (headerName.length > 10) {
        totalLongName = totalLongName + 1;
      }
    });
    if (totalLongName > 3) {
      needFixHeaderWidth = true;
    }
    return needFixHeaderWidth;
  }

  yearOptions(type = '') {
    const currentYear: number = new Date().getFullYear();
    const options: any[] = [];
    options.push(currentYear);
    if (type === 'accounts') {
      // Add 5 years before the current year
      for (let i = 1; i <= 5; i++) {
        options.push(currentYear - i);
      }
    } else {
      // Add 12 Month, current year, and 5 years before the current year
      for (let i = 0; i <= 5; i++) {
        options.push(currentYear - i);
        if (i === 0) {
          options.push("12 Months");
        }
      }
    }
    return options;
  }

  async changeView(type) {
    if (this.viewYear != type) {
      this.viewYear = type;
      if (!isNaN(type)) {
        this.targetYear = type;
      } else {
        this.targetYear = this.currYear;
      }
      this.dataObj["viewYear"] = this.viewYear;
      this.dataObj["targetYear"] = this.targetYear;
      this.selectedID = [];
      this.excludedIDs = [];
      this.selectedAll = false;
      this.resetSelectionTotal();
      if (this.isTargetView) {
        await this.grabCustomerTargetView();
      } else {
        await this.grabCustomers(this.dataObj, this, '', '', 'page');
      }
    }
  }
  totalCustomersWithProfit = 0;
  getBgColor(item) {
    let color = '#999';
    if(item.CURTOTALPROFITBK > 0) {
      const rank = item.RANK || 0;
      const step = this.totalCustomersWithProfit / 10;
      let rankLevel = 1;
      if(rank <= step) {
        rankLevel = 1;
      } else if(rank <= step * 2) {
        rankLevel = 2;
      } else if(rank <= step * 3) {
        rankLevel = 3;
      } else if(rank <= step * 4) {
        rankLevel = 4;
      } else if(rank <= step * 5) {
        rankLevel = 5;
      } else if(rank <= step * 6) {
        rankLevel = 6;
      } else if(rank <= step * 7) {
        rankLevel = 7;
      } else if(rank <= step * 8) {
        rankLevel = 8;
      } else if(rank <= step * 9) {
        rankLevel = 9;
      } else if(rank <= step * 10) {
        rankLevel = 10;
      }
      switch (rankLevel) {
        case 1:
          color = '#009cff';
          break;
        case 2:
          color = '#1ca7e4';
          break;
        case 3:
          color = '#34b0cb';
          break;
        case 4:
          color = '#4ebbb1';
          break;
        case 5:
          color = '#68c498';
          break;
        case 6:
          color = '#81ce7f';
          break;
        case 7:
          color = '#99d765';
          break;
        case 8:
          color = '#b3e24c';
          break;
        case 9:
          color = '#ceec32';
          break;
        case 10:
          color = '#e6f519';
          break;
        default:
          color = '#009cff';
          break;
      }

    }
    return color;
  }

  selectInPage(selectInPage) {
    this.selectPageAll = selectInPage;
    if (this.selectPageAll) {
      for (var val of this.items) {
        if (this.selectedID.indexOf(val.ACCOUNTID) == -1) {
          this.selectedID.push(val.ACCOUNTID);
          this.getTotalPrice(val);
        }
        this.$delete(this.excludedIDs, this.excludedIDs.findIndex(id => id == val.ACCOUNTID));
      }
    } else {
      for (var val of this.items) {
        let selectedIDIndex = this.selectedID.indexOf(val.ACCOUNTID);
        if (selectedIDIndex != -1) {
          this.selectedID.splice(selectedIDIndex, 1);
          this.getTotalPrice(val);
        }
        if(!this.excludedIDs.includes(val.ACCOUNTID)) {
          this.excludedIDs.push(val.ACCOUNTID);
        }
      }
    }
    let remaningCustomers = this.details.TOTAL - this.excludedIDs.length;
    if (this.selectedAll && remaningCustomers == 0) {
      this.selectedAll = false;
    }
  }

  resetSelectionTotal() {
    this.selectedGrandTotal = 0.0;
    this.selectedProfitTotal = 0.0;
  }
}
